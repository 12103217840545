import './ADFL.scss'
import React, { useEffect, useRef, useState } from "react";
import { InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { FlowMessageReadEdit } from "../../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppButton from "../../../shared/app-button/AppButton";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import { AutocompleteInput, AutocompleteOption, AutocompleteOptions, asAutocompleteOption } from "../../../shared/app-autocomplete-input/AppAutocompleteInput";
import AppToggleBox from "../../../shared/app-togglebox/AppTogglebox";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import { ApplicationLanguge } from '../../../../App';
import { CodeListName, MIN_PERSON_AGE } from '../../../../models/App.models';
import FlowMessage from '../../flow-message/FlowMessage';

export const ADDITIONAL_DRIVERS_FIRST_LICENCE_ID = 'ADFL';
export const ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP = 'ADFL.EXP';
export const ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE = 'ADFL.AGE';

type AgeState = {
    [key: string]: {
        value: boolean;
        meta?: AgeOptionMeta;
    };
}

type AgeOptionMeta = {
    from?: number;
    to?: number;
}

export const AdditionalDriversFirstLicenceHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, _appService } = useWizardStep(node, isLast);
    const { t, i18n } = useTranslation();
    const [hasAd, setHasAd] = useState(false);

    const handleSubmit = () => {
        if (!hasAd) {
            _wmService.handleStepValueChange(null, true, node, true, undefined, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE);
            _wmService.handleStepValueChange(null, true, node, false, undefined, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP);
        } else {
            let aoKey = Object.keys(age).find(x => age[x].value);
            let aoMatch = ageOptions.current.find(x => x.code === aoKey);

            _wmService.handleStepValueChange(new InputLayerValue(aoMatch?.code, aoMatch?.value), true, node, true, undefined, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE);
            _wmService.handleStepValueChange(experience, true, node, false, undefined, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP);
        }  
    }

    const ageOptions = useRef(
        (_appService.getCodeList(CodeListName.Age) ?? [])
            .map(asAutocompleteOption)
    );
    const newAgeOptionsState = (key: string) => {
        return ageOptions.current.reduce(
            (
                obj: AgeState,
                item: AutocompleteOption
            ) => {
                obj[item.code] = { value: item.code === key, meta: item.meta };
                return obj;
            },
            {} as AgeState
        );
    }

    const [age, setAge] = useState(newAgeOptionsState('34'));

    /** Get options from codelist cache */
    const getExperienceOptions = (ageMeta?: AgeOptionMeta) => {
        if (!ageMeta) {
            let newAgeMeta = Object.values(age).find(x => x.value);
            if (newAgeMeta) {
                ageMeta = newAgeMeta.meta;
            }
        }

        const maxXpYears = (ageMeta?.to ?? 100) - MIN_PERSON_AGE; 
        const rs = (_appService.getCodeList(CodeListName.Experience) ?? [])
            .map(asAutocompleteOption)
            .filter(x => { 
                let from = (x.meta?.from ?? 0);
                let to = (x.meta?.to ?? maxXpYears);
                return from <= maxXpYears && to <= maxXpYears;
            });  
        return rs;
    }

    // Stored codelist options
    const [experienceOptions, setExperienceOptions] = useState({
        lng: i18n.resolvedLanguage as ApplicationLanguge,
        o: getExperienceOptions(),
        cached: Date.now()
    } as AutocompleteOptions);

    /** Create new expreience state based on key */
    const getExperienceState = (key: string) => {
        let matchingOption = experienceOptions.o.find(x => x.code === key);
        return new InputLayerValue(matchingOption?.code, matchingOption?.value);
    }

    /** Selected experience value */
    const [experience, setExperience] = useState(getExperienceState('10'));

    // Codelist option update based on codelistupdate event
    useEffect(() => {
        // restrict updates to changes only
        if (experienceOptions.lng !== _appService.onCodeListUpdate) {
            setExperienceOptions(() => ({ lng: _appService.onCodeListUpdate, o: getExperienceOptions(), cached: Date.now() }));
        }
    }, [_appService.onCodeListUpdate]);

    /** Experience area DOM ref */
    const expAreaRef = useRef<HTMLDivElement>(null);

    /** Value change handler */
    const handleExperienceInputChange = (value: InputLayerValue) => {
        setExperience(() => value);
    }

    /** Experience area DOM */
    const experienceOptionContainer = () => {
        return (
            <div ref={expAreaRef} className={`adc-experience${hasAd ? '' : ' hidden'}`}>
                <span className="adce-title">
                    {t(`Wizard.ADFL.Edit.Experience.Title`)}
                </span>
                <AutocompleteInput
                    modalPortal={_wmService.pageViewportRef.current as Element}
                    placeholder={{ entry: 'Wizard.ADFL.Edit.Experience.Entry', assistive: 'Wizard.ADFL.Edit.Experience.Assistive' }}
                    onValueChange={handleExperienceInputChange}
                    disableSubmit={false}
                    hideSubmit={true}
                    value={experience}
                    clearable={false}
                    selectOnly={true}
                    configuration={{
                        type: 'static',
                        options: experienceOptions
                    }}
                    scrollTarget={_wmService.flowBodyRef.current}
                />
            </div>
        );
    }
    /** Human readable experience format */
    const readableExperienceValue = () => {
        let modelValue = _wmService.readModelValue(ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP);
        if (modelValue.truthy()) {
            modelValue.text = experienceOptions.o.find(x => x.code === modelValue.value)?.value ?? '';
            return modelValue.text;
        }

        return '...';   
    }

    const onAgeOptionValueChange = (aoKey: string, value: boolean) => {
        // if any of the age groups is turned off, turn '34' one back on as its the default one
        const applicableOption = !value ? '34' : aoKey;
        const newAgeState = newAgeOptionsState(applicableOption);
        setAge(newAgeState);
        // get new exp options with age meta filters applied
        const newExperienceOptions = getExperienceOptions(newAgeState[applicableOption].meta);
        setExperienceOptions(x => ({ ...x, o: newExperienceOptions, cached: Date.now() }));

        // set new experience value if new options dont include it
        if (newExperienceOptions.findIndex(x => x.code === experience.value) === -1) {
            let newExperienceState = newExperienceOptions[newExperienceOptions.length - 1];
            requestAnimationFrame(() => {
                setExperience(() => new InputLayerValue(newExperienceState?.code, newExperienceState?.value));
            });
        }
    };

    const ageOptionContainer = () => {
        return (
            <div className={`adc-age${hasAd ? '' : ' hidden'}`}>
                <div style={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <span className="adca-title">
                        {t(`Wizard.ADFL.Edit.Age.Title`)}
                    </span>
                    {
                        Object.keys(age).map(aoKey => {
                            const aoValue = age[aoKey].value;
                            const aoDisabled = aoKey === '34' && aoValue;

                            return (
                                <div className={`ao-option${aoValue ? ' active' : ''}`} key={`${aoKey}`}>
                                    {t(`DriverAge.${aoKey}`)}
                                    <AppToggleBox
                                        disabled={aoDisabled}
                                        toggled={aoValue}
                                        onToggleChange={(x: boolean) => onAgeOptionValueChange(aoKey, x)}
                                    />
                                </div>
                            )
                        })
                    }
                </div> 
            </div>
        );
    }

    const readableAgeValue = () => {
        let modelValue = _wmService.readModelValue(ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE).value;
        if (modelValue) {
            return t(`DriverAge.${modelValue}`);
        }

        return '...'; 
    }

    return (
        <React.Fragment>
        {
            active()
                ? (
                    <FlowMessage
                        stepId={ADDITIONAL_DRIVERS_FIRST_LICENCE_ID}
                        queryArea={
                            <AppQueryHeader>{t('Wizard.ADFL.Edit.Query')}</AppQueryHeader>
                        }
                        inputArea={() => 
                            <div className="additional-drivers-container">
                                <div className="additional-drivers-container-align">
                                    <div className="additional-driver-details-header">
                                        {t('Wizard.ADFL.Edit.HasAdditionalDriver')}
                                        <AppToggleBox
                                            toggled={hasAd}
                                            onToggleChange={(x: boolean) => { setHasAd(x); }}
                                        />
                                    </div>
                                    <div className={`additional-driver-details`}>
                                        {ageOptionContainer()}
                                        {experienceOptionContainer()}
                                        <div className="adc-confirm">
                                            <AppButton onPress={() => handleSubmit()}>
                                                {t('Input.Confirm')}
                                            </AppButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        ignoreFocusTransfer={true}
                    /> 
                )
                : (
                    <div data-step-id={ADDITIONAL_DRIVERS_FIRST_LICENCE_ID} className={`flow-message disabled`}>   
                        <div className={`flow-message-area`}>
                            {
                                !hasAd
                                ? (
                                    <div className="flow-message-read">
                                        <div className="flow-message-read-icon">
                                            <AppIconDisplay name="additionaldriver" size={AppIconDisplaySize.S} />
                                        </div>
                                        <div className="flow-message-read-label">
                                            {t('Wizard.ADFL.Read.Query')}
                                        </div>
                                        <div className="flow-message-read-value">
                                            {t('Wizard.ADFL.Read.None')}
                                        </div>
                                        {
                                            editable()
                                            && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                        }
                                    </div>
                                )
                                : (
                                    <React.Fragment>
                                        <div className="flow-message-read">
                                            <div className="flow-message-read-icon">
                                                <AppIconDisplay name="additionaldriver" size={AppIconDisplaySize.S} />
                                            </div>
                                            <div className="flow-message-read-label">
                                                {t('Wizard.ADFL.Read.Age')}
                                            </div>
                                            <div className="flow-message-read-value">
                                                {readableAgeValue()}
                                            </div>
                                            {
                                                editable()
                                                && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                            }
                                        </div>
                                        <div className="flow-message-read">
                                            <div className="flow-message-read-icon">
                                                <AppIconDisplay name="additionaldriver" size={AppIconDisplaySize.S} />
                                            </div>
                                            <div className="flow-message-read-label">
                                                {t('Wizard.ADFL.Read.Experience')}
                                            </div>
                                            <div className="flow-message-read-value">
                                                {readableExperienceValue()}
                                            </div>
                                            {
                                                editable()
                                                && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            }     
                        </div>
                    </div>
                )
        }         
        </React.Fragment>
    )
}