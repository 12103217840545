import React, { useContext } from "react";
import AppButton, { AppButtonType } from "../shared/app-button/AppButton";
import { WizardManagerService } from "./WizardManager.service";
import { useTranslation } from "react-i18next";
import AppQueryHeader from "../shared/app-query-header/AppQueryHeader";
import { InsurerError } from "../../models/Comm.models";

export const FlowInterruptionMessage: React.FC<{ type?: InsurerError; }> = ({ type }) => { 
    const readTitleHandler = (): React.FC => {
        switch (type) {
            case InsurerError.ActiveVehicleInsurance:
                return ActiveVehicleInsuranceTitleHandler;
            case InsurerError.VehicleNotOwned:
                return VehicleNotOwnedTitleHandler;
            case InsurerError.VehicleInformationUnavailable:
                return VehicleInformationUnavailableTitleHandler;
            default:
                return DefaultHandler;
        }
    }
    const TitleHandler = readTitleHandler();

    const readBodyHandler = (): React.FC => {
        switch (type) {
            case InsurerError.ActiveVehicleInsurance:
                return ActiveVehicleInsuranceBodyHandler;
            case InsurerError.VehicleNotOwned:
                return VehicleNotOwnedBodyHandler;
            case InsurerError.VehicleInformationUnavailable:
                return VehicleInformationUnavailableBodyHandler;
            default:
                return DefaultHandler;
        }
    }
    const BodyHandler = readBodyHandler();

    return (
        <div className="flow-message interruption">
            <div className="flow-message-area">
                <AppQueryHeader>
                    <TitleHandler />
                </AppQueryHeader>
                <div className="flow-message-input-area">
                    <BodyHandler />
                </div>
            </div>
        </div>
    );
}

const DefaultHandler: React.FC = () => <></>;

const ActiveVehicleInsuranceTitleHandler: React.FC = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {t('Wizard.Interruption.ActiveVehicleInsurance.Query')}
        </React.Fragment>
    )
}

const ActiveVehicleInsuranceBodyHandler: React.FC = () => {
    const _wmService = useContext(WizardManagerService);
    const { t } = useTranslation();
    return (
        <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
            <AppButton type={AppButtonType.Tertiary} onPress={() => _wmService.clear()}>
                {t('Wizard.Interruption.ActiveVehicleInsurance.NewQuote')}
            </AppButton>
            <AppButton type={AppButtonType.Primary} onPress={() => _wmService.correctInterruption(InsurerError.ActiveVehicleInsurance) }>
                {t('Wizard.Interruption.ActiveVehicleInsurance.Fix')}
            </AppButton>
        </div>
    )
}

const VehicleNotOwnedTitleHandler: React.FC = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {t('Wizard.Interruption.VehicleNotOwned.Query')} 
        </React.Fragment>
    )
}

const VehicleNotOwnedBodyHandler: React.FC = () => {
    const _wmService = useContext(WizardManagerService);
    const { t } = useTranslation();
    return (
        <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
            <AppButton
                type={AppButtonType.Tertiary}
                mailto={{
                    email: 'mailto:info@manramu.lt',
                    subject: t('Wizard.Interruption.VehicleNotOwned.ContactUs.Subject'),
                    body: t('Wizard.Interruption.VehicleNotOwned.ContactUs.Body')
                }}
            >
                {t('Wizard.Interruption.VehicleNotOwned.ContactUs.Title')}
            </AppButton>
            <AppButton type={AppButtonType.Secondary} onPress={() => _wmService.correctInterruption(InsurerError.VehicleNotOwned) }>
                {t('Wizard.Interruption.VehicleNotOwned.NewQuote')}
            </AppButton>
        </div>
    )
};

const VehicleInformationUnavailableTitleHandler: React.FC = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {t('Wizard.Interruption.VehicleInformationUnavailable.Query')} 
        </React.Fragment>
    )
}

const VehicleInformationUnavailableBodyHandler: React.FC = () => {
    const _wmService = useContext(WizardManagerService);
    const { t } = useTranslation();
    return (
        <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
            <AppButton
                type={AppButtonType.Tertiary}
                mailto={{
                    email: 'mailto:info@manramu.lt',
                    subject: t('Wizard.Interruption.VehicleInformationUnavailable.ContactUs.Subject'),
                    body: t('Wizard.Interruption.VehicleInformationUnavailable.ContactUs.Body')
                }}
            >
                {t('Wizard.Interruption.VehicleInformationUnavailable.ContactUs.Title')}
            </AppButton>
            <AppButton type={AppButtonType.Secondary} onPress={() => _wmService.correctInterruption(InsurerError.VehicleInformationUnavailable) }>
                {t('Wizard.Interruption.VehicleInformationUnavailable.NewQuote')}
            </AppButton>
        </div>
    )
}