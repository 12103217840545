import React, {
    ReactNode, createContext, useEffect,
    useMemo, useRef, useState
} from 'react';
import {
    InputLayerValue, PolicyState, QuotationState,
    StepNode, WizardMap, PaymentInitiationState,
    PaymentState,
    HandledInterruptions,
    PaymentCallbackState,
} from './WizardManager.models';
import { VEHICLE_REGISTRATION_NUMBER_ID } from './wizard-steps/VRN';
import { InsurerError, PaymentCallbackContext, ApiVehicleInformation } from '../../models/Comm.models';
import { QUOTE_ID } from './wizard-steps/QUOTE/QUOTE';
import { RESIDENTIAL_AREA_ID } from './wizard-steps/ADDRESS/RA';
import { ResidentialAreaAddressCache, StreetAddressCache } from './wizard-steps/ADDRESS/AddressCache';
import { PAYMENT_ID } from './wizard-steps/PAYMENT/PAYMENT';
import { ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP } from './wizard-steps/ADFL/ADFL';
import { POLICY_DURATION_ID } from './wizard-steps/PD';
import { POLICY_START_DATE_ID } from './wizard-steps/PSD';
import { DRIVERS_FIRST_LICENCE_ID } from './wizard-steps/DFL';
import { PERSONAL_IDENTIFICATION_CODE_ID } from './wizard-steps/PIC';
import logger from '../../services/Util';
import { ACCEPT_ID } from './wizard-steps/ACCEPT/ACCEPT';
import { C_QUOTATION_ID_KEY } from '../../cookies';
import { FINISH_ID } from './flow-finish/FlowFinish';
import { usePolicyExistanceVerification } from './service-extensions/usePolicyExistanceVerification';
import { useQuotation } from './service-extensions/useQuotation';
import { usePolicy } from './service-extensions/usePolicy';
import { usePayment } from './service-extensions/usePayment';
import { RISKS_ID } from './wizard-steps/RISKS/RISKS';
import { CONFIRMATION_ID } from './wizard-steps/CONFIRMATION/CONFIRMATION';
import { PHONE_ID } from './wizard-steps/PHONE/PHONE';

type InitState = {
    counter: number
    stepId?: string;
}

export type WizardManagerServiceSignature = {
    clear: (paymentCallbackContext?: PaymentCallbackContext) => void;
    messages: StepNode[];
    activeStep: string;
    handleStepValueChange: (value: InputLayerValue | null, bindToModel: boolean, stepNode: StepNode, onlyBind: boolean, nodeToOverride?: string, bindTargetOverride?: string) => void;
    readModelValue: (key: string, fallback?: string) => InputLayerValue;
    onConfirmEditStep: (stepId: string) => void;
    quotationState: QuotationState;
    runOutdatedQuotation: () => void;
    policyState: PolicyState;
    paymentInitiationState: PaymentInitiationState;
    paymentState: PaymentState;
    processPaymentCallback: () => void;
    paymentCallbackState: PaymentCallbackState;
    initiateIssuedPolicyPayment: () => void;
    reInitiateCanceledPayment: () => void;
    initiateIssuePolicy: () => void;
    vehicleState: ApiVehicleInformation | undefined;
    initiatePolicyExistanceVerification: () => void;
    initiateQuotationCreation: () => void;
    policyExistanceVerificationState: QuotationState;
    flowBodyRef: React.RefObject<HTMLDivElement>;
    pageViewportRef: React.RefObject<HTMLDivElement>;
    residentialAreaAddressCache: ResidentialAreaAddressCache;
    streetAddressCache: StreetAddressCache;
    interruptionState: { enabled?: boolean; type?: InsurerError; };
    correctInterruption: (type: InsurerError) => void;
    scrollToBottom: () => void;
    chooseAnotherProposal: () => void;
    confirmQuotation: (insurer: InputLayerValue, node: StepNode) => void;
    model: React.RefObject<{ [key: string]: InputLayerValue }>;
}

export const WizardManagerService = createContext<WizardManagerServiceSignature>({} as any);

export const WizardManagerServiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    /** Reference to flow body DOM */
    const wizardMap = useRef<WizardMap>(new WizardMap());

    // Init hook dependency
    const [init, setInit] = useState<InitState>({ counter: 0 });

    // Message container
    const [messages, setMessages] = useState<StepNode[]>([]);

    // Stores active step identifier
    const [activeStep, setActiveStep] = useState<string>('');

    /** Reference to flow body DOM */
    const flowBodyRef = useRef<HTMLDivElement>(null);

    /** Reference to page viewport DOM */
    const pageViewportRef = useRef<HTMLDivElement>(null);

    /** Entered data model */
    const model = useRef<{ [key: string]: InputLayerValue; }>({});

    // Vehicle information store
    const [vehicleState, setVehicleState] = useState<ApiVehicleInformation>();

    // Interruption handler
    const [interruptionState, setInterruptionState] = useState<{ enabled?: boolean; type?: InsurerError; }>({});

    // Initialize
    useEffect(() => {
        if (init.counter > 0) {
            handleStepBehavior(init?.stepId ?? VEHICLE_REGISTRATION_NUMBER_ID);
        }
    }, [init]);

    /** Scrolls the flow manager scrollable element to bottom */
    const scrollToBottom = () => {
        if (flowBodyRef?.current) {
            requestAnimationFrame(() => {
                flowBodyRef?.current?.scrollTo({
                    top: flowBodyRef?.current?.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        }
    }

    /** Gets last node in message list */
    const lastNode = (x = messages) => { return x[x.length - 1]; };

    /** Reads stored model value for a particular step.  */
    const readModelValue = (key: string, fallback?: string) => {
        return model.current[key] ?? new InputLayerValue(fallback ?? undefined);
    }
    /* Updates model. */

    const updateModel = (key: string, value: InputLayerValue | null): string | undefined => {
        let currentModel = model.current;
        let newModel = { ...model.current };
        if (value === null) {
            delete newModel[key]
        } else {
            newModel[key] = value;
        }

        const vehicleHasChanged = newModel[VEHICLE_REGISTRATION_NUMBER_ID]?.value !== currentModel[VEHICLE_REGISTRATION_NUMBER_ID]?.value;
        if (vehicleHasChanged) {
            // if vehicle number has changed, remove cached vehicle data
            setVehicleState({} as ApiVehicleInformation);
        }
        const personalCodeHasChanged = newModel[PERSONAL_IDENTIFICATION_CODE_ID]?.value !== currentModel[PERSONAL_IDENTIFICATION_CODE_ID]?.value;
        const policyStartDateHasChanged = newModel[POLICY_START_DATE_ID]?.value !== currentModel[POLICY_START_DATE_ID]?.value;

        let activatableNode: string | undefined = undefined;
        if (!quotationState.outdated) {
            if (
                vehicleHasChanged
                || personalCodeHasChanged
                || policyStartDateHasChanged
                || newModel[POLICY_DURATION_ID]?.value                      !== currentModel[POLICY_DURATION_ID]?.value
                || newModel[RESIDENTIAL_AREA_ID]?.value                     !== currentModel[RESIDENTIAL_AREA_ID]?.value
                || newModel[DRIVERS_FIRST_LICENCE_ID]?.value                !== currentModel[DRIVERS_FIRST_LICENCE_ID]?.value
                || newModel[ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP]?.value !== currentModel[ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP]?.value
                || newModel[ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE]?.value !== currentModel[ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE]?.value
                || newModel[RISKS_ID]?.value                                !== currentModel[RISKS_ID]?.value
            ) {
                delete newModel.QUOTE;
                activatableNode = outdateQuote();
            }
        } else if (messages.findIndex(x => x.id === QUOTE_ID) > -1) {
            // if quote is already outdated, edit step must return to quote step (if such step is even present)
            activatableNode = QUOTE_ID;
        }

        let existanceVerification = false;
        if (
            currentModel[PERSONAL_IDENTIFICATION_CODE_ID]
            && currentModel[VEHICLE_REGISTRATION_NUMBER_ID]
            && (
                currentModel[POLICY_START_DATE_ID] // if current model already has this, it means that the wizard is further than PSD step and it's an edit.
                || newModel[POLICY_START_DATE_ID] // if only newModel has this, its means wizard is at this step and it's the 'last step'
            ) 
            && (
                vehicleHasChanged
                || personalCodeHasChanged
                || policyStartDateHasChanged
            )
        ) {
            existanceVerification = true;
        }

        model.current = newModel;

        if (existanceVerification) {
            initiatePolicyExistanceVerification();
        }
        
        logger.log(`DEBUGGER: Model was updated with key #${key}`, model.current, [...messages]);

        return activatableNode;
    }

    /** Run a particular behavior on manager level. */
    const handleStepBehavior = (id: string): void => {
        if (!id) {
            logger.error(`DEBUGGER: Please provide behaviour node id to continue.`);
        }

        if (id === FINISH_ID) {
            setActiveStep(id);
            return;
        }

        const node = wizardMap.current.steps.find(x => x.id === id);
        if (!node) {
            logger.error(`DEBUGGER: Unable to find behavior node with id #${id}`);
            return;
        }

        // NOTE: dont store states on map items.
        if (node.hiddenAfterCompletion) {
            delete node.hiddenAfterCompletion;
        }

        if (messages.findIndex(x => x.id === id) > -1) {
            logger.error(`DEBUGGER: Message with #${id} already exists. Ignoring.`);
            return;
        }

        let interruptions = policyExistanceVerificationState.interruptions;
        if ((interruptions ?? []).length === 0) {
            interruptions = quotationState.interruptions ?? [];
        }

        if (!interruptionState.enabled && interruptions.length > 0) {
            let interruptionType = InsurerError.None;
            for (let x of HandledInterruptions) {
                let prioritizedInterruption = interruptions.find(y => y === x);
                if (prioritizedInterruption) {
                    interruptionType = prioritizedInterruption;
                    break;
                }
            }
            
            setInterruptionState(() => ({
                enabled: true,
                type: interruptionType
            }));
        }   

        const insurerId = readModelValue(QUOTE_ID).valueAsInt();
        // 1. a particular step might be available or not available for a particular insurer
        // 2. a step might be skiped for whatever reason that is defined in "skip = () => {...} boolean"
        if (
            (
                (node.forInsurers ?? []).length > 0
                && insurerId
                && node.forInsurers?.indexOf(insurerId) === -1
            )
            || node?.skip?.(readModelValue)
            
        ) {
            handleStepBehavior(node.to);
            return;
        }

        // store active node id
        setActiveStep(id);

        // default value pre-application
        if (node.defaultValue) {
            let valToUse = node.defaultValue;
            if (valToUse instanceof InputLayerValue) {
                updateModel(node.id, valToUse);
            } else {
                updateModel(node.id, new InputLayerValue(node.defaultValue));
            }

        }

        setMessages(msgs => [...msgs, node]);
    }

    /**
    * Processes any step value change
    * @param value
    * @param bindToModel Whether node value should be bound to model.
    * @param stepNode Context node
    * @param onlyBind Idicates that value should be updated in model but no further actions should be taken
    * @param nodeToOverride Uses provided value for next node navigation instead of nodes internal .to
    * @param bindTargetOverride Uses provided value for model updates instead of nodes internal .id
    */
    const handleStepValueChange = (
        value: InputLayerValue | null,
        bindToModel: boolean,
        stepNode: StepNode,
        onlyBind: boolean,
        nodeToOverride?: string,
        bindTargetOverride?: string
    ): void => {
        stepNode.hiddenAfterCompletion = stepNode.hideOnCompletion;

        let activatableNode: string | undefined = undefined;
        if (bindToModel) {
            activatableNode = updateModel(bindTargetOverride ?? stepNode.id, value);
        }

        if (onlyBind) {
            return;
        }

        // value change comes from last node, meaning that its not an EDIT
        if (stepNode.id === lastNode().id) {
            // go to the next node
            handleStepBehavior(nodeToOverride ?? stepNode.to);
        } else {
            // model is updated, we can just activate the last node.
            onCompleteEditStep(activatableNode)
        }
    }

    /** Initiate edit action */
    const onConfirmEditStep = (stepId: string) => {
        setActiveStep(stepId);
    }

    /** When user is presented activevehicleinsurance interruption the user can choose to */
    const correctInterruption = (type: InsurerError) => {
        clearInterruptionState();
        // attempt to outdate the quote only if quotation was run before.
        // it can be that interuption was caused by precheck rather than the actual quotation request.
        outdateQuote();
        switch (type) {
            case InsurerError.ActiveVehicleInsurance:
                onConfirmEditStep(POLICY_START_DATE_ID);
                break;
            case InsurerError.VehicleNotOwned:
                onConfirmEditStep(VEHICLE_REGISTRATION_NUMBER_ID);
                break;
            case InsurerError.VehicleInformationUnavailable:
                onConfirmEditStep(VEHICLE_REGISTRATION_NUMBER_ID);
                break;
        }
        
    }

    /** Finish edit action */
    const onCompleteEditStep = (overideActiveNodeIdentifier?: string) => {
        setActiveStep(overideActiveNodeIdentifier ?? lastNode().id)
    }

    // Policy pre-check setup
    const [
        policyExistanceVerificationState,
        initiatePolicyExistanceVerification,
        clearPolicyExistanceVerificationState,
    ] = usePolicyExistanceVerification({
        setVehicleState,
        readModelValue
    });

    // Quotation setup | NOTE: perhaps we should avoid exposing
    // setQuotationState and delegate actions with it to exposed functions
    const [
        quotationState,
        setQuotationState,
        initiateQuotationCreation,
        clearQuotationState,
        outdateQuotation,
        runOutdatedQuotation
    ] = useQuotation({
        setVehicleState,
        readModelValue,
        scrollToBottom,
        clearPolicyExistanceVerificationState,
        interruptionState,
        setInterruptionState
    });

    /** Handle quotation selection manually without going the default handleStepValueChange route. */
    const confirmQuotation = (insurer: InputLayerValue, node: StepNode) => {
        let oldSelection = readModelValue(QUOTE_ID).value;
        updateModel(QUOTE_ID, insurer);

        if (lastNode().id === QUOTE_ID) { 
            // user has confirmed quotation for the first time
            // go to the next node
            handleStepBehavior(node.to); // this will always be "ACCEPT_ID"
        } else {
            // user has re-confirmed quotation
            let hasPhoneEntry = messages.findIndex(x => x.id === PHONE_ID) > -1;
            if (hasPhoneEntry && readModelValue(PHONE_ID).truthy()) {
                if (messages.findIndex(x => x.id === CONFIRMATION_ID) > -1) {
                    // user could have reselected offer multiple times and PAY would have been added already (see else block)
                    onCompleteEditStep()
                } else {
                    // phone entry presence and its values presence would indicate a scenario where user came back from payment step (possibly an error).
                    // see chooseAnotherProposal() for the initiation of it
                    // Just override default .to and skip to PAY step.
                    handleStepBehavior(CONFIRMATION_ID);
                }
            } else {
                // user is simply changing their mind before reahing any "locking" steps.
                let hasAcceptedBefore = messages.findIndex(x => x.id === ACCEPT_ID) > -1;
                // user should re-accept quotation selection
                if (hasAcceptedBefore && oldSelection !== insurer.value) {
                    const node = wizardMap.current.steps.find(x => x.id === ACCEPT_ID);
                    if (node) {
                        delete node.hiddenAfterCompletion;
                    }

                    onCompleteEditStep(ACCEPT_ID);
                } else {
                    onCompleteEditStep();
                }
               
            }
        }
    }

    // Policy | NOTE: perhaps we should avoid exposing policyState and
    // delegate actions with it to exposed functions
    const [
        policyState,
        clearPolicyState,
        initiateIssuePolicy,
    ] = usePolicy({
        readModelValue,
        quotationState
    });

    // Upon changes in messages and policyState.result viewport will automatically scroll to bottom
    useEffect(() => {
        scrollToBottom();
    }, [messages, policyState.result]);

    // Payment | NOTE: perhaps we should avoid exposing policyState and
    // delegate actions with it to exposed functions
    const [
        paymentInitiationState,
        paymentState,
        paymentCallbackState,
        setPaymentCallbackState,
        processPaymentCallback,
        initiateIssuedPolicyPayment,
        reInitiateCanceledPayment,
        clearPaymentInitiationState,
        clearPaymentState
    ] = usePayment({
        readModelValue,
        quotationState,
        scrollToBottom,
        activeStep,
        handleStepValueChange,
        messages,
        policyState
    });

    /** 
    * Used when policy error occurs and user needs to select a different proposal.
    * We remove "PAYMENT" step from messages, clear related states and switch over to quotation step.
    */
    const chooseAnotherProposal = () => {
        // clear policy data
        clearPolicyState(); 
        // clear payment state
        clearPaymentInitiationState();
        // clear payment state
        clearPaymentState();
        // activate quotation step
        setActiveStep(QUOTE_ID);
        // remove PAYMENT STEP
        setMessages(x => x.filter(y =>
            y.id !== PAYMENT_ID
            && y.id !== CONFIRMATION_ID
        ));
    }

    /** Outdates the generated quote. */
    const outdateQuote = () => {
        logger.log('DEBUGGER: Outdating quote.');
        outdateQuotation();
        updateModel(QUOTE_ID, null);

        let activatableNode: string | undefined = undefined;
        // remove proposal acceptance node as it should be re-accepted.
        let ix = messages.findIndex(x => x.id === ACCEPT_ID);
        if (ix > -1) {
            activatableNode = QUOTE_ID;
            setMessages(msgs => [...msgs.slice(0, ix), ...msgs.slice(ix + 2)]);
        }
        return activatableNode;
    }

    /** Policy holder address cache */
    const residentialAreaAddressCache = new ResidentialAreaAddressCache();

    /** Policy holder street address cache */
    const streetAddressCache = new StreetAddressCache();

    const clearWizardMap = () => {
        wizardMap.current = new WizardMap();
    }

    const clearModel = () => {
        model.current = {};
    }

    const clearMessages = () => {
        setMessages(() => []);
    }

    const clearActiveStep = () => {
        setActiveStep(() => '');
    }

    const clearInterruptionState = () => {
        setInterruptionState({});
    }

    const clearVehicleState = () => {
        setVehicleState(undefined);
    }

    /** Clear service. */
    const clear = (paymentCallbackContext?: PaymentCallbackContext) => {
        clearActiveStep();
        clearModel();
        clearWizardMap();
        clearMessages();
        clearQuotationState();
        clearPolicyExistanceVerificationState();
        clearInterruptionState();
        clearVehicleState();
        clearPolicyState();
        clearPaymentInitiationState();
        clearPaymentState();

        if (paymentCallbackContext?.token) {
            setInit(x => ({ stepId: FINISH_ID, counter: x.counter + 1 }));

            let cachedQuotationId = sessionStorage.getItem(C_QUOTATION_ID_KEY) ?? '';
            if (cachedQuotationId) {
                setQuotationState(x => ({ ...x, id: cachedQuotationId }));
            }

            setPaymentCallbackState({
                context: paymentCallbackContext,
                loading: false,
                ready: false
            });
        } else {
            setPaymentCallbackState({
                loading: false,
                ready: false
            });
            setInit(x => ({ counter: x.counter + 1 }));
        }

        sessionStorage.removeItem(C_QUOTATION_ID_KEY);
    }

    /** Exposed methods and refresh dependencies */
    const providerValue = useMemo<WizardManagerServiceSignature>(
        () => ({
            // objects exposed to provider
            clear,
            messages,
            activeStep,
            onConfirmEditStep,
            handleStepValueChange,
            readModelValue,
            quotationState,
            runOutdatedQuotation,
            policyState,
            paymentInitiationState,
            paymentState,
            processPaymentCallback,
            paymentCallbackState,
            initiateIssuedPolicyPayment,
            reInitiateCanceledPayment,
            initiateIssuePolicy,
            vehicleState,
            initiatePolicyExistanceVerification,
            initiateQuotationCreation,
            policyExistanceVerificationState,
            flowBodyRef,
            residentialAreaAddressCache,
            streetAddressCache,
            interruptionState,
            correctInterruption,
            pageViewportRef,
            scrollToBottom,
            chooseAnotherProposal,
            confirmQuotation,
            model
        }),
        [   // dependencies on which the refresh of this memo happens
            messages,
            activeStep,
            quotationState,
            policyState,
            paymentInitiationState,
            paymentState,
            vehicleState,
            policyExistanceVerificationState,
            flowBodyRef,
            residentialAreaAddressCache,
            streetAddressCache,
            interruptionState,
            pageViewportRef,
            model.current,
            paymentCallbackState
        ]
    );

    return (
        <WizardManagerService.Provider value={providerValue}>
            {children}
        </WizardManagerService.Provider>
    );
}
