import './AppRoutePage.scss'
import React, { ReactElement, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AppButton, { AppButtonType } from '../app-button/AppButton';
import AppIconDisplay from '../app-icon-display/AppIconDisplay';
import { CSSTransition } from 'react-transition-group';
import { AppService, ApplicationLanguge } from '../../../App';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PdfViewer, { PdfType } from '../../pdf-viewer/PdfViewer';
import { Pressable } from '@react-aria/interactions';
import { AppRoutePageServiceProvider } from './AppRoutePage.service';


/** Wrapper for all page used in routing. Keeps layouting consistent.  */
const AppRoutePage: React.FC<AppRoutePageProps> = (props) => {
    return (
        <AppRoutePageServiceProvider>
            <InternalAppRoutePage {...props} />
        </AppRoutePageServiceProvider>
    );
}

export default AppRoutePage;

export type AppRoutePageHeaderAction = {
    icon: string;
    title: string;
    callback: () => void;
    disabled?: boolean;
}

/** AppRoutePage component input props. */
export type AppRoutePageProps = {
    /** Page identifier. */
    id: string;
    /** DOM children that are projected to inside the element. */
    children?: ReactNode;
    /** Exposes main-viewport DOM. */
    mainViewportRef?: React.RefObject<HTMLDivElement>;
    /** Classes for main-viewport. */
    mainClassName?: string;
    /** Exposes page-viewport DOM. */
    pageViewportRef?: React.RefObject<HTMLDivElement>;
    /** Classes for page-viewport. */
    pageClassName?: string;
    /** Additional header actions. */
    actions?: AppRoutePageHeaderAction[];
    /** Exposes page-vbody DOM. */
    pageBodyRef?: React.RefObject<HTMLDivElement>;
    /** */
    sideArea?: ReactElement;
}

const InternalAppRoutePage: React.FC<AppRoutePageProps> = ({
    id,
    children,
    mainViewportRef,
    mainClassName,
    pageViewportRef,
    pageClassName,
    actions,
    pageBodyRef,
    sideArea
}) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { application, showPdfViewer } = useContext(AppService);
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }
    const drawerRef = useRef(null);

    useEffect(() => {
        setDrawerOpen(false);
    }, [location.pathname]);

    const insuranceCompaniesMenuItem = () => {
        return (
            <div className="app-route-page-header-drawer-content-box">
                <span>{t('Landing.Links.Companies')}</span>
            </div>
        );
    }

    return (
        <div id={id} className={`app-route-page-main-viewport ${mainClassName ?? ''}`} ref={mainViewportRef}>
            <ScrollToHash />
            <div className={`app-route-page-viewport ${pageClassName ?? ''}`} ref={pageViewportRef}>
                {sideArea}
                <div className="app-route-page-header">
                    <Pressable onPress={() => navigate("/")}>
                        <div className="main-logo svg-logo-white" />
                    </Pressable>
                   
                    <div className="app-route-page-header-right">
                        <AppButton
                            style={{ width: '45px', justifyContent: 'center'}}
                            className="app-route-page-header-language-trigger"
                            onPress={() => {
                                i18n.changeLanguage(
                                    i18n.resolvedLanguage === ApplicationLanguge.English
                                        ? ApplicationLanguge.Lithuanian
                                        : ApplicationLanguge.English
                                )
                            }}
                            type={AppButtonType.Ghost}
                        >
                            {i18n.resolvedLanguage}
                        </AppButton>

                        <AppButton
                            className="app-route-page-header-drawer-trigger"
                            onPress={() => toggleDrawer()}
                            type={AppButtonType.Ghost}
                            iconOnly={true}
                        >
                            <AppIconDisplay name="dots" />
                        </AppButton>
                    </div>

                    <CSSTransition in={drawerOpen} nodeRef={drawerRef} timeout={200} unmountOnExit>
                        <div ref={drawerRef} className="app-route-page-header-drawer">
                            <div>
                                <div className="app-route-page-header-drawer-content">
                                    {
                                        (actions ?? [])
                                            .filter(action => !action.disabled)
                                            .map(action => (
                                                <Pressable
                                                    key={`${action.title}-${action.icon}`}
                                                    onPress={() => { setDrawerOpen(false); if (action.callback) { action.callback(); } }}
                                                >
                                                    <div className="app-route-page-header-drawer-content-box" >
                                                        {action.title}
                                                    </div>
                                                </Pressable>
                                            ))
                                    }

                                    {
                                        location.pathname !== '/'
                                            ? (
                                                <Link to="/#partnerlist">
                                                    {insuranceCompaniesMenuItem()}
                                                </Link>
                                            )
                                            : (
                                                <Pressable onPress={() => setDrawerOpen(false)}>
                                                    <a href="#partnerlist" >
                                                        {insuranceCompaniesMenuItem()}
                                                    </a>
                                                </Pressable>
  
                                            )
                                    }
 
                                    <Pressable onPress={() => { setDrawerOpen(false); showPdfViewer(PdfType.CustomerInformation) }}>
                                        <div className="app-route-page-header-drawer-content-box">
                                            {t('Landing.Links.Customer')}
                                        </div>
                                    </Pressable>

                                    <Pressable onPress={() => { setDrawerOpen(false); showPdfViewer(PdfType.TermsAndConditions) }}>
                                        <div className="app-route-page-header-drawer-content-box">
                                            {t('Landing.Links.Terms')}
                                        </div>
                                    </Pressable>

                                    <Pressable onPress={() => { setDrawerOpen(false); showPdfViewer(PdfType.CookiePolicy) }}>
                                        <div className="app-route-page-header-drawer-content-box">
                                            {t('Landing.Links.Cookies')}
                                        </div>
                                    </Pressable>

                                    <Pressable onPress={() => { setDrawerOpen(false); showPdfViewer(PdfType.PrivacyPolicy) }}>
                                        <div className="app-route-page-header-drawer-content-box">
                                            {t('Landing.Links.Privacy')}
                                        </div>
                                    </Pressable>

                                    <div className="app-route-page-header-drawer-content-box disabled">
                                        Version | {`${application.assemblyVersion}`}
                                    </div>
                                </div>

                                <Pressable onPress={() => { setDrawerOpen(false); } }>
                                    <div className="app-route-page-header-drawer-background">
                                    </div>
                                </Pressable>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
                <div className="app-route-page-body" id="app-route-page-body" ref={pageBodyRef}>
                    {children}
                </div>
                <PdfViewer />
            </div>
        </div>
    );
}

const ScrollToHash = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                const scrolltainer = document.getElementById('app-route-page-body');
                scrolltainer?.scrollTo({ top: element.offsetTop });
            }
        }
    }, [hash]);

    return null;
}