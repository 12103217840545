import React, { RefObject } from "react";
import { format, addDays } from 'date-fns';
import moment from 'moment';
import { VEHICLE_REGISTRATION_NUMBER_ID, VehicleRegistrationNumberHandler } from "./wizard-steps/VRN";
import { PERSONAL_IDENTIFICATION_CODE_ID, PersonalIdentificationCodeHandler } from "./wizard-steps/PIC";
import { TERMS_ID, TermsHandler, TermsSkipHandler } from "./wizard-steps/TERMS";
import { RESIDENTIAL_AREA_ID, ResidentialAreaHandler } from "./wizard-steps/ADDRESS/RA";
import { POLICY_HOLDER_ADDRESS_ID, PolicyHolderAddressHandler, PolicyHolderAddressSkipHandler } from "./wizard-steps/ADDRESS/PHA";
import { DRIVERS_FIRST_LICENCE_ID, DriversFirstLicenceHandler } from "./wizard-steps/DFL";
import { POLICY_HOLDER_ID, PolicyHolderHandler } from "./wizard-steps/PH/PH";
import { RISKS_ID, RisksHandler } from "./wizard-steps/RISKS/RISKS";
import { ADDITIONAL_DRIVERS_FIRST_LICENCE_ID, AdditionalDriversFirstLicenceHandler } from "./wizard-steps/ADFL/ADFL";
import { POLICY_START_DATE_ID, PolicyStartDateHandler } from "./wizard-steps/PSD";
import { POLICY_DURATION_ID, PolicyDurationHandler } from "./wizard-steps/PD";
import { QUOTE_ID, QuoteHandler } from "./wizard-steps/QUOTE/QUOTE";
import { ACCEPT_ID, AcceptHandler } from "./wizard-steps/ACCEPT/ACCEPT";
import { EMAIL_ID, EmailHandler } from "./wizard-steps/EMAIL/EMAIL";
import { PHONE_ID, PhoneHandler } from "./wizard-steps/PHONE/PHONE";
import { CONFIRMATION_ID, ConfirmationHandler } from "./wizard-steps/CONFIRMATION/CONFIRMATION";
import { PAYMENT_ID, PaymentHandler } from "./wizard-steps/PAYMENT/PAYMENT";
import { FINISH_ID } from "./flow-finish/FlowFinish";
import { ApiCalculation, InsurerError, PaymentCallbackContext, PaymentInitiationResponse, PaymentStatus } from "../../models/Comm.models";

// **************************************************************************************
// Constants used across system for various purposes.
// **************************************************************************************

// Should move InsurerIdMapping to this enum and work off of it.
//export enum InsurerId {
//    'BTA' = 1,
//    'Gjensidige' = 2,
//    'ERGO' = 3,
//    'Balcia' = 4,
//    'Lietuvos Draudimas' = 5,
//    'Compensa' = 6,
//}

/** Insurer number identifier mapping to their names */
export const InsurerIdMapping: { [key: number]: string } = {
    1: 'BTA',
    2: 'Gjensidige',
    3: 'ERGO',
    4: 'Balcia',
    5: 'Lietuvos Draudimas',
    6: 'Compensa'
}

/** Default system date time format */
export const DT_FORMAT = 'yyyy-MM-dd';

// **************************************************************************************
// Types & models pertaining to wizard manager.
// **************************************************************************************

export type StepNode = {
    /** Step identifier */
    id: string;
    /** Next step */
    to: string;
    /** Functional component responsible for handling step */
    StepHandler: React.FC<WizardStepProps>;
    /** Default value of node, that system will fill once the node is initialized. */
    defaultValue?: any;
    /** Whether node should be hidden after completion. */
    hideOnCompletion?: boolean;
    /** Whether node was hidden after completion. */
    hiddenAfterCompletion?: boolean;
    /** Whether node should be hidden after quotation becomes outdated for whatever reason. */
    hideOnOutdate?: boolean;
    /** By default input values are automatically capitalized. This allows to disable that functionality. */
    disableCapitalization?: boolean;
    /** Limits step availability to specified insurers. */
    forInsurers?: number[];
    /** Per step custom logic that can be used to ignore step. */
    skip?: (...args: any[]) => boolean;
}

/** Formatted tomorrows date */
export const tomorrowDate = () => {
    let result = new Date();
    result = addDays(result, 1);
    return format(result, DT_FORMAT);
}

export type WizardStepProps = {
    node: StepNode;
    isLast: boolean;
};

/** Used to create consistent storage cache identifiers across all steps. */
export const getStepCacheIdentifier = (id: string) => {
    return `MR_CACHE_STEP_${id}`;
}

/** Step sequence map.*/
export class WizardMap {
    steps: StepNode[] = [];
    constructor() {
        this.steps = [
            {
                // VEHICLE REGISTRATION NUMBER
                id: VEHICLE_REGISTRATION_NUMBER_ID, 
                to: TERMS_ID,
                StepHandler: VehicleRegistrationNumberHandler,
                //defaultValue: 'JCP674'
            },
            {
                // TERMS AND CONDITIONS
                id: TERMS_ID,
                to: PERSONAL_IDENTIFICATION_CODE_ID,
                StepHandler: TermsHandler,
                skip: TermsSkipHandler,
                hideOnCompletion: true
            },
            {
                // PERSONAL IDENTIFICATION CODE
                id: PERSONAL_IDENTIFICATION_CODE_ID, 
                to: POLICY_START_DATE_ID,
                StepHandler: PersonalIdentificationCodeHandler,
                //defaultValue: '38911111119'
            },
            {
                // POLICY START DATE
                id: POLICY_START_DATE_ID,
                to: POLICY_DURATION_ID,
                StepHandler: PolicyStartDateHandler
            },
            {
                // POLICY DURATION
                id: POLICY_DURATION_ID,
                StepHandler: PolicyDurationHandler,
                to: RESIDENTIAL_AREA_ID,
            },
            {
                // RESIDENTIAL AREA
                id: RESIDENTIAL_AREA_ID, 
                to: DRIVERS_FIRST_LICENCE_ID,
                StepHandler: ResidentialAreaHandler,
                // defaultValue: new InputLayerValue('17507', 'Kaunos m.')
                // use this default value only if you don't need policy holder 
                // address as this approach doesn't fill meta.streets and meta.addresses.
            },
            {
                // DRIVERS FIRST LICENCE
                id: DRIVERS_FIRST_LICENCE_ID, 
                to: ADDITIONAL_DRIVERS_FIRST_LICENCE_ID,
                StepHandler: DriversFirstLicenceHandler,
                //defaultValue: new InputLayerValue('2007-11-11')
            },
            {
                // ADDITIONAL DRIVERS FIRST LICENCE
                id: ADDITIONAL_DRIVERS_FIRST_LICENCE_ID, 
                to: RISKS_ID,
                StepHandler: AdditionalDriversFirstLicenceHandler
            },
            {
                // RISKS
                id: RISKS_ID,
                StepHandler: RisksHandler,
                to: QUOTE_ID,
            },
            {
                // QUOTATION
                id: QUOTE_ID,
                StepHandler: QuoteHandler,
                to: ACCEPT_ID,
            },
            {
                // ACCEPT OFFER
                id: ACCEPT_ID,
                to: POLICY_HOLDER_ID,
                StepHandler: AcceptHandler,
                hideOnCompletion: true,
            },
            {
                // POLICY HOLDER FIRST & LAST NAME
                id: POLICY_HOLDER_ID,
                to: POLICY_HOLDER_ADDRESS_ID,
                StepHandler: PolicyHolderHandler,
                forInsurers: [2, 3, 5, 6], // [Gjensidige, ERGO, Lietuvos Draudimas, Compensa]
                disableCapitalization: true,
                hideOnOutdate: true,
            },
            {
                // POLICY HOLDER ADDRESS
                id: POLICY_HOLDER_ADDRESS_ID,
                to: EMAIL_ID,
                StepHandler: PolicyHolderAddressHandler,
                skip: PolicyHolderAddressSkipHandler,
                forInsurers: [2, 3, 5, 6], // [Gjensidige, ERGO, Lietuvos Draudimas, Compensa]
                disableCapitalization: true,
                hideOnOutdate: true,
            },
            {
                // EMAIL
                id: EMAIL_ID,
                to: PHONE_ID,
                StepHandler: EmailHandler,
                //defaultValue: 'email@domain.com',
                disableCapitalization: true,
                hideOnOutdate: true,

            },
            {
                // PHONE
                id: PHONE_ID,
                to: CONFIRMATION_ID,
                StepHandler: PhoneHandler,
                //defaultValue: '+37062222222',
                defaultValue: '+370',
                hideOnOutdate: true,
            },
            {
                // PAY
                id: CONFIRMATION_ID,
                to: PAYMENT_ID,
                StepHandler: ConfirmationHandler,
                hideOnCompletion: true,
            },
            {
                // PAYMENT
                id: PAYMENT_ID,
                to: FINISH_ID,
                StepHandler: PaymentHandler,
                hideOnCompletion: true,
            }
        ]
    }
}

// list of errors that will affect UI (prioritized).
export const HandledInterruptions = [InsurerError.VehicleNotOwned, InsurerError.VehicleInformationUnavailable, InsurerError.ActiveVehicleInsurance];

/** Personal identification code wrapper. Provides validation options. */
export class PersonalCode {
    code: string;

    constructor(_code: string) {
        this.code = _code;
    }

    hasValidLength = (): boolean => {
        return this.code.length === 11;
    }

    hasAllDigits = (): boolean => {
        return new RegExp(/^\d+$/).test(this.code);
    }

    hasValidBirthDate = (): boolean => {
        if (!this.hasValidLength()) {
            return false;
        }

        if (!this.hasAllDigits()) {
            return false;
        }

        if (this.belongsToARareException() || this.belongsToARareElderlyException()) {
            return true;
        }

        const xDate = moment({
            year: parseInt(this.code.substring(1, 3)),
            month: parseInt(this.code.substring(3, 5)) - 1,
            day: parseInt(this.code.substring(5, 7))
        });

        return xDate.isValid();
    }

    belongsToARareElderlyException = (): boolean => {
        // * Personal codes given to elderly people who cannot remember their birth month or day.
        //      In such codes, 0 is written instead of the month or day digits.
        //      This is a very rare exception

        if (!this.hasValidLength()) {
            return false;
        }

        if (!this.hasAllDigits()) {
            return false;
        }

        return this.code.substring(3, 7) === '0000';
    }

    belongsToARareException = (): boolean => {
        // * Personal codes starting with 9. The rules of normal compilation do not apply
        //      to such codes - there is no correlation between
        //      the date of birth and the digits that make up the code,
        //      nor is there a control number. However, the 11 - digit rule applies to such codes.
        //      This is a rare exception.

        if (!this.hasValidLength()) {
            return false;
        }

        if (!this.hasAllDigits()) {
            return false;
        }

        return this.code.startsWith('9');
    }

    hasValidChecksum = (): boolean => {
        // The personal code consists of 11 digits, for example: 33309240064:
        // * the digit shows the century of birth and the person's gender:
        //      1 - a man born in the 19th century,
        //      2 - a woman born in the 19th century,
        //      3 - a man born in the 20th century,
        //      4 - a woman born in the 20th century,
        //      5 - a man born in the 21st century,
        //      6 - a woman born in the 21st century);
        // * the following six � the last two digits of the person's year of birth, month (two digits), day (two digits);
        // * the next three digits are the serial number of persons born on that day;
        // * the last - a check digit is derived from the other digits.

        if (this.code.length !== 11) {
            return false;
        }

        if (!/\d/.test(this.code[10])) {
            return false;
        }

        if (this.belongsToARareException()) {
            return true;
        }

        let b = 1;
        let c = 3;
        let d = 0;
        let e = 0;
        for (let i = 0; i < 10; i++) {
            if (!/\d/.test(this.code[i])) {
                return false;
            }

            const digit = parseInt(this.code[i]);

            d += digit * b;

            e += digit * c;

            b++;
            if (b === 10) {
                b = 1;
            }

            c++;
            if (c === 10) {
                c = 1;
            }
        }

        d %= 11;
        e %= 11;

        let checkDigit: number;
        if (d < 10) {
            checkDigit = d;
        } else if (e < 10) {
            checkDigit = e;
        } else {
            checkDigit = 0;
        }

        return checkDigit === parseInt(this.code[10]);
    };

    extractPersonBirthDate = (): Date => {
        let ownerbirthdateyear = 0;

        switch (this.code[0]) {
            case '1':
            case '2':
                ownerbirthdateyear = 1800;
                break;
            case '3':
            case '4':
                ownerbirthdateyear = 1900;
                break;
            case '5':
            case '6':
                ownerbirthdateyear = 2000;
                break;
        }

        ownerbirthdateyear += parseInt(this.code.substring(1, 3), 10);

        return new Date(
            ownerbirthdateyear,
            parseInt(this.code.substring(3, 5), 10) - 1,
            parseInt(this.code.substring(5, 7), 10)
        );
    }
}


export class InputLayerValue {
    // Suggestion: make 'value' a generic.

    /** used to identifiy value e.g. '1001' */
    value: string = '';
    /** textual data used to describe value e.g 'One thousand and one' */
    text: string = '';
    /** stores any additional data regarding value */
    meta?: { [key: string]: any };

    constructor(
        _value?: string,
        _text?: string,
        _meta?: any
    ) {
        if (_value !== undefined) {
            this.value = _value;

            if (!_text) {
                this.text = this.value;
            }
        }
        if (_text) {
            this.text = _text;
        }

        this.meta = _meta;
    }

    /** Check if value is provided */
    public truthy = () => {
        return !!this.value || this.valueAsInt() !== null;
    }

    /** Get values integer representation */
    public valueAsInt = () => {
        let result = parseInt(this.value);
        if (isNaN(result)) {
            return null;
        }
        return result;
    }

    /** Get values bool representation */
    public valueAsBool = () => {
        if (this.value === 'TRUE') {
            return true;
        } 
        return false;
    }
}

export type ComplexInputLayerValue = {
    [key: string]: InputLayerValue;
}

/** Input props for various wizard input controls */
export type InputLayer = {
    identifier?: string;
    /** Input pre existing value. Whether entered before by user or a default. */
    value: InputLayerValue;
    /** Output event for notifying manager (parent) of live value changes. (keypress) */
    onValueChange?: (data: InputLayerValue) => void;
    /** Output event for notifying manager (parent) of commited value changes. (enter/blur)*/
    onSubmit?: (ref: RefObject<HTMLInputElement> | undefined, data: InputLayerValue) => void;
    /** */
    disableSubmit: boolean;
    /** */
    maxLength?: number;
    /** */
    className?: string;
    /** */
    initialFocus?: boolean;
}

export type PollerState<T> = {
    id: string;
    result?: T ;

    loading: boolean;
    ready: boolean;
    success: boolean;
}

export type PolicyState = PollerState<number> & {
    number?: string;
};

export type QuotationState = PollerState<ApiCalculation[]> & {
    /** Indicates that the quotation is outdated and it needs to be refreshed. */
    outdated: boolean;
    /** Interruptions if any. */
    interruptions: InsurerError[],
    /** */
    afterEffects: boolean;
}

export type PaymentInitiationState = {
    id: string;

    loading: boolean;
    ready: boolean;
    success: boolean;
} & PaymentInitiationResponse;

export type PaymentCallbackState = {
    context?: PaymentCallbackContext;

    loading: boolean;
    ready: boolean;
};

export type PaymentState = {
    id: string;
    paymentStatus: PaymentStatus;

    loading: boolean;
    ready: boolean;
    success: boolean;

    policyReady: boolean;
    policySuccess: boolean;
    policyNumber?: string;
    policyHolderEmail?: string;
}