import './AppTextInput.scss';
import React, { useContext, useEffect, useRef, useState } from "react";
import { InputLayer, InputLayerValue } from "../../flow-manager/WizardManager.models";
import AppButton, { AppButtonSize, AppButtonType } from "../app-button/AppButton";
import AppIconDisplay from "../app-icon-display/AppIconDisplay";
import { useTranslation } from "react-i18next";
import { Input } from "react-aria-components";
import { useFocusRing } from "../../../services/useFocusRing";
import { PreventScrollMount } from '../../../services/usePreventScroll';
import { v4 as uuid } from 'uuid';
import { InputControlContext } from '../AppInputControlContext';

type TextInputLayer = InputLayer & {
    /** Input placeholder translation key.*/
    placeholder?: string;
    hideSubmit?: boolean;
    type?: string;
    disableCapitalization?: boolean;
    scrollTarget?: HTMLElement | null;
}

export const TextInput: React.FC<TextInputLayer> = ({
    value,
    placeholder,
    onValueChange,
    onSubmit,
    disableSubmit,
    maxLength,
    disableCapitalization,
    hideSubmit,
    className,
    scrollTarget = document.documentElement,
    initialFocus = true,
    type = 'text',
}) => {
    /** Note that this is only available if this control is within FlowMessage component */
    const _cc = useContext(InputControlContext);
    const [fieldValue, setFieldValue] = useState(value);

    const acid = useRef(`off-${uuid()}`);
    const { t } = useTranslation();

    const emitOnSubmit = () => {
        if (disableSubmit || !onSubmit) {
            return;
        }
        onSubmit(undefined, fieldValue);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let uVal = e.target.value;
        if (!disableCapitalization) {
            uVal = uVal.toUpperCase();
        }
        let val = new InputLayerValue(uVal);
        setFieldValue(val);
        if (onValueChange) {
            onValueChange(val);
        }
        
    };

    const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // handle enter and escape keys
        if (['Enter', 'Escape'].indexOf(e.key) === -1) {
            return;
        }

        if (e.key === 'Escape') {
            (e.target as any).blur();
        }

        // submit changes as well
        if (e.key === 'Enter') {
            emitOnSubmit();
        }
    };

    const clear = () => {
        let val = new InputLayerValue();
        setFieldValue(val);
        if (onValueChange) {
            onValueChange(val);
        }
    }

    let { isFocused, focusProps } = useFocusRing();

    // replicate isFocused to context
    useEffect(() => {
        if (!_cc || !_cc.setIsFocused) {
            return;
        }
        _cc.setIsFocused(isFocused);
    }, [isFocused, _cc]);

    return (
        <React.Fragment>
            <div className={`input-control type-text-input${isFocused ? ' focused-input' : ''}${className ? ' ' + className : ''}`}>
                <Input
                    {...focusProps}
                    autoFocus={initialFocus ? true : undefined}
                    autoComplete={acid.current}
                    maxLength={maxLength}
                    type={type}
                    value={fieldValue.value}
                    onKeyUp={handleOnKeyUp}
                    onChange={handleOnChange}
                    placeholder={t(placeholder ?? '')}
                />
                {
                    fieldValue.truthy()
                    && (
                        <AppButton
                            className="clear-button"
                            onPress={clear}
                            type={AppButtonType.Ghost}
                            size={AppButtonSize.Medium}
                            iconOnly={true}
                            title={t('Input.Clear')}
                        >
                            <AppIconDisplay name="close" />
                        </AppButton>
                    )
                }
                {
                    !hideSubmit
                    && (
                        <AppButton
                            className="approve-button"
                            onPress={emitOnSubmit}
                            type={AppButtonType.Secondary}
                            iconOnly={true}
                            title={t('Input.Confirm')}
                        >
                            <AppIconDisplay name="send" />
                        </AppButton>
                    )
                } 
            </div> 
            {
                !_cc?.externalScrollPrevention
                && isFocused
                && <PreventScrollMount scrollTarget={scrollTarget} />
            }         
        </React.Fragment>  
    );
}
