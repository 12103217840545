import { useEffect, useState } from "react";
import { HandledInterruptions, InputLayerValue, QuotationState } from "../WizardManager.models";
import logger, { shuffleArray } from "../../../services/Util";
import {
    ApiVehicleInformation, CreateQuotationRequest,
    CreateQuotationResponse, GetQuotationResponse, InsurerError
} from "../../../models/Comm.models";
import { _http } from "../../../App";
import { PERSONAL_IDENTIFICATION_CODE_ID } from "../wizard-steps/PIC";
import { VEHICLE_REGISTRATION_NUMBER_ID } from "../wizard-steps/VRN";
import { POLICY_START_DATE_ID } from "../wizard-steps/PSD";
import { DRIVERS_FIRST_LICENCE_ID } from "../wizard-steps/DFL";
import { RESIDENTIAL_AREA_ID } from "../wizard-steps/ADDRESS/RA";
import { POLICY_DURATION_ID } from "../wizard-steps/PD";
import { RISKS_ID } from "../wizard-steps/RISKS/RISKS";
import { ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE, ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP } from "../wizard-steps/ADFL/ADFL";
import { mergeAndUpdateQuotationCalculations } from "./mergeAndUpdateQuotationCalculations";

type PollContext = {
    id: string,
    count: number,
    timestamp: any
}

type Props = {
    setVehicleState: React.Dispatch<React.SetStateAction<ApiVehicleInformation | undefined>>;
    readModelValue: (key: string, fallback?: string) => InputLayerValue;
    scrollToBottom: () => void;
    clearPolicyExistanceVerificationState: () => void;
    interruptionState: any;
    setInterruptionState: React.Dispatch<React.SetStateAction<any>>;
}
export function useQuotation(props: Props): [
    QuotationState,
    React.Dispatch<React.SetStateAction<QuotationState>>,
    () => void,
    () => void,
    () => void,
    () => void
] {
    let {
        setVehicleState,
        readModelValue,
        scrollToBottom,
        clearPolicyExistanceVerificationState,
        interruptionState,
        setInterruptionState
    } = props;

    // Default state
    const getInitialQuotationState = () => ({
        id: '',
        result: [],

        loading: false,
        ready: false,
        success: false,
        outdated: false,
        interruptions: [],
        afterEffects: false
    });

    // Quotation information & ux state
    const [quotationState, setQuotationState] = useState<QuotationState>(getInitialQuotationState());

    // Quotation polling context
    const [quotationPoller, setQuotationPoller] = useState<PollContext>({ id: '', count: 0, timestamp: 0 });

    // Quotation polling scheduling handler 
    useEffect(() => {
        if (!quotationState.loading || quotationState.outdated) {
            return;
        }
        const timeoutId = setTimeout(() => pollQuotation(), 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "quotationPoller" changes
    }, [quotationPoller]);

    // Initiates quotation process
    const initiateQuotationCreation = () => {
        // cancel verification check and ignore its results.
        clearPolicyExistanceVerificationState();
        setQuotationAnimationPoller(0);
        clearQuotationState();

        const request: CreateQuotationRequest = {
            holder: {
                code: readModelValue(PERSONAL_IDENTIFICATION_CODE_ID).value,
                licence: readModelValue(DRIVERS_FIRST_LICENCE_ID).value
            },
            vehicle: {
                number: readModelValue(VEHICLE_REGISTRATION_NUMBER_ID).value
            },
            residentialAreaId: readModelValue(RESIDENTIAL_AREA_ID).valueAsInt(),
            start: readModelValue(POLICY_START_DATE_ID).value,
            duration: readModelValue(POLICY_DURATION_ID).valueAsInt(),
            risk: readModelValue(RISKS_ID).valueAsInt(),
        };

        const otherExperience = readModelValue(ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_EXP);
        const otherAge = readModelValue(ADDITIONAL_DRIVERS_FIRST_LICENCE_ID_AGE);
        if (otherExperience.truthy() && otherAge.truthy()) {
            request.experience = otherExperience.value;
            request.age = otherAge.value;
        }

        _http
            .post(request, '/api/quote')
            .res()
            .then(async response => {
                let cqr = {} as CreateQuotationResponse;
                if (response.body) {
                    cqr = JSON.parse(await response.text());
                }

                setTimeout(() => {
                    // artifically delay initial quotation setup to improve UX flow and jarriness
                    processResponse(cqr, response.headers.get('X-Timestamp'), true, cqr.id);
                }, 500);
                
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while initiating quotation creation', error);
                setQuotationState(qs => ({
                    ...qs,
                    loading: false,
                    ready: true,
                    success: false,
                    interruptions: []
                }));
            });
    }

    // Polls for quotation result
    const pollQuotation = () => {
        _http
            .headers({ 'X-Timestamp': quotationPoller.timestamp })
            .get(`/api/quote/${quotationPoller.id}`)
            .res()
            .then(async response => {
                let gqr = {} as GetQuotationResponse;
                if (response.body) {
                    gqr = JSON.parse(await response.text());
                }
                processResponse(gqr, response.headers.get('X-Timestamp'), false);
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling for quotation', { details: error });
                setQuotationState(qs => ({
                    ...qs,
                    loading: false,
                    ready: true,
                    success: false,
                    interruptions: []
                }));
            });
    };

    // serves as a callback to initiateQuotationCreation.setQuotationState(....)
    useEffect(() => {
        // ignore state updates that have no results.
        if ((quotationState.result ?? []).length === 0) {
            return;
        }

        if (!quotationState.afterEffects) {
            setQuotationState(qs => ({ ...qs, afterEffects: true }));
            setQuotationAnimationPoller(quotationAnimationPoller + 1);
        } else if (quotationAnimationPoller === 1) {
            scrollToBottom();
        }
    }, [quotationState]);

    const [quotationAnimationPoller, setQuotationAnimationPoller] = useState(0);
    useEffect(() => {
        if (
            quotationAnimationPoller === 0
            || (
                quotationState.ready
                && (quotationState.result ?? []).every(r => r.progress.isCompleted())
            )
        ) {
            return;
        }

        const intervalSeconds = 1000;

        const timeoutId = setTimeout(() => {
            let dtNow = Date.now();
            quotationState.result?.forEach(r => {
                // dont change to next state if min time between state changes is not lasted
                let timeSinceLastUpdate = dtNow - r.progress.lastChanged();

                r.progress.update();

                if (timeSinceLastUpdate > intervalSeconds) {
                    if (r.progress.isPulling()) {
                        r.progress.completeIt();
                    }
                }
            });
            setQuotationAnimationPoller(quotationAnimationPoller + 1);
        }, intervalSeconds);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [quotationAnimationPoller]);

    const processResponse = (response: (CreateQuotationResponse | GetQuotationResponse), timestamp: any, shuffle: boolean, id?: string) => {
        setQuotationState(qs => {
            let ctxId = id ?? qs.id;

            let calculations = mergeAndUpdateQuotationCalculations(
                (qs.result ?? []),
                response?.calculations ?? [],
                response.ready
            );
            if (shuffle) {
                calculations = shuffleArray(calculations);
            }

            const interruptions = calculations.reduce((acc: InsurerError[], cur) => {
                if (
                    cur.error
                    && HandledInterruptions.includes(cur.error)
                    && !acc.includes(cur.error)
                    && (
                        cur.error !== InsurerError.VehicleInformationUnavailable
                        || calculations.every(c =>
                             c.error === InsurerError.VehicleInformationUnavailable
                            || c.error === InsurerError.FunctionalityNotImplemented
                        )
                        // in case of VehicleInformationUnavailable we should verify that ALL insurers return this error
                        // see MANRAMU-436
                    )
                ) {
                    acc.push(cur.error);
                }
                return acc;
            }, []);

            if (response.ready) {
                if (interruptions.length > 0) {
                    // in an event when all calculations return errors, set the interruption state instantly and hide quotation block.
                    if (calculations.every(x => !x.premium || x.error)) {
                        if (!interruptionState.enabled) {
                            let interruptionType = InsurerError.None;
                            for (let x of HandledInterruptions) {
                                let prioritizedInterruption = interruptions.find(y => y === x);
                                if (prioritizedInterruption) {
                                    interruptionType = prioritizedInterruption;
                                    break;
                                }
                            }
                        
                            setInterruptionState(() => ({
                                enabled: true,
                                type: interruptionType
                            }));
                        }
                    }
                } else {
                    // interruptions are only "HandledInterruptions", there can be other interruptions that could block this.
                    if (
                        response.vehicle
                        && calculations.some(x => x.premium) // allow setting vehicle only if some insurer returned price
                        // && response.vehicle.owner  // maybe additional verification on ".vehicle.owner" ?
                    ) {
                        setVehicleState(vs => ({
                            ...(vs ?? {} as ApiVehicleInformation),
                            ...(response.vehicle ?? {} as ApiVehicleInformation)
                        }));
                    }
                }

                 scrollToBottom();
            } else {
                 setQuotationPoller(() => ({
                    id: ctxId,
                    count: 0,
                    timestamp: timestamp
                }));
            }

            return ({
                ...qs,
                id: ctxId,
                result: calculations,
                ready: response.ready,
                loading: !response.ready,
                success: response.ready,
                interruptions
            });
        });
    }

    const outdateQuotation = () => {
        if (!quotationState.outdated && quotationState.id) {
            clearQuotationPoller();
            setQuotationState(qs => ({
                ...qs,
                ready: true,
                outdated: true
            }));
        }
    }

    const clearQuotationState = () => {
        setQuotationState(() => getInitialQuotationState());
        clearQuotationPoller();
    }

    const clearQuotationPoller = () => {
        setQuotationPoller({ id: '', count: 0, timestamp: 0 });
        setQuotationAnimationPoller(0);
    }

    /** Re-initiates quotation process. */
    const runOutdatedQuotation = () => {
        initiateQuotationCreation();
    }

    return [
        quotationState,
        setQuotationState,
        initiateQuotationCreation,
        clearQuotationState,
        outdateQuotation,
        runOutdatedQuotation,
    ];
}