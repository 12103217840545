import './AppEmphasizedText.scss'
import React, { ReactNode } from "react";

export enum AppEmphasizedTextTheme {
    Black = 'black',
    Orange = 'orange',
    Green = 'green'
}

export enum AppEmphasizedTextWeight {
    Bold = 'bold',
    Normal = 'normal'
}

type AppEmphasizedTextProps = {
    children?: ReactNode;
    theme?: AppEmphasizedTextTheme;
    weight?: AppEmphasizedTextWeight;
}

const AppEmphasizedText: React.FC<AppEmphasizedTextProps> = ({
    children,
    theme = AppEmphasizedTextTheme.Orange,
    weight = AppEmphasizedTextWeight.Bold
}) => {
    return (
        <span className={`app-emphasized-text theme--${theme} weight--${weight}`}>
            {children}
        </span>
    ); 
}

export default AppEmphasizedText;