import './AppCheckbox.scss';
import React, { ReactNode, useEffect, useState } from "react";

export type AppCheckboxProps = {
    checked: boolean,
    children?: ReactNode,
    onCheckedChange: (value: boolean) => void;
    disabled?: boolean;
}

// placeholder for eventual implementation of checkbox component
const AppCheckbox: React.FC<AppCheckboxProps> = ({
    checked,
    children,
    onCheckedChange,
    disabled
}) => {
    const [trackedValue, setTrackedValue] = useState(checked);

    useEffect(() => {
        setTrackedValue(checked);
    }, [checked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }

        setTrackedValue(event.target.checked);

        if (onCheckedChange) {
            onCheckedChange(event.target.checked);
        }
    };

    return (
        <label className={`app-checkbox ${disabled ? ' ac-disabled' : ''}`}>
            <input
                className={'app-checkbox-input'}
                type={'checkbox'}
                checked={trackedValue}
                onChange={handleChange}
            />
            <span className={'app-checkbox-text'}>
                {children}
            </span>
        </label>
    );
}

export default AppCheckbox;