import React, { RefObject, useState } from "react";
import { InputLayerValue, WizardStepProps } from "../WizardManager.models";
import { TextInput } from "../../shared/app-text-input/AppTextInput";
import { FlowMessageReadEdit } from "../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "./WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import { AppLoader, AppLoaderSize } from '../../shared/app-loader/AppLoader';
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../flow-message/FlowMessage';

export const VEHICLE_REGISTRATION_NUMBER_ID = 'VRN';

export const VehicleRegistrationNumberHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, readValue } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [stepValue, setStepValue] = useState(_wmService.readModelValue(node.id));
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (value: InputLayerValue) => {
        const results = [];
        const x: string = value.value;
        if (x.length === 0) {
            results.push('Validation.Mandatory');
        } else if (!/^\w*\d\w*$/.test(x)) {
            results.push('Validation.InvalidVehicleRegistrationNumber');
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (submitValidationRun) {
            setDisableSubmit(validate(data).length !== 0);
        }      
    };

    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        setStepValue(value);

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }
        
        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            // of validation is ok, just bubble up
            _wmService.handleStepValueChange(value, true, node, false)
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    const vehicleValue = () => {  
        let result = [];
        let year = (_wmService.vehicleState?.year ?? '').toString().trim();
        if (year) {
            result.push(year);
        }

        let make = (_wmService.vehicleState?.make ?? '').toString().trim();
        if (make) {
            result.push(make);
        }

        let model = (_wmService.vehicleState?.model ?? '').toString().trim();
        if (model) {
            result.push(model);
        }

        let strResult = result.join(' ');
        return strResult || '-'
    }

    const vehicleValuePresentable = () => {
        return _wmService.vehicleState?.make
            || _wmService.vehicleState?.model
            || _wmService.vehicleState?.year
            || _wmService.quotationState.ready
            || _wmService.policyExistanceVerificationState.ready
    }

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={VEHICLE_REGISTRATION_NUMBER_ID}
                            queryArea={
                                _wmService.messages.length === 1
                                    ? (
                                        <React.Fragment>
                                            <AppQueryHeader>{t('Wizard.Welcome.Query')}</AppQueryHeader>
                                            <AppQueryHeader>{t('Wizard.VRN.Edit.QueryWelcome')}</AppQueryHeader>
                                        </React.Fragment>
                                    )
                                    : (
                                        <AppQueryHeader>{t('Wizard.VRN.Edit.Query')}</AppQueryHeader>
                                    )
                            }
                            inputArea={() => 
                                <TextInput
                                    onValueChange={handleInputChange}
                                    placeholder={'Wizard.VRN.Edit.Placeholder'}
                                    onSubmit={(x, y) => handleSubmit(x, y)}
                                    disableSubmit={!active() && disableSubmit}
                                    value={stepValue}
                                    maxLength={6}
                                    scrollTarget={_wmService.flowBodyRef.current}
                                />
                            }
                            assistanceRecords={validationResults.map(x => ({ key: x, type: FlowMessageAssistanceRecordType.Error } as FlowMessageAssistanceRecord))}
                            withConfirm={true}
                        />     
                    )
                    : (
                        <React.Fragment>
                            <div data-step-id={VEHICLE_REGISTRATION_NUMBER_ID} className={`flow-message disabled`}>
                                <div className={`flow-message-area`}>
                                    <div className="flow-message-read">
                                        <div className="flow-message-read-icon">
                                            <AppIconDisplay name="carplate" size={AppIconDisplaySize.S} />
                                        </div>
                                        <div className="flow-message-read-label">
                                            {t('Wizard.VRN.Read.Query')}
                                        </div>
                                        <div className="flow-message-read-value">
                                            {readValue()}
                                        </div>
                                        {
                                            editable()
                                            && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div data-step-id={VEHICLE_REGISTRATION_NUMBER_ID} className={`flow-message disabled animated-off`}>
                                <div className={`flow-message-area`}>
                                    <div className="flow-message-read">
                                        <div className="flow-message-read-icon">
                                            <AppIconDisplay name="vehicle" size={AppIconDisplaySize.S} />
                                        </div>
                                        <div className="flow-message-read-label">
                                            {t('Wizard.VEHICLE.Read.Query')}
                                        </div>
                                        <div className="flow-message-read-value">
                                            {
                                                vehicleValuePresentable()
                                                    ? vehicleValue()
                                                    : <AppLoader size={AppLoaderSize.Small} style={{ display: 'inline-block'}} />
                                            }
                                        </div>
                                        {
                                            editable()
                                            && (
                                                <div className="flow-message-read-edit">
                                                    <div style={{ height: '24px', width: '24px' }}>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
            }
        </React.Fragment>
    )
}