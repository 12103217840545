import './styles/normalize.scss';
import './styles/styles.scss';
import './styles/aria-styles.scss'
import './styles/cookiebot.scss'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css'
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logger from './services/Util';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import './i18n'

// configures logger to be exposed to console
window.o537038ug = logger.o537038ug;

// configures pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();