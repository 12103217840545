import './PolicyDocuments.scss';
import React, { useEffect, useState } from "react";
import { PollerState } from "../../flow-manager/WizardManager.models";
import logger, { getEnumName } from "../../../services/Util";
import { _http } from "../../../App";
import mime from 'mime';
import { useTranslation } from "react-i18next";
import { AppLoader } from "../../shared/app-loader/AppLoader";
import AppButton, { AppButtonType } from '../../shared/app-button/AppButton';
import FlowMessage from '../../flow-manager/flow-message/FlowMessage';
import AppQueryHeader from '../../shared/app-query-header/AppQueryHeader';
import AppIconDisplay, { AppIconDisplaySize } from '../../shared/app-icon-display/AppIconDisplay';
import { ApiPolicyDocument, GetPolicyDocumentsResponse, PolicyDocumentType } from '../../../models/Comm.models';

type PollContext = {
    id: string;
    count: number;
}

type PolicyDocumentsProps = {
    /** Policy identifier. */
    policyIdentifier: string;
    /** Last 4 digits of policy holder personal code. Provide only when accessed out of flow. */
    personalCodePart?: string;
    onFixPersonalCodePart?: () => void;
}

export type PolicyDocumentsState = PollerState<ApiPolicyDocument[]>;

const PolicyDocuments: React.FC<PolicyDocumentsProps> = ({ policyIdentifier, personalCodePart, onFixPersonalCodePart }) => {
    const { t } = useTranslation();

    // Policy document polling context
    const [policyDocumentsPoller, setPolicyDocumentsPoller] = useState<PollContext>({ id: policyIdentifier, count: 0 });

    // Policy documents information & ui state
    const [policyDocumentsState, setPolicyDocumentsState] = useState<PolicyDocumentsState>({
        id: policyIdentifier,
        result: [],
        loading: true,
        ready: false,
        success: false
    });

    // Policy documents polling scheduling handler 
    useEffect(() => {
        if (!policyDocumentsState.loading) {
            return;
        }
        const timeoutId = setTimeout(() => pollPolicyDocuments(), policyDocumentsPoller.count === 0 ? 0 : 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "policyDocumentsPoller" changes
    }, [policyDocumentsPoller]);

    /** Polls for policy documents */
    const pollPolicyDocuments = () => {
        let authCodeExt = personalCodePart ? `?code=${personalCodePart}` : '';
        _http
            .get(`/api/policy/${policyIdentifier}/documents${authCodeExt}`)
            .res()
            .then(async response => {
                let gpdr = {} as GetPolicyDocumentsResponse;
                if (response.body) {
                    gpdr = JSON.parse(await response.text());
                }

                setTimeout(() => {
                    setPolicyDocumentsState(pds => ({
                        ...pds,
                        loading: false,
                        result: gpdr.documents,
                        success: gpdr.ready
                    }));

                    setTimeout(() => {
                        if (!gpdr.ready) {
                            // reset poller
                            setPolicyDocumentsPoller(ctx => ({ ...ctx, count: ctx.count + 1 }));
                        } else {
                            const processedDocs: ApiPolicyDocument[] = [];

                            // Side load individual document files as blobs.
                            return Promise
                                .all(
                                    gpdr.documents.map(doc => _http
                                        .get(`/api/document/${doc.id}${authCodeExt}`)
                                        .blob()
                                        .then(documentResponse => {
                                            doc.blob = documentResponse;
                                            doc.blobSuccess = true;
                                            processedDocs.push(doc);

                                            // update state after each side-load is completed to keep UI up to date.
                                            setPolicyDocumentsState(pds => {
                                                const ready = processedDocs.length === gpdr.documents.length;
                                                return ({
                                                    ...pds,
                                                    ready: ready,
                                                    result: (pds.result ?? []).map(x => {
                                                        x.blob = (processedDocs.find(y => y.id === x.id)?.blob ?? undefined);
                                                        return x;
                                                    })
                                                });
                                            });
                                        })
                                        .catch(error => {
                                            logger.error('DEBUGGER: An issue occured while getting single document', { details: error });
                                            doc.blobSuccess = false;
                                            processedDocs.push(doc);
                                        })
                                        .finally(() => {
                                            doc.blobReady = true;
                                        })
                                    )
                                )
                                .finally(() => {
                                    requestAnimationFrame(() => {
                                        setPolicyDocumentsState(pds => ({
                                            ...pds,
                                            loading: false,
                                            ready: true,
                                            success: (gpdr.documents ?? []).every(x => x.blobSuccess)
                                        }));
                                    });
                                })
                                .catch(error => {
                                    logger.error('DEBUGGER: An issue occured while getting all single documents', { details: error });
                                })
                        }
                    }, 2000);
                }, 2000);
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling for document list', { details: error });

                setTimeout(() => {
                    setPolicyDocumentsState(pds => ({ ...pds, loading: false, ready: true, success: false }));
                }, 2000);
            });
    };

    const downloadDoc = (doc: ApiPolicyDocument) => {
        if (!doc.blob) {
            logger.log('DEBUGGER: Cant download doc, blob not provided', doc);
            return;
        }
        logger.log('DEBUGGER: Downloading doc', doc);

        // Create a URL for the Blob
        const url = URL.createObjectURL(doc.blob);

        // Create a link element
        const link = document.createElement("a");

        // Fill link element
        link.href = url;
        link.download = `${translateDocType(doc.type)}_${doc.id}.${mime.getExtension(doc.contentType)}`;

        // Trigger link element
        link.click();

        // Clean up by revoking the URL
        URL.revokeObjectURL(url);
    }

    const translateDocType = (input?: number) => {  
        const docType = (input === 0 || input == null)
            ? PolicyDocumentType.Other
            : input;
        return t(`PolicyDocumentType.${getEnumName(docType, PolicyDocumentType)}`);
    }

    return (
        <div className="document-list-container">
            {
                !(personalCodePart && policyDocumentsState.ready && !policyDocumentsState.success)
                && (
                    <div className="document-list-header">
                        <AppQueryHeader>
                            {
                                policyDocumentsState.ready
                                    ? t('DocumentList.Ready')
                                    : t('DocumentList.Preparing')
                            }
                        </AppQueryHeader>
                    </div>
                )
            }
            {
                (policyDocumentsState.loading || (policyDocumentsState?.result ?? []).length > 0)
                && (
                    <div className="document-list">
                        {
                            policyDocumentsState.loading
                            && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <AppLoader />
                                </div>
                            )
                        }
                        {
                            (policyDocumentsState?.result ?? []).map(pdsr => (
                                <div key={pdsr.id} className="document-list-item">
                                    <div className="document-list-item-content">
                                        {
                                            pdsr.blobReady
                                                ? <AppIconDisplay
                                                    name={'doc-done'}
                                                    size={AppIconDisplaySize.XL}
                                                />
                                                : <AppIconDisplay
                                                    name={'doc-shine'}
                                                    size={AppIconDisplaySize.XXL}
                                                />
                                        }

                                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', color: '#79C2C6', fontFamily: 'Poppins, sans-serif', fontWeight: '600', whiteSpace: 'normal' }}>
                                            {
                                                pdsr.blobReady
                                                    ? (
                                                        pdsr.blobSuccess
                                                            ? (
                                                                <AppButton type={AppButtonType.Tertiary} style={{ gap: '0.5rem', maxWidth: '260px' }} onPress={() => { pdsr.blobSuccess && downloadDoc(pdsr) }}>
                                                                    <span>{translateDocType(pdsr.type)}</span>
                                                                    <AppIconDisplay name="download" size={AppIconDisplaySize.XS} />
                                                                </AppButton>
                                                            )
                                                            : t('DocumentList.Document.Unavailable', { documentType: translateDocType(pdsr.type) })
                                                    )
                                                    : t('DocumentList.Document.InProgress', { documentType: translateDocType(pdsr.type) })
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                personalCodePart
                && policyDocumentsState.ready
                && !policyDocumentsState.success
                && (
                    <div className="document-list-footer">
                        <FlowMessage
                            stepId="POLICY-DOCUMENTS-ERROR"
                            queryArea={
                                <AppQueryHeader>{t('ExternalAccessPolicy.IncorrectCode.Title')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
                                    <AppButton onPress={() => onFixPersonalCodePart?.()}>
                                        {t('ExternalAccessPolicy.IncorrectCode.Fix')}
                                    </AppButton>
                                </div>
                            }
                            padded={false}
                        />
                    </div>
                )
            }
        </div>
    );
}

export default PolicyDocuments;