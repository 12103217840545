import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
    InputLayerValue, PaymentCallbackState, PaymentInitiationState,
    PaymentState, PolicyState, QuotationState, StepNode
} from "../WizardManager.models";
import logger, { useStateCallback } from "../../../services/Util";
import { AppService, _http } from "../../../App";
import { C_QUOTATION_ID_KEY } from "../../../cookies";
import { useTranslation } from "react-i18next";
import {
    GetPolicyPaymentStatusResponse, GetPolicyResponse, PaymentClientSideCallbackResponse, PaymentInfo,
    PaymentInitiationResponse, PaymentStatus
} from "../../../models/Comm.models";
import { PAYMENT_ID } from "../wizard-steps/PAYMENT/PAYMENT";
import { EMAIL_ID } from "../wizard-steps/EMAIL/EMAIL";

type PolicyInfo = {
    id: string;
    number?: string;
}

type PolicyPollContext = {
    id: string;
    count: number;
    override: boolean;
}

type Props = {
    readModelValue: (key: string, fallback?: string) => InputLayerValue;
    quotationState: QuotationState;
    scrollToBottom: () => void;
    activeStep: string;
    handleStepValueChange: (value: InputLayerValue, bindToModel: boolean, stepNode: StepNode, onlyBind: boolean, nodeToOverride?: string, bindTargetOverride?: string) => void;
    messages: StepNode[];
    policyState: PolicyState;
}
export function usePayment(props: Props):
    [
        PaymentInitiationState,
        PaymentState,
        PaymentCallbackState,
        Dispatch<SetStateAction<PaymentCallbackState>>,
        () => void,
        () => void,
        () => void,
        () => void,
        () => void
    ]
{
    const { application } = useContext(AppService);
    const { i18n } = useTranslation();

    let {
        readModelValue,
        quotationState,
        scrollToBottom,
        activeStep,
        handleStepValueChange,
        messages,
        policyState
    } = props;

    const [paymentInitiationState, setPaymentInitiationState] = useState<PaymentInitiationState>({
        id: '',
        paymentAlreadyProcessing: false,
        loading: false,
        ready: false,
        success: false
    });

    const [paymentState, setPaymentState] = useState<PaymentState>({
        id: '',
        loading: false,
        ready: false,
        success: false,
        paymentStatus: PaymentStatus.None,
        policyReady: false,
        policySuccess: false
    });

    const initiatePayment = (policy: PolicyInfo) => {
        if (paymentInitiationState.loading) {
            return;
        }

        const policyId = policy.id;

        if (!policyId) {
            logger.error('DEBUGGER: Policy identifier not found');
            return;
        }

        if (application.environmentName !== 'Production') {
            sessionStorage.setItem(C_QUOTATION_ID_KEY, quotationState.id);
        }

        setPaymentInitiationState({
            id: policyId,
            paymentAlreadyProcessing: false,
            loading: true,
            ready: false,
            success: false
        });

        const paymentInfo: PaymentInfo = {
            policyExternalId: policyId
        };

        if (i18n.resolvedLanguage) {
            paymentInfo.defaultLocale = i18n.resolvedLanguage;
        }

        _http
            .post(paymentInfo, `/api/payment/initiate`)
            .res()
            .then(async response => {
                const result: PaymentInitiationResponse = await JSON.parse(await response.text());

                if (result.paymentAlreadyProcessing) {
                    if (activeStep === PAYMENT_ID) {
                        handleStepValueChange(
                            new InputLayerValue('PAYMENT.PENDING', 'Payment pending'),
                            true,
                            messages.find(x => x.id === PAYMENT_ID)!,
                            false
                        );
                    }
                    setPaymentState(() => ({
                        id: policyId,
                        loading: false,
                        ready: false,
                        success: false,
                        paymentStatus: PaymentStatus.Pending,
                        policyReady: false,
                        policySuccess: false
                    }));
                    setPaymentInitiationState(pis => ({ ...pis, ...result, loading: false }));
                } else if (result.dataToken) {
                    setPaymentInitiationState(pis => ({ ...pis, ...result, loading: true }));
                    window.location.href = `https://psd2.neopay.lt/widget.html?${result.dataToken}`;
                }
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while initiating payment', { details: error });
                setPaymentInitiationState(pis => ({ ...pis, loading: false, ready: true, success: false } as PaymentInitiationState));
            })
    }


    useEffect(() => {
        if (paymentState.paymentStatus === PaymentStatus.Success) {
            scrollToBottom();
        }

        if (
            !paymentState.id
            || paymentState.loading
            || (paymentState.policyReady && !paymentState.policySuccess)
            || paymentState.paymentStatus !== PaymentStatus.Pending
        ) {
            return;
        }
        const timeoutId = setTimeout(() => refreshPaymentState(), 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "paymentState" changes
    }, [paymentState]);

    const refreshPaymentState = () => {
        const policyId = paymentState.id;
        if (!policyId) {
            logger.error('DEBUGGER: Cannot refresh payment state. Policy identifier not found.');
            setPaymentState(x => ({
                ...x,
                loading: false,
                ready: true,
                success: false
            }));
            return;
        }

        setPaymentState(x => ({
            ...x,
            loading: true,
            ready: false,
            success: false
        }));

        _http
            .get(`/api/policy/${policyId}/payment/status`)
            .res()
            .then(async response => {
                const result: GetPolicyPaymentStatusResponse = await JSON.parse(await response.text());
                const success = result.paymentStatus === PaymentStatus.Success;

                setPaymentState(x => ({
                    ...x,
                    paymentStatus: result.paymentStatus,
                    loading: false,
                    ready: true,
                    success: true
                }));

                if (success) {
                    // after payment is successful policy has to be re-retrieved. It will then have the number and email in it.
                    setPaymentPolicyPoller(ctx => ({
                        id: policyId,
                        count: ctx.count + 1,
                        override: true
                    }));
                }
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling payment state', { details: error });

                setPaymentState(x => ({
                    ...x,
                    loading: false,
                    ready: true,
                    success: false
                }));
            })
    }

    const [paymentCallbackState, setPaymentCallbackState] = useState<PaymentCallbackState>({
        loading: true,
        ready: false
    });

    const processPaymentCallback = () => {
        if (!paymentCallbackState.context || paymentCallbackState.ready || paymentCallbackState.loading) {
            return;
        }

        const context = paymentCallbackState.context;
        if (!context.token) {
            logger.error('DEBUGGER: Payment callback context not provided');
            setPaymentCallbackState(pcs => ({ ...pcs, loading: false, ready: true }));
            return;
        }

        setPaymentCallbackState(pcs => ({ ...pcs, context, ready: true }));

        _http
            .post(context, `/api/payment/callback/clientside`)
            .res()
            .then(async response => {
                const result: PaymentClientSideCallbackResponse = await JSON.parse(await response.text());
                const success = result.paymentStatus === PaymentStatus.Success;

                setPaymentState(x => ({
                    ...x,
                    id: result.policyId,
                    paymentStatus: result.paymentStatus,
                    loading: success,
                    ready: true,
                    success: true
                }));

                if (success) {
                    // after payment is successful policy has to be re-retrieved. It will then have the number and email in it.
                    setPaymentPolicyPoller(ctx => ({
                        id: result.policyId,
                        count: ctx.count + 1,
                        override: true
                    }));
                } else {
                    setPaymentCallbackState(pcs => ({ ...pcs, loading: false, ready: true }));
                }
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while processing payment callback', { details: error });

                setPaymentState(x => ({
                    ...x,
                    loading: false,
                    ready: true,
                    success: false
                }));
                setPaymentCallbackState(pcs => ({ ...pcs, loading: false, ready: true }));
            });
    }

    const initiateIssuedPolicyPayment = () => {
        if (!policyState.id || policyState.loading || !policyState.ready || !policyState.success) {
            logger.error('DEBUGGER: Payment state does not qualify for initiateIssuedPolicyPayment');
            return;
        }

        initiatePayment({ id: policyState.id, number: policyState.number });
    }

    const reInitiateCanceledPayment = () => {
        if (!paymentState.id || paymentState.paymentStatus !== PaymentStatus.Canceled || paymentState.loading || paymentInitiationState.loading) {
            logger.error('DEBUGGER: Payment state does not qualify for reInitiateCanceledPayment');
            return;
        }

        initiatePayment({ id: paymentState.id, number: paymentState.policyNumber });
    }

    // Policy information & ux state */
    const [paymentPolicyLoading, setPaymentPolicyLoading] = useState(false);

    // Policy polling context */
    const [paymentPolicyPoller, setPaymentPolicyPoller] = useState<PolicyPollContext>({ id: '', count: 0, override: false });

    // Policy polling scheduling handler */
    useEffect(() => {
        if (!paymentPolicyLoading && !paymentPolicyPoller.override) {
            return;
        }
        const timeoutId = setTimeout(() => pollPolicy(), paymentPolicyPoller.count === 0 ? 0 : 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "policyPoller" changes
    }, [paymentPolicyPoller]);

    /** Polls for policy issue result */
    const pollPolicy = () => {
        const policyId = paymentPolicyPoller.id;

        _http
            .get(`/api/policy/${policyId}`)
            .res()
            .then(async response => {
                let getPolicyResponse = {} as GetPolicyResponse;
                if (response.body) {
                    getPolicyResponse = JSON.parse(await response.text());
                }
     
                setPaymentPolicyLoading(!getPolicyResponse.ready);

                if (getPolicyResponse.ready) {
                    setPaymentState(x => ({
                        ...x,
                        policySuccess: getPolicyResponse.success,
                        policyReady: getPolicyResponse.ready,
                        policyNumber: getPolicyResponse.number,
                        policyHolderEmail: getPolicyResponse.holder?.email
                    }));
                    setPaymentCallbackState(pcs => ({ ...pcs, loading: false, ready: true }));
                } else {
                    // reset poller
                    setPaymentPolicyPoller(ctx => ({ ...ctx, count: ctx.count + 1, override: false }));
                }
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling for policy', { details: error });
                setPaymentPolicyLoading(false);
            });
    };

    const clearPaymentInitiationState = () => {
        setPaymentInitiationState({
            id: '',
            paymentAlreadyProcessing: false,
            loading: false,
            ready: false,
            success: false
        });
    }

    const clearPaymentState = () => {
        setPaymentPolicyLoading(false);
        setPaymentPolicyPoller({ id: '', count: 0, override: false });
        setPaymentState({
            id: '',
            loading: false,
            ready: false,
            success: false,
            paymentStatus: PaymentStatus.None,
            policyReady: false,
            policySuccess: false
        });
    }


    return [
        paymentInitiationState,
        paymentState,
        paymentCallbackState,
        setPaymentCallbackState,
        processPaymentCallback,
        initiateIssuedPolicyPayment,
        reInitiateCanceledPayment,
        clearPaymentInitiationState,
        clearPaymentState
    ];
}