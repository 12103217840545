import React, { RefObject, useEffect, useState } from "react";
import { isValid, parse, differenceInCalendarMonths, addDays } from 'date-fns';
import { InputLayerValue, WizardStepProps, tomorrowDate } from "../WizardManager.models";
import { CalendarInput } from "../../shared/app-calendar-input/AppCalendarInput";
import { FlowMessageReadEdit } from "../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "./WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../flow-message/FlowMessage';
import { useInit } from '../../../services/useInit';

export const POLICY_START_DATE_ID = 'PSD';

export const PolicyStartDateHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, readValue } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [stepValue, setStepValue] = useState(_wmService.readModelValue(node.id, tomorrowDate()));
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [assistanceRecords, setAssistanceRecords] = useState([] as FlowMessageAssistanceRecord[]);

    useInit(() => {
        setSubmitValidationRun(true);
        validate(stepValue);
    });

    const validate = (value: InputLayerValue) => {
        const results = [];
        const x = value.value;
        if (x.length === 0 || !/^(?:\d{4}-\d{2}-\d{2})$/.test(x)) {
            results.push({ key: 'Validation.InvalidDateFormat', type: FlowMessageAssistanceRecordType.Informational });
        } else {
            const parsedDate = parse(x, 'yyyy-MM-dd', new Date());
            if (!isValid(parsedDate)) {
                results.push({ key: 'Validation.InvalidDate', type: FlowMessageAssistanceRecordType.Error });
            } else {
                const currentDate = new Date();
                if (parsedDate < currentDate) {
                    results.push({ key: 'Validation.PastDate', type: FlowMessageAssistanceRecordType.Error });
                } else {
                    const monthDiff = differenceInCalendarMonths(parsedDate, currentDate);
                    if (monthDiff > 2) {
                        results.push({ key: 'Validation.TwoMonthsInFuture', type: FlowMessageAssistanceRecordType.Error });
                    }
                }
            }
        }
     
        setAssistanceRecords(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (submitValidationRun) {
            setDisableSubmit(validate(data).length !== 0);
        }      
    };

    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        setStepValue(value);

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }
        
        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            // of validation is ok, just bubble up
            _wmService.handleStepValueChange(value, true, node, false);
            // _wmService.initiatePolicyExistanceVerification();
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }
 
    const dProps = () => {
        const now = new Date();
        // If user initiates session later than 23:00 the allowed policy start will be day after
        let fromDate = addDays(now, now.getHours() >= 23 ? 2 : 1);
        let toDate = fromDate;
        toDate = addDays(toDate, 29);

        return {
            fromDate,
            toDate
        };
    }

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={POLICY_START_DATE_ID}
                            queryArea={
                                <AppQueryHeader>{t('Wizard.PSD.Edit.Query')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <CalendarInput
                                    modalPortal={_wmService.pageViewportRef.current as Element}
                                    onValueChange={handleInputChange}
                                    onSubmit={(x, y) => handleSubmit(x, y)}
                                    disableSubmit={!active() && disableSubmit}
                                    value={stepValue}
                                    maxLength={10}
                                    dayPickerProps={dProps()}
                                    scrollTarget={_wmService.flowBodyRef.current}
                                />
                            }
                            assistanceRecords={assistanceRecords}
                            withConfirm={true}
                        /> 
                    )
                    : (
                        <div data-step-id={POLICY_START_DATE_ID} className={`flow-message disabled`}>   
                            <div className={`flow-message-area`}>
                                <div className="flow-message-read">
                                    <div className="flow-message-read-icon">
                                        <AppIconDisplay name="clock" size={AppIconDisplaySize.S} />
                                    </div>
                                    <div className="flow-message-read-label">
                                        {t('Wizard.PSD.Read.Query')}
                                    </div>
                                    <div className="flow-message-read-value">
                                        {readValue()}
                                    </div>
                                    {
                                        editable()
                                        && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                    }
                                </div>        
                            </div>
                        </div>
                    )
            }
        </React.Fragment>
    )
}