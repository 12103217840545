import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter, Route, useLocation, Routes, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ApplicationOptions, CodeListName, CodeValue, SystemOptions } from './models/App.models';
import { useTranslation } from 'react-i18next';
import { ViewportSize, useViewportSize } from './services/useViewportSize';
import wretch from 'wretch'
import logger from './services/Util';
import PaymentCallback from './components/payment-callback/PaymentCallback';
import WizardManager from './components/flow-manager/WizardManager';
import GetDocuments from './components/get-documents/GetDocuments';
import './components/flow-manager/flow-message/FlowMessage.scss';
import { isMobile } from './services/isMobile';
import { C_SESSION_KEY, C_LANGUAGE } from './cookies';
import Home from './components/home/Home';
import { AppLoader } from './components/shared/app-loader/AppLoader';
import { CreateSessionRequest, GetCodeListsResponse } from './models/Comm.models';
import { PdfType } from './components/pdf-viewer/PdfViewer';
import { useCookieConsent, CookieGroup } from './services/useCookieConsent';
import { useInit } from './services/useInit';

const App: React.FC = () => {
    return (
        <ApplicationContextProvider>
            <main id="outlet" className={isMobile() ? 'mobile' : 'desktop'}>
                <InternalApp />
            </main>
        </ApplicationContextProvider>
    );
}

export default App;

/** Application wide HTTP client. Complete with base urls, session headers and other things. */
export let _http = wretch();

/** Application context type */
export type AppServiceSignature = {
    /** Get codelist values by name. Returns only codelists specified in CodeListName enum. */
    getCodeList: (name: CodeListName) => CodeValue[];
    application: ApplicationOptions;
    viewport: ViewportSize;
    sessionResolved: boolean;
    initialized: boolean;
    buildSession: (limitedAccess: boolean, code?: string) => Promise<void>;
    pdfViewer: { show: boolean, type: PdfType };
    showPdfViewer: (type: PdfType) => void;
    closePdfViewer: () => void;
    onCodeListUpdate: ApplicationLanguge;
}
/** Application context exposing functionality from Application service (ApplicationContextProvider). */
export const AppService = createContext<AppServiceSignature>({} as any);

/** Application language */
export enum ApplicationLanguge {
    Lithuanian = 'lt',
    English = 'en'
}

/** Application service. */
const ApplicationContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { i18n } = useTranslation();
    const viewport = useViewportSize();
    const [application, setApplication] = useState<ApplicationOptions>({} as any);
    const [initialized, setInitialized] = useState(false);
    const [sessionResolved, setSessionResolved] = useState(false);
    const codeListCache = useRef<{ [key in ApplicationLanguge]?: GetCodeListsResponse }>({});

    const buildSession = (limitedAccess: boolean, code?: string) => {
        const nowDate = new Date().getTime();
        let lsSession = JSON.parse(sessionStorage.getItem(C_SESSION_KEY) ?? '{}');
        if (lsSession.key && lsSession.start) {
            let startDate = new Date(lsSession.start).getTime();
            // 1200 = 20 hours. This is intentionally set shorter than actual session length (24h).
            if (((nowDate - startDate) / (1000 * 60)) > 1200) {
                lsSession = {};
            } else {
                logger.log(`DEBUGGER: Session storage contained valid session key #${lsSession.key}.`);
                _http = _http.headers({ 'X-Session': lsSession.key });
                setSessionResolved(true);
                return Promise.resolve();
            }
        }

        // limited access without a code shouldn't invoke session token retrieval
        if (limitedAccess && !code) {
            return Promise.resolve();
        }

        return _http
            .post({ code } as CreateSessionRequest, '/api/session')
            .json<{ sessionId: string }>()
            .then(sessionIdentifierResponse => {
                lsSession = { key: sessionIdentifierResponse.sessionId, start: nowDate };
                logger.log(`DEBUGGER: New session created ${lsSession.key}`);
                sessionStorage.setItem(C_SESSION_KEY, JSON.stringify(lsSession));
                // all calls via _http will include X-Session header
                _http = _http.headers({ 'X-Session': lsSession.key });
                setSessionResolved(true);
            });
    }

    // Initialize
    useInit(() => {
        _http
            .get('/options')
            .json<{ application: ApplicationOptions, system: SystemOptions }>()
            .then(optionsResponse => {
                // setup base url, this means that all requests will be pointed to API instead of webapplication
                logger.log('DEBUGGER: Application options', optionsResponse);
                setApplication(optionsResponse.application);
                return buildSession(optionsResponse.system.limitedAccess)
                    .catch(() => Promise.resolve());
            })
            .finally(() => {
                setTimeout(() => {
                    setInitialized(true);
                }, 750); 
            });
    });

    // Serves as a proxy for langauge change and subsequentially - codelist update based on language. 
    // I know.. naming is atrocious
    const [onCodeListUpdate, setOnCodeListUpdate] = useState(i18n.resolvedLanguage as ApplicationLanguge);

    // Caches codelists
    useEffect(() => {
        let lang = i18n.resolvedLanguage as ApplicationLanguge;
        if (!sessionResolved || !lang) {
            return;
        }

        if (codeListCache.current?.[lang]) {
            setOnCodeListUpdate(() => lang);
            return;
        }

        logger.log(`DEBUGGER: Caching codelists for #${i18n.resolvedLanguage}.`);
        _http
            .get(`/api/codeList/${i18n.resolvedLanguage}`)
            .json<GetCodeListsResponse>()
            .then(codeListsResponse => {
                codeListCache.current[lang] = codeListsResponse;
                setOnCodeListUpdate(() => lang);
                logger.log(`DEBUGGER: Finished caching codelists for #${i18n.resolvedLanguage}.`, codeListCache);
            });
    }, [sessionResolved, i18n.resolvedLanguage]);

    useEffect(() => {
        document.documentElement.style.setProperty('--height-app', `${viewport.height}px`);
    }, [viewport]);

    const getCodeList = (name: CodeListName) => {
        if (!name) {
            logger.error('DEBUGGER: You must provide a name for getCodeList(<here>).');
            return [];
        }

        let lang = i18n.resolvedLanguage as ApplicationLanguge;
        let langResult: GetCodeListsResponse | undefined = codeListCache.current[lang];
        if (!langResult) {
            logger.error(`DEBUGGER: CodeList #${name} can't be retrieved. Langauge cache #${lang} not available.`);
            return [];
        }

        let result = langResult[name];
        if (!result) {
            logger.error(`DEBUGGER: CodeList #${name} can't be retrieved. Langauge cache #${lang} doesn't have it.`);
            return [];
        }

        if (result.length === 0) {
            logger.warn(`DEBUGGER: CodeList #${name} has no values.`);
        }

        return result;
    };

    // Indicates the state of pdf viewer */
    const [pdfViewer, setPdfViewer] = useState({ show: false, type: PdfType.None });

    const showPdfViewer = (type: PdfType) => {
        setPdfViewer(x => ({ show: true, type }));
    }

    const closePdfViewer = () => {
        setPdfViewer(x => ({ ...x, show: false}));
    }

    const isPreferencesCookieConsented = useCookieConsent(CookieGroup.Preferences);

    if (isPreferencesCookieConsented) {
        const { resolvedLanguage } = i18n;

        if (resolvedLanguage) {
            localStorage.setItem(C_LANGUAGE, resolvedLanguage);
        }
    } else {
        localStorage.removeItem(C_LANGUAGE);
    }

    const providerValue = useMemo(() => ({
        getCodeList,
        application,
        viewport,
        sessionResolved,
        initialized,
        buildSession,
        pdfViewer,
        showPdfViewer,
        closePdfViewer,
        onCodeListUpdate
    }), [
        application,
        viewport,
        sessionResolved,
        initialized,
        showPdfViewer,
        onCodeListUpdate
    ]);
    return (
        <AppService.Provider value={providerValue}>
            {children}
        </AppService.Provider>
    );
};

export { ApplicationContextProvider };

/** When route is activated, this will check if Promo is entered. */
const PromoProtected: React.FC<{ children: ReactNode; }> = ({ children }) => {
    const { sessionResolved } = useContext(AppService);
    if (!sessionResolved) {
        return <Navigate to="/" replace />
    }
    return children;
}

/** Routing setup */
const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<Home />} index={true} />
        , <Route path="/insure" element={<PromoProtected><WizardManager /></PromoProtected>} />
        , <Route path="/payment-callback" element={<PaymentCallback />} />
        , <Route path="/mypolicy/:id" element={<GetDocuments />} />
    ]),
    { basename: document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined }
);

const InternalApp: React.FC = () => {
    const { initialized } = useContext(AppService);
    return (
        <React.Fragment>
            {
                initialized
                    ? <RouterProvider router={router} />
                    : (
                        <div style={{
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div className="svg-logo-green"
                                style={{
                                    height: '40px',
                                    width: '114px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100%'
                                }}
                            />
                            <AppLoader />
                        </div>
                    )
            }
        </React.Fragment>

    );
};
