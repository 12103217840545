import { useState, useRef, RefObject } from 'react';
import { useInit } from './useInit';

export function useElementWidth<T extends HTMLElement>(): [RefObject<T>, number, () => void] {
    const [width, setWidth] = useState(0);
    const elementRef = useRef<T>(null);

    const updateWidth = () => {
        const element = elementRef.current;
        if (!element) {
            return;
        } 

        setWidth(element.getBoundingClientRect().width);
    };

    useInit(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        } 

        const resizeObserver = new ResizeObserver(updateWidth);
        resizeObserver.observe(element);

        // Initial width
        updateWidth();

        return () => {
            resizeObserver.disconnect();
        };
    });

    return [elementRef, width, updateWidth];
}