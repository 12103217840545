import { CSSProperties } from 'react';
import './AppLoader.scss';

export enum AppLoaderSize {
    Default = 'default',
    Small = 'small'
}

/** SVG loader. Three dots flashing. */
export const AppLoader: React.FC<{ size?: AppLoaderSize, style?: CSSProperties | undefined }> = ({ size = AppLoaderSize.Default, style }) => {
    return (
        <div className={`app-loader app-loader--${size}`} style={style}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 66 14">
                <circle fill="#FF720B" stroke="none" cx="7" cy="7" r="7">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.1" />
                </circle>
                <circle fill="#FF720B" stroke="none" cx="33" cy="7" r="7">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.2" />
                </circle>
                <circle fill="#FF720B" stroke="none" cx="59" cy="7" r="7">
                    <animate
                        attributeName="opacity"
                        dur="1s"
                        values="0;1;0"
                        repeatCount="indefinite"
                        begin="0.3" />
                </circle>
            </svg>
        </div>
    );
}