import React, { RefObject, useState } from "react";
import { InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { AutocompleteInput, AutocompleteOption } from "../../../shared/app-autocomplete-input/AppAutocompleteInput";
import { FlowMessageReadEdit } from "../../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import { POLICY_HOLDER_ADDRESS_STREET } from "./PHA";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../../flow-message/FlowMessage';

export const RESIDENTIAL_AREA_ID = 'RA';

export const ResidentialAreaHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { t } = useTranslation();
    const { _wmService, editable, active, readValue } = useWizardStep(node, isLast);
   const [stepValue, setStepValue] = useState(_wmService.readModelValue(RESIDENTIAL_AREA_ID));
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (value: InputLayerValue) => {
        const results = [];
        const x = value.value;
        if (x.length === 0) {
            results.push('Validation.Mandatory');
        }

        setValidationResults(results);
        return results;
    }

    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        if (value.truthy()) {
            const areaIdentifier = value.valueAsInt();

            if (areaIdentifier && isNaN(areaIdentifier)) {
                setStepValue(() => new InputLayerValue());
            } else {
                setStepValue(() => value);
            }
        } else {
            setStepValue(() => new InputLayerValue());
        }

        
        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            // of validation is ok, just bubble up
            _wmService.handleStepValueChange(value, true, node, false);
        }
    }

    const isAddressFilled = () => _wmService.readModelValue(POLICY_HOLDER_ADDRESS_STREET).truthy();

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={RESIDENTIAL_AREA_ID}
                            queryArea={
                                <AppQueryHeader>{t('Wizard.RA.Edit.Query')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <AutocompleteInput
                                    placeholder={{ entry: 'Wizard.RA.Edit.Entry', modal: 'Wizard.RA.Edit.Modal', assistive: 'Wizard.RA.Edit.Assistive' }}
                                    onSubmit={(x, y) => handleSubmit(x, y)}
                                    disableSubmit={false}
                                    value={stepValue}
                                    modalPortal={_wmService.pageViewportRef.current as Element}
                                    configuration={{
                                        type: 'api',
                                        endpoint: () => '/api/address/residentialArea/search',
                                        buildRequest: (term) => ({ query: term }),
                                        threshold: 1,
                                        processResponse: (response: {
                                            results: {
                                                id: number,
                                                name: string,
                                                municipality?: string,
                                                elderate?: string,
                                                addresses?: boolean,
                                                streets?: boolean
                                            }[]
                                        }) => {
                                            return response.results.map(x => {
                                                let name = x.name;

                                                if (x.elderate) {
                                                    name += `, ${x.elderate}`;
                                                }
                                                if (x.municipality) {
                                                    name += `, ${x.municipality}`;
                                                }

                                                return ({ 
                                                    code: x.id.toString(), 
                                                    value: name, 
                                                    meta: { addresses: x.addresses, streets: x.streets} 
                                                } as AutocompleteOption);
                                            });
                                        }
                                    }}
                                    scrollTarget={_wmService.flowBodyRef.current}
                                />
                            }
                            assistanceRecords={validationResults.map(x => ({ key: x, type: FlowMessageAssistanceRecordType.Error } as FlowMessageAssistanceRecord))}
                            withConfirm={true}
                        /> 
                    )
                    : (
                         <div data-step-id={RESIDENTIAL_AREA_ID} className={`flow-message disabled`}>   
                            <div className={`flow-message-area`}>
                                {
                                    !isAddressFilled()
                                    && (
                                        <div className="flow-message-read">
                                            <div className="flow-message-read-icon">
                                                <AppIconDisplay name="location" size={AppIconDisplaySize.S} />
                                            </div>
                                            <div className="flow-message-read-label">
                                                {t('Wizard.RA.Read.Query')}
                                            </div>
                                            <div className="flow-message-read-value">
                                                {readValue()}
                                            </div>
                                            {
                                                editable()
                                                && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                            }
                                        </div>
                                    )
                                } 
                            </div>
                        </div>
                    )
            }
        </React.Fragment>    
    )
}