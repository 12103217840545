import { useEffect, useState } from "react";
import { HandledInterruptions, InputLayerValue, QuotationState } from "../WizardManager.models";
import logger from "../../../services/Util";
import {
    ApiVehicleInformation, CreateQuotationRequest,
    CreateQuotationResponse, GetQuotationResponse, InsurerError
} from "../../../models/Comm.models";
import { _http } from "../../../App";
import { PERSONAL_IDENTIFICATION_CODE_ID } from "../wizard-steps/PIC";
import { VEHICLE_REGISTRATION_NUMBER_ID } from "../wizard-steps/VRN";
import { POLICY_START_DATE_ID } from "../wizard-steps/PSD";
import { mergeAndUpdateQuotationCalculations } from "./mergeAndUpdateQuotationCalculations";

type PollContext = {
    id: string,
    count: number,
    timestamp: any
}

type Props = {
    setVehicleState: React.Dispatch<React.SetStateAction<ApiVehicleInformation | undefined>>;
    readModelValue: (key: string, fallback?: string) => InputLayerValue;
}
export function usePolicyExistanceVerification(props: Props): [QuotationState, () => void, () => void] {
    let {
        setVehicleState,
        readModelValue
    } = props;

    // Policy existance verification context
    const [policyExistanceVerificationPoller, setPolicyExistanceVerificationPoller] = useState<PollContext>({ id: '', count: 0, timestamp: 0 } as PollContext);

    const getInitialPolicyExistanceVerificationState = () => ({
        id: '',
        result: [],

        loading: false,
        ready: false,
        success: false,
        outdated: false,
        interruptions: [],
        afterEffects: false
    });

    // Policy existance verification state  */
    const [policyExistanceVerificationState, setPolicyExistanceVerificationState] = useState<QuotationState>(getInitialPolicyExistanceVerificationState());

    // Policy existance verification polling scheduling handler */
    useEffect(() => {
        if (!policyExistanceVerificationState.loading) {
            return;
        }
        const timeoutId = setTimeout(() => pollPolicyExistanceVerification(), 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "vehiclePoller" changes
    }, [policyExistanceVerificationPoller]);


    /** Initiates policy existance verification. Also retrieves info on vehicle.*/
    const initiatePolicyExistanceVerification = () => {
        logger.log('DEBUGGER: Initiation policy existance verification.');
        clearPolicyExistanceVerificationState();

        const request: CreateQuotationRequest = {
            holder: {
                code: readModelValue(PERSONAL_IDENTIFICATION_CODE_ID).value
            },
            vehicle: {
                number: readModelValue(VEHICLE_REGISTRATION_NUMBER_ID).value
            },
            start: readModelValue(POLICY_START_DATE_ID).value,
            residentialAreaId: 17507, // kaunas
            duration: 12
        };

        _http
            .headers({ 'X-Mode': 1 as any })
            .post(request, '/api/quote')
            .res()
            .then(async response => {
                let cqr = {} as CreateQuotationResponse;
                if (response.body) {
                    cqr = JSON.parse(await response.text());
                }

                processResponse(cqr, response.headers.get('X-Timestamp'), cqr.id);    
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while initiating policy existance check', { details: error });
                setPolicyExistanceVerificationState(qs => ({
                    ...qs,
                    loading: false,
                    ready: true,
                    success: false,
                    interruptions: []
                }));
            });
    }

    /** Polls vehicle information result */
    const pollPolicyExistanceVerification = () => {
        if (!policyExistanceVerificationPoller.id
            || policyExistanceVerificationPoller.id !== policyExistanceVerificationState.id
        ) {
            return;
        }
        _http
            .headers({
                'X-Timestamp': policyExistanceVerificationPoller.timestamp,
                'X-Mode': 1 as any
            })
            .get(`/api/quote/${policyExistanceVerificationPoller.id}`)
            .res()
            .then(async response => {
                let gqr = {} as GetQuotationResponse
                if (response.body) {
                    gqr = JSON.parse(await response.text());
                }

                processResponse(gqr, response.headers.get('X-Timestamp')); 
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling for policy existance', { details: error });
                setPolicyExistanceVerificationState(qs => ({
                    ...qs,
                    loading: false,
                    ready: true,
                    success: false,
                    interruptions: []
                }));
            });
    };

    const processResponse = (response: (CreateQuotationResponse | GetQuotationResponse), timestamp: any, id?: string) => {
        setPolicyExistanceVerificationState(qs => {
            let ctxId = id ?? qs.id;

            const calculations = mergeAndUpdateQuotationCalculations(
                (qs.result ?? []),
                response?.calculations ?? [],
                response.ready
            );

            const interruptions = calculations.reduce((acc: InsurerError[], cur) => {
                if (
                    cur.error
                    && HandledInterruptions.includes(cur.error)
                    && !acc.includes(cur.error)
                    && (
                        cur.error !== InsurerError.VehicleInformationUnavailable
                        || calculations.every(c =>
                            c.error === InsurerError.VehicleInformationUnavailable
                            || c.error === InsurerError.FunctionalityNotImplemented
                        )
                        // in case of VehicleInformationUnavailable we should verify that ALL insurers return this error
                        // see MANRAMU-436
                    )
                ) {
                    acc.push(cur.error);
                }
                return acc;
            }, []);

            if (response.ready) {
                if (
                    interruptions.length === 0
                    && response.vehicle
                    && calculations.some(x => x.premium) // allow setting vehicle only if some insurer returned price
                    // && response.vehicle.owner  // maybe additional verification on ".vehicle.owner" ?
                ) {
                    setVehicleState(vs => ({
                        ...(vs ?? {} as ApiVehicleInformation),
                        ...(response.vehicle ?? {} as ApiVehicleInformation)
                    }));
                }
            } else {
                // init polling only if main request doesn't finish in time.
                 setPolicyExistanceVerificationPoller(() => ({
                    id: ctxId,
                    count: 0,
                    timestamp
                }));
            }

            return ({
                ...qs,
                id: ctxId,
                result: calculations,
                ready: response.ready,
                loading: !response.ready,
                success: response.ready,
                interruptions
            });
        });
    }

    const clearPolicyExistanceVerificationState = () => {
        setPolicyExistanceVerificationState(() => getInitialPolicyExistanceVerificationState());
        setPolicyExistanceVerificationPoller({ id: '', count: 0, timestamp: 0 });
    }

    return [
        policyExistanceVerificationState,
        initiatePolicyExistanceVerification,
        clearPolicyExistanceVerificationState
    ];
}