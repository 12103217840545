import { useTranslation } from 'react-i18next';
import { Dialog, DialogTrigger, Modal } from 'react-aria-components';
import AppButton, { AppButtonType } from '../../shared/app-button/AppButton';
import { WizardManagerService } from '../WizardManager.service';
import { useContext } from 'react';

type DiscardInterceptorProps = {
    open: boolean;
    onCancelDiscard?: () => void;
    onConfirmDiscard?: () => void;
}

export const DiscardInterceptor: React.FC<DiscardInterceptorProps> = ({ open, onCancelDiscard, onConfirmDiscard }) => {
    const { t } = useTranslation();
    const _wmService = useContext(WizardManagerService);

    return (
        <DialogTrigger isOpen={open}>
            <Modal UNSTABLE_portalContainer={_wmService.pageViewportRef.current as Element} className="react-aria-Modal cover-w">
                <Dialog aria-label={`${t('Wizard.ClearVerification.Title')} ${t('Wizard.ClearVerification.Description')}`} >
                    {
                        ({ close }) => (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ fontSize: '20px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#008e9c', lineHeight: '1' }}>{t('Wizard.ClearVerification.Title')}</div>
                                <div>{t('Wizard.ClearVerification.Description')}</div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                    <AppButton
                                        className="cancel-button"
                                        type={AppButtonType.Tertiary}
                                        title={'Cancel'}
                                        onPress={() => { onCancelDiscard?.(); close(); }}
                                    >
                                        {t('Wizard.ClearVerification.Cancel')}
                                    </AppButton>
                                    <AppButton
                                        className="confirm-button"
                                        title={'Confirm'}
                                        onPress={() => { onConfirmDiscard?.(); close(); }}
                                    >
                                        {t('Wizard.ClearVerification.Confirm')}
                                    </AppButton>
                                </div>
                            </div>
                        )
                    }
                </Dialog>
            </Modal>
        </DialogTrigger>
    );
}
