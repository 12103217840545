import './PdfViewer.scss';
import React, { useState, useContext, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";
import { useTranslation } from "react-i18next";
import { AppService } from "../../App";
import { useElementWidth } from '../../services/useElementWidth';
import { AppLoader } from '../shared/app-loader/AppLoader';
import AppButton, { AppButtonType } from "../shared/app-button/AppButton";
import AppIconDisplay from "../shared/app-icon-display/AppIconDisplay";
import wizardTermsAndConditionsPdf from './ext_Interneto_svetaines_taisykles_ir_Privatumo_politika_20240701.pdf';
import termsAndConditionsPdf from './ext_Interneto_svetaines_taisykles_20240701.pdf';
import customerInformationPdf from './ext_Informacija klientui_20240604.pdf';
import cookiePolicyPdf from './ext_Slapuku_informacija_20240808.pdf';
import privacyPolicyPdf from './ext_Privatumo_politika_20240604.pdf';

export enum PdfType {
    None = 'none',
    WizardTermsAndConditions = 'wizardTermsAndConditions',
    TermsAndConditions = 'termsAndConditions',
    CustomerInformation = 'customerInformation',
    CookiePolicy = 'cookiePolicy',
    PrivacyPolicy = 'privacyPolicy'
}

const PdfViewer: React.FC = () => {
    const { pdfViewer, closePdfViewer } = useContext(AppService);
    const { t } = useTranslation();

    // indicates what pages of pdf are currently shown.
    const [pdfPages, setPdfPages] = useState(0);
    const [loadedPdfPages, setLoadedPdfPages] = useState([] as number[]);

    const [pdfViewport, pdfViewportWidth, updateWidth] = useElementWidth<HTMLDivElement>();

    const onPdfLoadSuccess = (document: PDFDocumentProxy) => {
        setPdfPages(document.numPages);
        requestAnimationFrame(() => {
            updateWidth();
        });
    }

    const onPdfPageLoadSuccess = (pageIndex: number) => {
        setLoadedPdfPages(x => {
            const result = [...x];
            if (x.indexOf(pageIndex) === -1) {
                result.push(pageIndex)
            }
            return result;
        });
    }

    useEffect(() => {
        setPdfPages(() => 0);
        setLoadedPdfPages(() => [])
    }, [pdfViewer]);

    const context = () => {
        const result = {
            title: '',
            file: undefined as File | undefined
        };
        switch (pdfViewer.type) {
            case PdfType.TermsAndConditions:
                result.title = 'TermsAndConditions.Title';
                result.file = termsAndConditionsPdf;
                break;
            case PdfType.WizardTermsAndConditions:
                result.title = 'WizardTermsAndConditions.Title';
                result.file = wizardTermsAndConditionsPdf;
                break;
            case PdfType.CustomerInformation:
                result.title = 'CustomerInformation.Title';
                result.file = customerInformationPdf;
                break;
            case PdfType.PrivacyPolicy:
                result.title = 'PrivacyPolicy.Title';
                result.file = privacyPolicyPdf;
                break;
            case PdfType.CookiePolicy:
                result.title = 'CookiePolicy.Title';
                result.file = cookiePolicyPdf;
                break;
        }
        return result;
    }

    return (
        <React.Fragment>
            {
                pdfViewer.show
                && (
                    <div ref={pdfViewport} className="pdfviewer-viewport">
                        <div className="pdfviewer-header">
                            <div className="pdfviewer-title">{t(context().title)}</div>

                            <AppButton
                                className="close-button"
                                title={t('TermsAndCondition.Close')}
                                onPress={closePdfViewer}
                                type={AppButtonType.Ghost}
                                iconOnly={true}
                            >
                                <AppIconDisplay name="close" />
                            </AppButton>
                        </div>
                        <div className="pdfviewer-body">
                            {
                                (loadedPdfPages.length !== pdfPages || pdfPages === 0)
                                && (
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '1rem 0 1rem 0' }}>
                                        <AppLoader />
                                    </div>
                                )
                            }

                            <Document
                                className="pdfviewer-content"
                                file={context().file}
                                loading={''}
                                onLoadSuccess={onPdfLoadSuccess}
                            >
                                {Array.from(
                                    new Array(pdfPages),
                                    (el, index) => (
                                        <Page
                                            loading={''}
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            width={pdfViewportWidth - 20}
                                            scale={1}
                                            devicePixelRatio={2}
                                            onLoadSuccess={() => onPdfPageLoadSuccess(index)}
                                        />
                                    ),
                                )}
                            </Document>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default PdfViewer;