import React, { RefObject, useState } from "react";
import { InputLayerValue, PersonalCode, WizardStepProps } from "../WizardManager.models";
import { FlowMessageReadEdit } from "../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "./WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../flow-message/FlowMessage';
import { TextInput } from '../../shared/app-text-input/AppTextInput';

export const PERSONAL_IDENTIFICATION_CODE_ID = 'PIC';

export const PersonalIdentificationCodeHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, readValue } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [stepValue, setStepValue] = useState(_wmService.readModelValue(node.id));
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as FlowMessageAssistanceRecord[]);

    const validate = (value: InputLayerValue) => {
        const results: FlowMessageAssistanceRecord[] = [];
        const x: string = value.value;
        if (x.length === 0) {
            results.push({ key: 'Validation.Mandatory', type: FlowMessageAssistanceRecordType.Error });
        } else {
            const pc = new PersonalCode(x);
            if (!pc.hasValidLength()) {
                results.push({ key: 'Validation.ExactlyNCharacters', params: { n: 11 }, type: FlowMessageAssistanceRecordType.Error });
            } else if (!pc.hasAllDigits()) {
                results.push({ key: 'Validation.OnlyDigits', type: FlowMessageAssistanceRecordType.Error });
            } else if (!pc.hasValidBirthDate()) {
                results.push({ key: 'Validation.InvalidPersonalCodeBirthDate', type: FlowMessageAssistanceRecordType.Error });
            } else if (!pc.hasValidChecksum()) {
                results.push({ key: 'Validation.InvalidPersonalChecksum', type: FlowMessageAssistanceRecordType.Error });
            }
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (!submitValidationRun) {
            return;        
        }      
        setDisableSubmit(validate(data).length !== 0);
    };

    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        setStepValue(value);

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }

        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            // of validation is ok, just bubble up
            _wmService.handleStepValueChange(value, true, node, false);
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    const readValueFormatter = (x: string) => {
        // mask settings
        const start = 2;
        const end = 9;
        const maskChar = '*';

        // apply mask
        const before = x.slice(0, start);
        const after = x.slice(end + 1);
        const masked = maskChar.repeat(end - start + 1);

        // join
        return `${before}${masked}${after}`;
    }

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={PERSONAL_IDENTIFICATION_CODE_ID}
                            queryArea={
                                <AppQueryHeader>{t('Wizard.PIC.Edit.Query')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <TextInput
                                    onValueChange={handleInputChange}
                                    placeholder={'Wizard.PIC.Edit.Placeholder'}
                                    onSubmit={(x, y) => handleSubmit(x, y) }
                                    disableSubmit={!active() && disableSubmit}
                                    value={stepValue}
                                    maxLength={11}
                                    scrollTarget={_wmService.flowBodyRef.current}
                                />
                            }
                            assistanceRecords={validationResults}
                            withConfirm={true}
                        /> 
                    )
                    : (
                         <div data-step-id={PERSONAL_IDENTIFICATION_CODE_ID} className={`flow-message disabled`}>   
                            <div className={`flow-message-area`}>
                                <div className="flow-message-read">
                                    <div className="flow-message-read-icon">
                                        <AppIconDisplay name="personalcode" size={AppIconDisplaySize.S} />
                                    </div>
                                    <div className="flow-message-read-label">
                                        {t('Wizard.PIC.Read.Query')}
                                    </div>
                                    <div className="flow-message-read-value">
                                        {readValue(readValueFormatter)}
                                    </div>
                                    {
                                        editable()
                                        && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                    }
                                </div> 
                            </div>
                        </div>
                    )
            }
        </React.Fragment>    
    )
}