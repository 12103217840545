import React from "react";
import { InputLayerValue, WizardStepProps } from "../WizardManager.models";
import AppButton, { AppButtonType } from "../../shared/app-button/AppButton";
import AppInlineLink from "../../shared/app-inline-link/AppInlineLink";
import { Trans, useTranslation } from "react-i18next";
import useWizardStep from "./WizardStep";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import { C_TERMS_KEY } from "../../../cookies";
import { useNavigate } from "react-router-dom";
import { PdfType } from '../../pdf-viewer/PdfViewer';
import FlowMessage from '../flow-message/FlowMessage';

export const TERMS_ID = 'TERMS';

export const TermsHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, active, _appService } = useWizardStep(node, isLast);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const agreeWithTerms = () => {
        const yesValue = new InputLayerValue('YES', 'Wizard.TERMS.Accept');
        sessionStorage.setItem(C_TERMS_KEY, yesValue.value);
        _wmService.handleStepValueChange(yesValue, false, node, false);
    }

    const disagreeWithTerms = (e?: any) => {
        if (e && e.key !== 'Enter') {
            return;
        }

        navigate('/');
    }

    return (
        <React.Fragment>
            {
                active()
                    && (
                        <FlowMessage
                            stepId={TERMS_ID}
                            queryArea={
                                <AppQueryHeader>
                                    <div>
                                        <Trans
                                            i18nKey="Wizard.TERMS.Edit.Query"
                                            values={{ pdfTitle: t('Wizard.TERMS.Edit.PdfTitle') }}
                                            components={{
                                                emp: <AppInlineLink onClick={() => _appService.showPdfViewer(PdfType.WizardTermsAndConditions)}></AppInlineLink> 
                                            }}
                                        />
                                    </div>
                                </AppQueryHeader>
                            }
                            inputArea={() => 
                                <div className="flow-message-suggestions">
                                    <AppButton
                                        type={AppButtonType.Tertiary}
                                        onPress={() => disagreeWithTerms()}
                                    >
                                        {t('Wizard.TERMS.Deny')}
                                    </AppButton>

                                    <AppButton
                                        onPress={() => agreeWithTerms()}
                                    >
                                        {t('Wizard.TERMS.Accept') }
                                    </AppButton>
                                </div>
                            }
                        /> 
                    )
            }
        </React.Fragment>
    )
}

export const TermsSkipHandler = () => {
    /** Step should not be displayed if user has already accepted the terms once. */
    const lsCache = sessionStorage.getItem(C_TERMS_KEY);
    if (lsCache === 'YES') {
        return true;
    }
    return false;
}