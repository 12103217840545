import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react';
import { useDebouncedState } from '../../services/Util';

export type InputControlContextSignature = {
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    externalScrollPrevention: boolean;
    setExternalScrollPrevention: Dispatch<SetStateAction<boolean>>;
};

export const InputControlContext = createContext<InputControlContextSignature>({} as any);

export const InputControlContextProvider: React.FC<{ debounce: boolean, children: ReactNode; }> = ({ debounce, children }) => {
    const [isFocused, setIsFocused] = useDebouncedState(false, debounce ? 50 : 0);
    const [externalScrollPrevention, setExternalScrollPrevention] = useState(false);

    /** Memo insures that this will force a refresh of children once dependency changes */
    const providerValue = useMemo<InputControlContextSignature>(
        () => ({
            isFocused,
            setIsFocused,
            externalScrollPrevention,
            setExternalScrollPrevention
        } as InputControlContextSignature),
        [
            isFocused,
            externalScrollPrevention,
            setIsFocused
        ]
    );

    return (
        <InputControlContext.Provider value={providerValue}>
            {children}
        </InputControlContext.Provider>
    );
};
