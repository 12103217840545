import './RISKS.scss'
import React, { useState } from "react";
import { InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { FlowMessageReadEdit } from "../../WizardManager";
import AppToggleBox from "../../../shared/app-togglebox/AppTogglebox";
import AppButton from "../../../shared/app-button/AppButton";
import { useTranslation } from "react-i18next";
import { getEnumName } from "../../../../services/Util";
import useWizardStep from "../WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import { VehicleUseRisk } from '../../../../models/Comm.models';
import FlowMessage from '../../flow-message/FlowMessage';

export const RISKS_ID = 'RISKS';

export const RisksHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active } = useWizardStep(node, isLast);
    const { t } = useTranslation();

    /** Creates a new risk state. Ensures that only one key can ever be truthy */
    const newRiskState = (risk: VehicleUseRisk) => {
        let blankRiskState = {
            [VehicleUseRisk.PersonalUse]: false,
            [VehicleUseRisk.PassengerOrFreightTransportation]: false,
            [VehicleUseRisk.Rental]: false,
            [VehicleUseRisk.DrivingLessons]: false,
            [VehicleUseRisk.Other]: false,
        };

        blankRiskState[risk] = true;

        return blankRiskState;
    }

    const [riskState, setRiskState] = useState<{ [key in VehicleUseRisk]: boolean }>(newRiskState(VehicleUseRisk.PersonalUse));
    const [failedSubmit, setFailedSubmit] = useState(false);

    const handleSubmit = () => {
        let activeRisk: InputLayerValue;

        for (let rs of Object.keys(riskState)) {
            const rsKey: VehicleUseRisk = parseInt(rs);
            const rsValue = riskState[rsKey]; 
            if (rsValue) {
                if (rsKey === VehicleUseRisk.Other) {
                    setFailedSubmit(true);
                } else {
                    activeRisk = new InputLayerValue(rsKey.toString(), `VehicleUseRisk.${getEnumName(rsKey, VehicleUseRisk)}`);
                    _wmService.handleStepValueChange(activeRisk, true, node, false);
                }
                break;
            }
        }
    }

    const onValueChange = (risk: VehicleUseRisk, value: boolean) => {
        setFailedSubmit(false);

        let applicableRisk = risk;
        // if any of the risks is turned off, turn personal use back on as its the default one
        if (!value) {
            applicableRisk = VehicleUseRisk.PersonalUse;
        }

        setRiskState(newRiskState(applicableRisk));
    };

    const readValue = () => {
        let modelValue = _wmService.readModelValue(node.id);
        if (modelValue.truthy()) {
            return t(modelValue.text);
        }

        return '...';
    }

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={RISKS_ID}
                            queryArea={
                                <AppQueryHeader>{t('Wizard.RISKS.Edit.Query')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <React.Fragment>
                                    <div className="flow-message-risks">
                                        {
                                            Object.keys(riskState).map(rs => {
                                                const rsKey: VehicleUseRisk = parseInt(rs);
                                                const rsValue = riskState[rsKey];
                                                const rsDisabled = rsKey === VehicleUseRisk.PersonalUse && rsValue;
                                                return (
                                                    <div className={`fm-risk${rsValue ? ' active' : ''}`} key={`${rsKey}`}>
                                                        {t(`VehicleUseRisk.${getEnumName(rsKey, VehicleUseRisk)}`)}
                                                        <AppToggleBox
                                                            disabled={rsDisabled}
                                                            toggled={rsValue}
                                                            onToggleChange={(x: boolean) => onValueChange(rsKey, x)}
                                                        />
                                                    </div>   
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flow-message-risk-confirmation">
                                        {
                                            failedSubmit
                                                ? (
                                                    <div className="fm-rc-fail">
                                                        {t('Wizard.RISKS.Edit.OtherWarning')}
                                                    </div>
                                                )
                                                : (
                                                    <AppButton onPress = { () => handleSubmit() }>
                                                        {t('Input.Confirm')}
                                                    </AppButton>
                                                )
                                        } 
                                    </div>
                                </React.Fragment>  
                            }
                        /> 
                    )
                    : (
                        <div data-step-id={RISKS_ID} className={`flow-message disabled`}>   
                            <div className={`flow-message-area`}>
                                <div className="flow-message-read">
                                    <div className="flow-message-read-icon">
                                        <AppIconDisplay name="risks" size={AppIconDisplaySize.S} />
                                    </div>
                                    <div className="flow-message-read-label">
                                        {t('Wizard.RISKS.Read.Query')}
                                    </div>
                                    <div className="flow-message-read-value">
                                        {readValue()}
                                    </div>
                                    {
                                        editable()
                                        && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                    }
                                </div>       
                            </div>
                        </div>
                    )
            }
        </React.Fragment>
    )
}