import './WizardManager.scss';
import React, { useContext, useEffect, useState } from 'react';
import { WizardManagerServiceProvider, WizardManagerService } from "./WizardManager.service";
import { useLocation} from 'react-router-dom';
import AppButton, { AppButtonSize, AppButtonType } from '../shared/app-button/AppButton';
import AppIconDisplay from '../shared/app-icon-display/AppIconDisplay';
import { FlowInterruptionMessage } from './Interuption';
import { PressEvent } from 'react-aria-components';
import AppRoutePage from '../shared/app-route-page/AppRoutePage';
import FlowFinish, { FINISH_ID } from './flow-finish/FlowFinish';
import { AppService } from '../../App';
import { DiscardInterceptorProvider, useDiscardInterceptor } from './discard-interceptor/DiscardInterceptor.service';
import { useInit } from '../../services/useInit';

const WizardManager: React.FC = () => {
    return (
        <WizardManagerServiceProvider>
            <DiscardInterceptorProvider>
                <InternalWizardManager />
            </DiscardInterceptorProvider>
        </WizardManagerServiceProvider>
    );
}

export default WizardManager;

const InternalWizardManager: React.FC = () => {
    const _wmService = useContext(WizardManagerService);
    const _app = useContext(AppService);
    const location = useLocation();

    useInit(() => {
        _wmService.clear(location.state?.paymentCallbackContext);
    });

    const [shouldIntercept, setShouldIntercept] = useState(false);

    const distinctUpdateIntercept = (val: boolean) => {
        if (shouldIntercept != val) {
            setShouldIntercept(val);
        }
    };

    useEffect(
        () => {
            if (
                _wmService.activeStep === FINISH_ID
                || _wmService.paymentInitiationState.loading
            ) {
                // MANRAMU-475 Pressing Logo not works (after successful policy registration)
                distinctUpdateIntercept(false);
            } else if (Object.keys(_wmService.model.current ?? {}).length > 0) {
                distinctUpdateIntercept(true);
            }

        },
        [_wmService.model.current, _wmService.activeStep, _wmService.paymentInitiationState]
    );

    useDiscardInterceptor({
        shouldIntercept: shouldIntercept, 
        onConfirmDiscard: () => _wmService.clear()
    });

    // RENDER
    return (
        <AppRoutePage
            id="page-wizard-manager"
            pageClassName={_wmService.messages.length <= 1 && _wmService.activeStep !== FINISH_ID ? 'onboard' : ''} // onboard class enables background image.
            pageViewportRef={_wmService.pageViewportRef}
            pageBodyRef={_wmService.flowBodyRef}
            sideArea={
                <div className="car-insurance-image">
                    <div className="car-insurance-image-spacer"></div>
                    <div className="car-insurance-image-self"></div>
                </div>
            }
            actions={[{
                icon: 'code',
                title: 'Debug',
                callback: () => window.open(`/api/internal/trace.html?q=${_wmService.quotationState.id}`, '_blank'),
                disabled: !_wmService.quotationState.id || _app.application.environmentName === 'Production' // debug action should only be enabled when quotation id is present and env is not prod
            }]}
        >
            {
                _wmService.activeStep !== FINISH_ID
                && _wmService.messages.length > 1
                && (
                    // progress tracker
                    <div className="flow-progress-bar-area" >
                        <div className="flow-progress-bar-track">
                            <div className="flow-progress-bar" style={{ width: `${_wmService.messages.length / 15 * 100}%` }}></div>
                        </div>
                    </div>
                )
            }
            <div className={`flow-body`}>
                {
                    _wmService.activeStep === FINISH_ID
                        ? <FlowFinish />
                        : (
                            <React.Fragment>
                                {
                                    _wmService.messages.map((message, index, src) => {
                                        const isLast = index + 1 === src.length;
                                        return (
                                            <React.Fragment key={message.id}>
                                                {
                                                    (!message.hideOnCompletion || !message.hiddenAfterCompletion)
                                                    && (!isLast || !_wmService.interruptionState.enabled)
                                                    && (
                                                        <message.StepHandler node={message} isLast={isLast} />
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                }
                {
                    _wmService.interruptionState.enabled
                    && (
                        <FlowInterruptionMessage type={_wmService.interruptionState.type} />   
                    )
                }
            </div>
        </AppRoutePage>
    );
}

export const FlowMessageReadEdit: React.FC<{ onPress?: (e: PressEvent) => void }> = ({ onPress }) => (
    <div className="flow-message-read-edit">
        <AppButton type={AppButtonType.Ghost} size={AppButtonSize.Small} iconOnly={true} onPress={onPress}>
            <AppIconDisplay name="edit" />
        </AppButton>
    </div>
)