import React, {
    ReactNode, createContext, useCallback,
    useContext, useEffect, useMemo, useState
} from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { DiscardInterceptor } from './DiscardInterceptor';
import { useTranslation } from 'react-i18next';

type CallbackOptions = {
    onConfirmDiscard?: () => void;
    onCancelDiscard?: () => void;
}

type DiscardInterceptorHookOptions = CallbackOptions & {
    shouldIntercept: boolean;
}

type DiscardInterceptorServiceSignature = {
    show: (callback: CallbackOptions) => void;
}

const DiscardInterceptorService = createContext<DiscardInterceptorServiceSignature>({} as any);

const useConfirm = () => {
    const context = useContext(DiscardInterceptorService);
    if (!context) {
        throw new Error('useConfirm must be used within a ConfirmProvider');
    }

    return context;
};

export const DiscardInterceptorProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [callbackMeta, setCallbackMeta] = useState<CallbackOptions>({} as any);

    const show = useCallback(
        (callback: CallbackOptions) => {
            setCallbackMeta(callback);
            setOpen(true);
        },
        [setOpen]
    );

    const onConfirmDiscard = () => {
        callbackMeta?.onConfirmDiscard?.();
        setOpen(false);
    };

    const onCancelDiscard = () => {
        callbackMeta?.onCancelDiscard?.();
        setOpen(false);
    };

    /** Exposed methods and refresh dependencies */
    const providerValue = useMemo(
        () => ({
            show
        }),
        [show]
    );

    return ( 
        <DiscardInterceptorService.Provider value={providerValue}>
            {children}
            <DiscardInterceptor
                onCancelDiscard={onCancelDiscard}
                onConfirmDiscard={onConfirmDiscard}
                open={open}
            />
        </DiscardInterceptorService.Provider>
    );
};

export const useDiscardInterceptor = ({ shouldIntercept, onConfirmDiscard, onCancelDiscard }: DiscardInterceptorHookOptions) => {
    const { show } = useConfirm();
    const blocker = useBlocker(shouldIntercept);

    const confirm = useCallback(() => {
        if (!shouldIntercept) {
            return Promise.resolve(true);
        } 

        return new Promise<boolean>((resolve) => {
            show({
                onConfirmDiscard: () => {
                    resolve(true);
                    onConfirmDiscard?.();
                },
                onCancelDiscard: () => {
                    resolve(false);
                    onCancelDiscard?.();
                },
            });
        });
    }, [
        show,
        onCancelDiscard,
        onConfirmDiscard,
        shouldIntercept,
    ]);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            confirm().then(result => {
                if (result) {
                    blocker.proceed();
                } else {
                    blocker.reset();
                } 
            });
        }
    }, [blocker, confirm]);

    useEffect(() => {
        if (shouldIntercept) {
            window.onbeforeunload = (e: BeforeUnloadEvent) => {
                e.preventDefault();
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [shouldIntercept]);

    return { confirm };
};