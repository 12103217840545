import './PaymentCallback.scss';
import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { PaymentCallbackContext, PaymentStatus } from '../../models/Comm.models';

const PaymentCallback: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        const data = searchParams.get('data');
        
        let context: PaymentCallbackContext | null = null;
    
        if (token) {
            context = {
                token: token,
                isSuccessToken: true
            };
        } else if (data) {
            const pending = searchParams.get('pending');
            const canceled = searchParams.get('canceled');
    
            context = {
                token: data,
                isSuccessToken: false,
                paymentStatus: (pending && PaymentStatus.Pending) || (canceled && PaymentStatus.Canceled) || null
            };
        }
    
        if (context) {
            navigate('/insure', { 
                state: { 
                    paymentCallbackContext: context
                }, 
                replace: true 
            });
        }
    }, [searchParams]);

    return (
        <div className="payment-callback-viewport"></div>
    );
}

export default PaymentCallback;