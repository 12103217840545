import './PHA.scss';
import React, { useEffect, useState } from "react";
import { ComplexInputLayerValue, InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { FlowMessageReadEdit } from "../../WizardManager";
import AppButton from "../../../shared/app-button/AppButton";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import { AutocompleteInput, AutocompleteOption } from "../../../shared/app-autocomplete-input/AppAutocompleteInput";
import { ApplicationLanguge, _http } from "../../../../App";
import logger from "../../../../services/Util";
import { RESIDENTIAL_AREA_ID } from "./RA";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import { AppLoader } from "../../../shared/app-loader/AppLoader";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../../flow-message/FlowMessage';

export const POLICY_HOLDER_ADDRESS_ID = 'POLICYHOLDERADDRESS';
export const POLICY_HOLDER_ADDRESS_STREET = `${POLICY_HOLDER_ADDRESS_ID}.STREET`;
export const POLICY_HOLDER_ADDRESS_HOUSE = `${POLICY_HOLDER_ADDRESS_ID}.HOUSE`;
export const POLICY_HOLDER_ADDRESS_FLAT = `${POLICY_HOLDER_ADDRESS_ID}.FLAT`;

export const PolicyHolderAddressHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, hidden } = useWizardStep(node, isLast);
    const { t, i18n } = useTranslation();

    const [village, setVillage] = useState(_wmService.readModelValue(RESIDENTIAL_AREA_ID));
    const [street, setStreet] = useState(_wmService.readModelValue(POLICY_HOLDER_ADDRESS_STREET));
    const [house, setHouse] = useState(_wmService.readModelValue(POLICY_HOLDER_ADDRESS_HOUSE));
    const [flat, setFlat] = useState(_wmService.readModelValue(POLICY_HOLDER_ADDRESS_FLAT));

    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (data: ComplexInputLayerValue, effect = false) => {
        const results = [];

        const areAllFieldsEmpty = Object.keys(data).every(k => data[k].value.length === 0);

        if (areAllFieldsEmpty) {
            results.push('Validation.Mandatory');
        } else if (!effect) {
            if (!data[POLICY_HOLDER_ADDRESS_STREET].truthy()) {
                if (!houseOptions.enabled) {
                    results.push('Wizard.PHA.Edit.Street.Missing');
                }
            } else if (houseOptions.enabled) {
                if (!data[POLICY_HOLDER_ADDRESS_HOUSE].truthy()) {
                    results.push('Wizard.PHA.Edit.House.Missing');
                } else if (flatOptions.enabled) {
                    if (!data[POLICY_HOLDER_ADDRESS_FLAT].truthy()) {
                        results.push('Wizard.PHA.Edit.Flat.Missing');
                    }
                }
            }
        }

        setValidationResults(results);
        return results;
    }

    const compatabilityStepValue = () => {
        return {
            // --> /api/address/residentialArea/{residentialAreaId}/street/search
            'POLICYHOLDERADDRESS.STREET': street,
            // --> /api/address/residentialArea/{residentialAreaId}/address OR /api/address/street/{streetId}/address
            'POLICYHOLDERADDRESS.HOUSE': house,
            // --> /api/address/address/{addressId}/room (HOUSE contains addressId)
            'POLICYHOLDERADDRESS.FLAT': flat
        } as ComplexInputLayerValue;
    }

    useEffect(() => {
        if (!submitValidationRun) {
            return;
        }
        setDisableSubmit(validate(compatabilityStepValue(), true).length !== 0);
    }, [street, house, flat])

    useEffect(() => {
        if (village.value) {
            let villageService = _wmService.readModelValue(RESIDENTIAL_AREA_ID);
            if (villageService.value !== village.value) {
                setVillage(villageService);
                setStreet(() => new InputLayerValue());
                setHouse(() => new InputLayerValue());
                setHouseOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
                setFlat(() => new InputLayerValue());
                setFlatOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
                // suggestion: perhaps udpate the model as well?
            }
        }
    }, [_wmService.model.current]);

    const [houseOptions, setHouseOptions] = useState({ enabled: false, loading: false, options: [] as AutocompleteOption[], cached: 0 });

    // cached village house options in case we need to fallback from street query
    const [villageHouseOptions, setVillageHouseOptions] = useState([] as AutocompleteOption[]);

    /** Operates the second address field - STREET */
    const handleStreetInputChange = (value: InputLayerValue, init = false) => {
        if (!init) {
            // clear dependents
            setHouse(() => new InputLayerValue());
            setHouseOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
            setFlat(() => new InputLayerValue());
            setFlatOptions(() => ({ enabled: false, loading: false, options: [], cached: 0}));
        }

        if (value.truthy()) {
            const streetIdentifier = value.valueAsInt();

            if (!streetIdentifier) {
                setStreet(() => new InputLayerValue());
                return;
            } else {
                setStreet(() => value);
            }

            if (value.meta?.addresses) {
                // if street has addreses, load house options
                setHouseOptions(() => ({ enabled: false, loading: true, options: [], cached: 0 }));
                _wmService
                    .streetAddressCache
                    .getAddresses(streetIdentifier)
                    .then(response => {
                        response = (response ?? []);
                        response.sort((a, b) => {
                            const parsePart = (str: string) => {
                                const numPart = parseInt(str, 10);
                                const alphaPart = str.slice(numPart.toString().length);
                                return { numPart, alphaPart };
                            };

                            const numA = parsePart(a.number);
                            const numB = parsePart(b.number);

                            // Compare numeric parts of strnum first
                            if (numA.numPart !== numB.numPart) {
                                return numA.numPart - numB.numPart;
                            }

                            // If numeric parts are equal, compare alphabetic parts of strnum
                            if (numA.alphaPart < numB.alphaPart) {
                                return -1;
                            }
                            if (numA.alphaPart > numB.alphaPart) {
                                return 1;
                            }

                            // If strnum parts are equal, compare addnum if it exists
                            const hnumA = a.housingNumber ? parseInt(a.housingNumber, 10) : null;
                            const hnumB = b.housingNumber ? parseInt(b.housingNumber, 10) : null;

                            if (hnumA !== null && hnumB !== null) {
                                return hnumA - hnumB;
                            }

                            if (hnumA !== null && hnumB === null) {
                                return -1;
                            }

                            if (hnumA === null && hnumB !== null) {
                                return 1;
                            }

                            return 0;
                        });

                        const newOptions = response.map(x => {
                            let name = x.number;
                            if (x.housingNumber) {
                                name += ` K${x.housingNumber}`;
                            }
                            return {
                                code: x.id.toString(), 
                                value: name, 
                                meta: { addresses: x.addresses }
                            } as AutocompleteOption;
                        });
                        // perhaps we should manually conditionally control timings instead of flat 500.
                        setTimeout(() => {
                            setHouseOptions(() => ({ enabled: newOptions.length > 0, loading: false, options: newOptions, cached: Date.now() }));
                        }, 500);

                    })
                    .catch(() => {
                        // perhaps we should manually conditionally control timings instead of flat 500.
                        setTimeout(() => {
                            setHouseOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
                        }, 500);
                    });
            }
        } else {
            setStreet(() => new InputLayerValue());
            setHouseOptions(() => ({ enabled: false, loading: true, options: [], cached: 0 }));

            if (villageHouseOptions.length > 0) {
                setTimeout(() => {
                    setHouseOptions(() => ({ enabled: true, loading: false, options: villageHouseOptions, cached: Date.now() }));
                }, 500);
            }   
        }
    }

    const [flatOptions, setFlatOptions] = useState({ enabled: false, loading: false, options: [] as AutocompleteOption[], cached: 0 });

    /** Operates the third address field - HOUSE */
    const handleHouseInputChange = (value: InputLayerValue, init = false) => {
        if (!init) {
            // clear dependents
            setFlat(() => new InputLayerValue());
            setFlatOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }))
        }

        if (value.truthy()) {
            const addressIdentifier = value.valueAsInt();

            if (!addressIdentifier) {
                setHouse(() => new InputLayerValue());
                return;
            } else {
                setHouse(() => value);
                setFlatOptions(() => ({ enabled: false, loading: true, options: [], cached: 0 }));
            }

            const url = `/api/address/address/${addressIdentifier}/room`;
            _http
                .get(url)
                .json<any>()
                .catch(error => {
                    logger.error(`DEBUGGER: Was unable to retrieve room list by id #${addressIdentifier} from #${url} `, { details: error });
                    return { rooms: [] };
                })
                .then((response: { rooms: { id: number, number: string }[] }) => {
                    
                    const newOptions = (response.rooms ?? []).map(x => {
                        return { code: x.id.toString(), value: x.number } as AutocompleteOption;
                    });

                    newOptions.sort((a, b) => {
                        const anumberint = parseInt(a.value ?? '0');
                        const bnumberint = parseInt(b.value ?? '0');
                        return anumberint - bnumberint;
                    });

                    // perhaps we should manually conditionally control timings instead of flat 500.
                    setTimeout(() => {
                        setFlatOptions(() => ({ enabled: newOptions.length > 0, loading: false, options: newOptions, cached: Date.now() }));
                    }, 500);
                    
                })
                .catch(() => {
                    // perhaps we should manually conditionally control timings instead of flat 500.
                    setTimeout(() => {
                        setFlatOptions(() => ({ enabled: false, loading: false, options: [], cached: 0}));
                    }, 500);
                    
                });
        } else {
            setHouse(() => new InputLayerValue());
            setFlatOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
        }
    }
    /** Operates the FOURTH address field - FLAT */
    const handleFlatInputChange = (value: InputLayerValue) => {
        setFlat(() => value);
    }

    const handleSubmit = () => {
        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }

        const stepValue = compatabilityStepValue();
        if (validate(stepValue).length === 0) {
            Object
                .keys(stepValue)
                .forEach((x, i, src) => {
                    let shouldOnlyBind = i < src.length - 1;
                    // send all sub-values with 'onlyBind' but send the last one without it to "commit" the changes and go to next step.
                    _wmService.handleStepValueChange(stepValue[x], true, node, shouldOnlyBind, undefined, x)
                });
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    const rootClassNames = `flow-message${!active() ? ' disabled' : ''}`;
    const showValidationArea = active() && validationResults.length > 0;

    const readValue = () => {
        let village = _wmService.readModelValue(RESIDENTIAL_AREA_ID);
        let street = _wmService.readModelValue(POLICY_HOLDER_ADDRESS_STREET);
        let house = _wmService.readModelValue(POLICY_HOLDER_ADDRESS_HOUSE);
        let flat = _wmService.readModelValue(POLICY_HOLDER_ADDRESS_FLAT);

        let result = village.text ?? '';
        if (street.truthy()) {
            result += `, ${street.text}`;
        }
        if (house.truthy()) {
            result += `, ${house.text}`;
        }
        if (flat.truthy()) {
            result += `- ${flat.text}`;
        }

        if (result) {
            // if this step is incomplete, don't show anything at all.
            if (result === village.text) {
                return null;
            }
            return result;
        }
        return '...';
    }


    useEffect(() => {
        if (!village.truthy()) {
            return;
        }

        if (village.meta?.addresses) {
            setHouseOptions(() => ({ enabled: false, loading: true, options: [], cached: 0 }));

            _wmService
                .residentialAreaAddressCache
                .getAddresses(village.valueAsInt()!)
                .then(response => {
                    response = (response ?? []);
                    response.sort((a, b) => {
                        const digitaNumber = a.number.replace(/\D/g, '');
                        const anumberint = parseInt(digitaNumber ?? '0');
                        const digitaHousingNumber = (a.housingNumber ?? '').replace(/\D/g, '');
                        const ahousingint = parseInt(digitaHousingNumber ?? '0');

                        const digitbNumber = b.number.replace(/\D/g, '');
                        const bnumberint = parseInt(digitbNumber ?? '0');
                        const digitbHousingNumber = (b.housingNumber ?? '').replace(/\D/g, '');
                        const bhousingint = parseInt(digitbHousingNumber ?? '0');

                        if (anumberint === bnumberint) {
                            return ahousingint - bhousingint;
                        }

                        return anumberint - bnumberint;
                    });

                    let newOptions = (response ?? []).map(x => {
                        let name = x.number;
                        if (x.housingNumber) {
                            name += ` K${x.housingNumber}`;
                        }
                        return { code: x.id.toString(), value: name } as AutocompleteOption;
                    });

                    setVillageHouseOptions(() => newOptions);

                    setTimeout(() => {
                        setHouseOptions(() => ({ enabled: newOptions.length > 0, loading: false, options: newOptions, cached: Date.now() }));
                    }, 500);
                })
                .catch(() => {
                    setHouseOptions(() => ({ enabled: false, loading: false, options: [], cached: 0 }));
                });
        }
    }, []);

    return (
        <React.Fragment>
             {
                !hidden()
                && (
                    <React.Fragment>
                        {
                            active()
                                ? (
                                    <FlowMessage
                                        stepId={POLICY_HOLDER_ADDRESS_ID}
                                        queryArea={
                                            <AppQueryHeader>{t('Wizard.PHA.Edit.Query')}</AppQueryHeader>
                                        }
                                        inputArea={() => 
                                            <React.Fragment>
                                                <div className="address-container">
                                                    <div className={`field-container-area fca-village`}>
                                                        <div className={`field-ico`}>
                                                            <AppIconDisplay name={'location'} size={AppIconDisplaySize.S } />
                                                        </div>
                                                        <div className={`field-area-ref`} data-address-id={village.value} style={{ width: '100%', padding: '12px 12px 12px 48px', display: 'flex', alignItems: 'center' } }>
                                                            {village?.text}
                                                        </div>
                                                    </div>
                                                    {
                                                        village.meta?.streets
                                                        && (
                                                            <div className={`field-container-area fca-street`}>
                                                                <div className={`field-ico`}>
                                                                    <AppIconDisplay name={'street'} size={AppIconDisplaySize.S } />
                                                                </div>
                                                                <AutocompleteInput
                                                                    identifier={'pha-street'}
                                                                    modalPortal={_wmService.pageViewportRef.current as Element}
                                                                    onValueChange={handleStreetInputChange}
                                                                    placeholder={{ entry: 'Wizard.PHA.Edit.Street.Entry', modal: 'Wizard.PHA.Edit.Street.Modal', assistive: 'Wizard.PHA.Edit.Street.Assistive' }}
                                                                    disableSubmit={!active() && disableSubmit}
                                                                    value={street}
                                                                    hideSubmit={true}
                                                                    configuration={{
                                                                        type: 'api',
                                                                        threshold: 1,
                                                                        endpoint: () => `/api/address/residentialArea/${village.value}/street/search`,
                                                                        buildRequest: (term) => ({ query: term }),
                                                                        processResponse: (response: { results: { id: number, name: string, addresses: boolean }[] }) => {
                                                                            return response.results.map(x => {
                                                                                return ({ code: x.id.toString(), value: x.name, meta: { addresses: x.addresses }  } as AutocompleteOption);
                                                                            });
                                                                        }
                                                                    }}
                                                                    scrollTarget={_wmService.flowBodyRef.current}
                                                                />
                                                            </div>
                                                       
                                                        )
                                                    }
                                                    {
                                                        (village.meta?.addresses || street.meta?.addresses)
                                                        && (
                                                            <div className={`field-container-area fca-house ${houseOptions.enabled || houseOptions.loading ? '' : ' hidden'}`}>
                                                                {
                                                                    houseOptions.loading
                                                                    && (
                                                                        <div className="field-area-ref-loader" title={t('Wizard.PHA.HouseLookupInProgress')}><AppLoader /></div>
                                                                    )
                                                                }
                                                                {
                                                                    houseOptions.enabled
                                                                    && (
                                                                        <React.Fragment>
                                                                            <div className={`field-ico`}>
                                                                                <AppIconDisplay name={'house'} size={AppIconDisplaySize.S } />
                                                                            </div>
                                                                            <AutocompleteInput
                                                                                identifier={'pha-house'}
                                                                                modalPortal={_wmService.pageViewportRef.current as Element}
                                                                                onValueChange={handleHouseInputChange}
                                                                                placeholder={{ entry: 'Wizard.PHA.Edit.House.Entry', modal: 'Wizard.PHA.Edit.House.Modal', assistive: 'Wizard.PHA.Edit.House.Assistive' }}
                                                                                disableSubmit={!active() && disableSubmit}
                                                                                value={house}
                                                                                hideSubmit={true}
                                                                                configuration={{
                                                                                    type: 'static',
                                                                                    options: { lng: i18n.resolvedLanguage as ApplicationLanguge, o: houseOptions.options, cached: houseOptions.cached }
                                                                                }}
                                                                                scrollTarget={_wmService.flowBodyRef.current}
                                                                            />  
                                                                        </React.Fragment>
                                                                    )
                                                                }  
                                                            </div>
                                                        )
                                                    }
                                                    <div className={`field-container-area fca-flat ${flatOptions.enabled || flatOptions.loading ? '' : ' hidden'}`}>
                                                        {
                                                            flatOptions.loading
                                                            && (
                                                                <div className="field-area-ref-loader" title={t('Wizard.PHA.FlatLookupInProgress')}><AppLoader /></div>
                                                            )
                                                        }
                                                        {
                                                            flatOptions.enabled
                                                            && (
                                                                <React.Fragment>
                                                                    <div className={`field-ico`}>
                                                                        <AppIconDisplay name={'apartment'} size={AppIconDisplaySize.S } />
                                                                    </div>
                                                                    <AutocompleteInput
                                                                        identifier={'pha-flat'}
                                                                        modalPortal={_wmService.pageViewportRef.current as Element}
                                                                        onValueChange={handleFlatInputChange}
                                                                            placeholder={{ entry: 'Wizard.PHA.Edit.Flat.Entry', modal: 'Wizard.PHA.Edit.Flat.Modal', assistive: 'Wizard.PHA.Edit.Flat.Assistive' }}
                                                                        disableSubmit={!active() && disableSubmit}
                                                                        value={flat}
                                                                        hideSubmit={true}
                                                                        configuration={{
                                                                            type: 'static',
                                                                            options: { lng: i18n.resolvedLanguage as ApplicationLanguge, o: flatOptions.options, cached: flatOptions.cached }
                                                                        }}
                                                                        scrollTarget={_wmService.flowBodyRef.current}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="address-container-confirm">
                                                    <AppButton onPress = { () => handleSubmit() }>
                                                        {t('Wizard.PHA.Confirm')}
                                                    </AppButton> 
                                                </div> 
                                            </React.Fragment>
                                        }
                                        ignoreFocusTransfer={true}
                                        assistanceRecords={validationResults.map(x => ({ key: x, type: FlowMessageAssistanceRecordType.Error } as FlowMessageAssistanceRecord))}
                                    /> 
                                )
                                : (
                                    <div data-step-id={POLICY_HOLDER_ADDRESS_ID} className={rootClassNames}>   
                                        <div className={`flow-message-area ${showValidationArea ? ' invalid' : ''}`}>
                                            {
                                                readValue()
                                                && (
                                                    <div className="flow-message-read">
                                                        <div className="flow-message-read-icon">
                                                            <AppIconDisplay name="street" size={AppIconDisplaySize.S} />
                                                        </div>
                                                        <div className="flow-message-read-label">
                                                            {t('Wizard.PHA.Read.Query')}
                                                        </div>
                                                        <div className="flow-message-read-value">
                                                            {readValue()}
                                                        </div>
                                                        {
                                                            editable()
                                                            && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                                        }
                                                    </div>
                                                )
                                            }       
                                        </div>
                                    </div>
                                )
                        }
                    </React.Fragment>
                )
            }
        </React.Fragment>    
    )
} 

export const PolicyHolderAddressSkipHandler = (...args: any[]) => {
    const readModelValue: (key: string, fallback?: string) => InputLayerValue = args[0];
    const residentialArea = readModelValue(RESIDENTIAL_AREA_ID);
    return !residentialArea.meta?.streets && !residentialArea.meta?.addresses;
}