import React, { useContext } from "react";
import { PaymentStatus } from "../../../models/Comm.models";
import { AppLoader } from "../../shared/app-loader/AppLoader";
import { Trans, useTranslation } from "react-i18next";
import AppIconDisplay, { AppIconDisplaySize } from "../../shared/app-icon-display/AppIconDisplay";
import PolicyDocuments from "../../get-documents/policy-documents/PolicyDocuments";
import AppButton, { AppButtonType } from "../../shared/app-button/AppButton";
import { AppService } from "../../../App";
import { WizardManagerService } from "../WizardManager.service";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import AppEmphasizedText, { AppEmphasizedTextTheme } from "../../shared/app-emphasized-text/AppEmphasizedText";
import FlowMessage from "../flow-message/FlowMessage";
import './FlowFinish.scss';
import { PaymentInitiationState, PaymentState } from "../WizardManager.models";
import { useNavigate } from 'react-router-dom';

export const FINISH_ID = 'FINISH';

const FlowFinish: React.FC = () => {
    const { application } = useContext(AppService);
    const _wmService = useContext(WizardManagerService);

    if (application?.environmentName) {
        _wmService.processPaymentCallback();
    }

    return (
        <div className="flow-finish-area">
            <PaymentInfo
                paymentState={_wmService.paymentState}
                paymentInitiationState={_wmService.paymentInitiationState}
                reInitiateCanceledPayment={_wmService.reInitiateCanceledPayment}
            />
        </div>
    )
}

export default FlowFinish;

const PaymentPending = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="payment-status">
                <div className="header-title" style={{ color: '#79C2C6' }}>{t('Wizard.RESULT.PaymentPending')}</div>
            </div>
            <div className="payment-area-body">
                <div style={{ padding: '16px 18px', display: 'flex', justifyContent: 'center' }}>
                    <AppLoader />
                </div>
            </div>
        </React.Fragment>
    );
}

const PaymentCanceled: React.FC<{
    paymentInitiationState: PaymentInitiationState,
    reInitiateCanceledPayment: () => void,
}> = ({
    paymentInitiationState,
    reInitiateCanceledPayment,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="payment-status payment-status--error">
                <div className="header-vis">
                    <AppIconDisplay name="status-error" />
                </div>
                <div className="header-title">
                    {t('Wizard.RESULT.PaymentCanceled.Title')}
                </div>
            </div>
            <div className="payment-area-body">
                <FlowMessage
                    stepId="PAYMENT-CANCELED"
                    queryArea={
                        <AppQueryHeader>{t('Wizard.RESULT.PaymentCanceled.Description')}</AppQueryHeader>
                    }
                    inputArea={() => 
                        paymentInitiationState.loading
                            ? (
                                <div className="payment-pending">
                                    <span>{t('Wizard.RESULT.PaymentRedirecting')}</span>
                                    <AppLoader />
                                </div>
                            )
                            : (
                                <React.Fragment>
                                    {
                                        !paymentInitiationState.success
                                            ? (
                                                <div className="payment-error">
                                                    {t('Wizard.RESULT.PaymentNotInitiated')}
                                                </div>
                                            )
                                            : null
                                    }
                                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', padding: '1rem' }}>
                                        <AppButton type={AppButtonType.Tertiary} onPress={() => navigate("/")}>
                                            {t('Wizard.RESULT.PaymentCanceled.Cancel')}
                                        </AppButton>
                                        <AppButton onPress={() => reInitiateCanceledPayment()}>
                                            {t('Wizard.RESULT.PaymentCanceled.RepeatPayment')}
                                        </AppButton>
                                    </div>
                                </React.Fragment>
                            )
                    }
                />
            </div>
        </React.Fragment>
    );
}

const PaymentSuccess: React.FC<{paymentState: PaymentState}> = ({ paymentState }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="payment-status payment-status--success">
                <div className="header-vis">
                    <AppIconDisplay name="status-success" size={AppIconDisplaySize.L} />
                </div>
                <div className="header-title">
                    {t('Wizard.RESULT.PaymentSuccess.Title')}
                </div>
            </div>
            <div className="payment-area-body">
                {
                    !paymentState.policyReady
                    && (
                        <React.Fragment>
                            <div className="description" style={{ marginTop: '16px' }}>
                                <AppQueryHeader>
                                    {t('Wizard.RESULT.PaymentSuccess.NotReady')}
                                </AppQueryHeader>
                            </div>
                            <div style={{ padding: '16px 18px', display: 'flex', justifyContent: 'center' }}>
                                <AppLoader />
                            </div>
                        </React.Fragment>
                    )
                }
                {   
                    (paymentState.policyReady && paymentState.policySuccess)
                    && (
                        <React.Fragment>
                            <div className="description">
                                <AppQueryHeader>
                                    <div>
                                        <Trans
                                            i18nKey="Wizard.RESULT.PaymentSuccess.Description"
                                            values={{ email: paymentState.policyHolderEmail }}
                                            components={{
                                                emp: <AppEmphasizedText theme={AppEmphasizedTextTheme.Black} />
                                            }}
                                        />
                                    </div>
                                </AppQueryHeader>
                            </div>
                            <PolicyDocuments policyIdentifier={paymentState.id} />
                        </React.Fragment> 
                    )
                }
                {   
                    (paymentState.policyReady && !paymentState.policySuccess)
                    && (
                         <FlowMessage
                            stepId="PAYMENT-POLICY-ERROR"
                            queryArea={
                                <AppQueryHeader>{t('Wizard.RESULT.PaymentSuccess.PolicyError.Title')}</AppQueryHeader>
                            }
                            inputArea={() => 
                                <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
                                    <AppButton mailto={{
                                        email: 'mailto:info@manramu.lt',
                                        subject: t('Wizard.RESULT.PaymentSuccess.PolicyError.ContactUs.Subject'),
                                        body: t('Wizard.RESULT.PaymentSuccess.PolicyError.ContactUs.Body', { policyid: paymentState.id })
                                    }}>
                                        {t('Wizard.RESULT.PaymentSuccess.PolicyError.ContactUs.Title')}
                                    </AppButton>
                                </div>
                            }
                            padded={false}
                        />
                    )
                }
            </div>
        </React.Fragment>
    );
}

const PaymentError: React.FC<{
    paymentInitiationState: PaymentInitiationState,
    reInitiateCanceledPayment: () => void
}> = ({
    paymentInitiationState,
    reInitiateCanceledPayment
}) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="payment-status payment-status--error">
                <div className="header-vis">
                    <AppIconDisplay name="status-error" />
                </div>
                <div className="header-title">
                    {t('Wizard.RESULT.PaymentError.Title')}
                </div>
            </div>
            <div className="payment-area-body">
                <FlowMessage
                    stepId="PAYMENT-ERROR"
                    queryArea={
                        <AppQueryHeader>{t('Wizard.RESULT.PaymentError.Description')}</AppQueryHeader>
                    }
                    inputArea={() => 
                        paymentInitiationState.loading
                            ? (
                                <div className="payment-pending">
                                    <span>{t('Wizard.RESULT.PaymentRedirecting')}</span>
                                    <AppLoader />
                                </div>
                            )
                            : (
                                <React.Fragment>
                                    {
                                        !paymentInitiationState.success
                                            ? (
                                                <div className="payment-error">
                                                    {t('Wizard.RESULT.PaymentNotInitiated')}
                                                </div>
                                            )
                                            : null
                                    }
                                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', padding: '1rem' }}>
                                        <AppButton onPress={() => reInitiateCanceledPayment()}>
                                            {t('Wizard.RESULT.PaymentCanceled.RepeatPayment')}
                                        </AppButton>
                                    </div>
                                </React.Fragment>
                            )
                    }
                />
            </div>
        </React.Fragment>
    );
}

const PaymentInfo: React.FC<{
    paymentState: PaymentState,
    paymentInitiationState: PaymentInitiationState,
    reInitiateCanceledPayment: () => void
}> = ({
    paymentState,
    paymentInitiationState,
    reInitiateCanceledPayment
}) => {
    if (paymentState.ready && !paymentState.success) {
        return <PaymentError
            paymentInitiationState={paymentInitiationState}
            reInitiateCanceledPayment={reInitiateCanceledPayment}
        />;
    }

    switch (paymentState.paymentStatus) {
        case PaymentStatus.Pending:
            return <PaymentPending />
        case PaymentStatus.Canceled:
            return <PaymentCanceled
                paymentInitiationState={paymentInitiationState}
                reInitiateCanceledPayment={reInitiateCanceledPayment}
            />
        case PaymentStatus.Success:
            return <PaymentSuccess paymentState={paymentState} />
        default:
            return (
                <div className="payment-area-body">
                    <div style={{ padding: '16px 18px', display: 'flex', justifyContent: 'center' }}>
                        <AppLoader />
                    </div>
                </div>
            );
    }
}