import './AppIconDisplay.scss';
import React from "react";

export enum AppIconDisplaySize {
    Inherit = 'inherit',
    XXL = 'xxl',
    XL = 'xl',
    L = 'l',
    M = 'm',
    S = 's',
    XS = 'xs',
    XXS = 'xxs'
}
export type AppIconDisplayProps = {
    /** Name of icon that results in 'svg-{name}-sym class for CSS. See "styles/svg-icons.scss" for list of currently available icons.*/
    name: string;
    /** Size of icon. [Default, Small, ExtraSmall] */
    size?: AppIconDisplaySize;
}
const AppIconDisplay: React.FC<AppIconDisplayProps> = ({
    name,
    size = AppIconDisplaySize.Inherit
}) => {
    return (
        <div className={`app-icon-display size--${size}`}>
            <div className={`svg-icon svg-${name}-sym`}>
            </div>
        </div>
    );
}

export default AppIconDisplay;