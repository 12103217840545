import './PH.scss'
import React, { useState } from "react";
import { ComplexInputLayerValue, InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { TextInput } from "../../../shared/app-text-input/AppTextInput";
import { FlowMessageReadEdit } from "../../WizardManager";
import AppButton, { AppButtonType } from "../../../shared/app-button/AppButton";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../../flow-message/FlowMessage';
import { InputControlContextSignature } from '../../../shared/AppInputControlContext';
import { PreventScrollMount } from '../../../../services/usePreventScroll';

export const POLICY_HOLDER_ID = 'POLICYHOLDER';

export const PolicyHolderHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, hidden } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [stepValue, setStepValue] = useState({
        'POLICYHOLDER.FIRSTNAME': _wmService.readModelValue('POLICYHOLDER.FIRSTNAME'),
        'POLICYHOLDER.LASTNAME': _wmService.readModelValue('POLICYHOLDER.LASTNAME')
    } as ComplexInputLayerValue);
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (data: ComplexInputLayerValue) => {
        const results = [];
        if (Object.keys(data).every(k => data[k].value.length === 0)) {
            results.push('Validation.Mandatory');
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (key: string, value: InputLayerValue) => {
        const updated: ComplexInputLayerValue = updatedStepValue(key, value);
        setStepValue(updated);

        if (submitValidationRun) {
            setDisableSubmit(validate(updated).length !== 0);
        }      
    };

    const updatedStepValue = (key: string, value: InputLayerValue) => {
        let updatedValue = {} as ComplexInputLayerValue;
        updatedValue[key] = value;
        return { ...stepValue, ...updatedValue };
    }

    const handleSubmit = () => {
        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }
        
        if (validate(stepValue).length === 0) {
            Object
                .keys(stepValue)
                .forEach((x, i, src) => {
                    let shouldOnlyBind = i < src.length - 1;
                    // send all sub-values with 'onlyBind' but send the last one without it to "commit" the changes and go to next step.
                    _wmService.handleStepValueChange(stepValue[x], true, node, shouldOnlyBind, undefined, x)
                });
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    const rootClassNames = `flow-message${!active() ? ' disabled' : ''}`;
    const showValidationArea = active() && validationResults.length > 0;

    const readValue = (): string => {
        let modelValue = '';
        let firstName = _wmService.readModelValue('POLICYHOLDER.FIRSTNAME');
        let lastName = _wmService.readModelValue('POLICYHOLDER.LASTNAME');

        if (firstName.truthy()) {
            modelValue += firstName.text;
        }

        if (lastName.truthy()) {
            modelValue += ` ${lastName.text}`;
        }

        if (modelValue) {
            return modelValue;
        }
        return '...';
    }

    return (
        <React.Fragment>
             {
                !hidden()
                && (
                    <React.Fragment>
                        {
                            active()
                                ? (
                                    <FlowMessage
                                        stepId={POLICY_HOLDER_ID}
                                        queryArea={
                                            <AppQueryHeader>{t('Wizard.PH.Edit.Query')}</AppQueryHeader>
                                        }
                                        inputArea={(_cc: InputControlContextSignature) => 
                                           <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                                                    {
                                                        _cc.isFocused
                                                        && <PreventScrollMount scrollTarget={_wmService.flowBodyRef.current} source={'PH'} />
                                                    }   
                                                    <TextInput
                                                        onValueChange={(x) => handleInputChange('POLICYHOLDER.FIRSTNAME', x)}
                                                        placeholder={'Wizard.PH.Edit.FirstName.Placeholder'}
                                                        disableSubmit={!active() && disableSubmit}
                                                        hideSubmit={true}
                                                        value={stepValue['POLICYHOLDER.FIRSTNAME']}
                                                        disableCapitalization={node.disableCapitalization}
                                                        maxLength={254}
                                                        className={'POLICYHOLDER_FIRSTNAME'}
                                                    />
                                                    <TextInput
                                                        onValueChange={(x) => handleInputChange('POLICYHOLDER.LASTNAME', x)}
                                                        placeholder={'Wizard.PH.Edit.LastName.Placeholder'}
                                                        disableSubmit={!active() && disableSubmit}
                                                        hideSubmit={true}
                                                        initialFocus={false}
                                                        value={stepValue['POLICYHOLDER.LASTNAME']}
                                                        disableCapitalization={node.disableCapitalization}
                                                        maxLength={254}
                                                        className={'POLICYHOLDER_LASTNAME'}
                                                    />
                                                </div>

                                                <AppButton
                                                    className="confirm-button"
                                                    type={AppButtonType.Secondary}
                                                    onPress={() => handleSubmit()}
                                                    title="Approve your first and last name"
                                                    iconOnly={true}
                                                >
                                                    <AppIconDisplay name="send" />
                                                </AppButton>
                                            </div>
                                        }
                                        ignoreFocusTransfer={true}
                                        ownScrollPrevention={true}
                                        assistanceRecords={validationResults.map(x => ({ key: x, type: FlowMessageAssistanceRecordType.Error} as FlowMessageAssistanceRecord))}
                                        withConfirm={true}
                                    /> 
                                )
                                : (
                                    <div data-step-id={POLICY_HOLDER_ID} className={rootClassNames}>
                                        <div className={`flow-message-area with-actions${showValidationArea ? ' invalid' : ''}`}>
                                            <div className="flow-message-read">
                                                <div className="flow-message-read-icon">
                                                    <AppIconDisplay name="personalcode" size={AppIconDisplaySize.S} />
                                                </div>
                                                <div className="flow-message-read-label">
                                                    {t('Wizard.PH.Read.Query')}
                                                </div>
                                                <div className="flow-message-read-value">
                                                    {readValue()}
                                                </div>
                                                {
                                                    editable()
                                                    && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}