/** 
* Session identifier that can be used to restore data entered in context of session.
* User controllable - NO
* Persistance - SESSIONSTORAGE
*/
export const C_SESSION_KEY = 'MR_CACHE_SESSION_IDENTIFIER';

/** 
* Used in non-production environments to control the state of debug menu availability when payment flow is tested.
* User controllable - NO
* Persistance - SESSIONSTORAGE
*/
export const C_QUOTATION_ID_KEY = 'MR_CACHE_QUOTATION_ID';

/** 
* Its presence idicates that logger should output debug messages.
* User controllable - NO
* Persistance - LOCALSTORAGE
*/
export const C_LOGGER_KEY = 'MR_CACHE_LOGGER';

/** 
* Stores users choice on term acceptance.
* User controllable - YES
* Persistance - SESSIONSTORAGE.
*/
export const C_TERMS_KEY = 'MR_CACHE_TERMS';

/** 
* Stores users language choice on site visit.
* User controllable - YES
* Persistance - LOCALSTORAGE.
*/
export const C_LANGUAGE = 'MR_CACHE_LANGUAGE';