import './ACCEPT.scss'
import React from "react";
import { InputLayerValue, InsurerIdMapping, WizardStepProps } from "../../WizardManager.models";
import AppButton, { AppButtonType } from "../../../shared/app-button/AppButton";
import { QUOTE_ID } from "../QUOTE/QUOTE";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";

export const ACCEPT_ID = 'ACCEPT';

export const AcceptHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, active } = useWizardStep(node, isLast);
    const { t } = useTranslation();

    const handleSubmit = () => {
        _wmService.handleStepValueChange(new InputLayerValue('ACCEPT', 'Wizard.ACCEPT.Edit.Accept'), true, node, false)
    }

    const selectedProposal = () => {
        let result = {} as any;
        let selectedInsurer = _wmService.readModelValue(QUOTE_ID).valueAsInt();
        if (selectedInsurer) {
            result.insurer = InsurerIdMapping[selectedInsurer];
        }
        let selectedQuotation = (_wmService.quotationState.result ?? []).find(x => x.insurerId === selectedInsurer);
        if (selectedQuotation) {
            if (selectedQuotation.premium) {
                let sp = selectedQuotation.premium.toString().split(/[.,]/);
                if (sp.length == 1) {
                    sp.push('00');
                }
                if (sp[1].length == 1) {
                    sp[1] += '0';
                }
                result.premium = { left: sp[0], right: sp[1] };
            } else {
                result.premium = { left: '', right: '' };
            }
        }
       
        return result;
    }

    return (
        <React.Fragment>
            {
                active()
                && (
                    <div data-step-id={ACCEPT_ID} className={`flow-message${!active() ? ' disabled' : ''}`}>
                        <div className={`flow-message-area`}>
                            <div className="proposal-accept-area-tick">
                                 <div className="proposal-accept-area-tick-draw">
                                </div>
                            </div>
                            <div className="proposal-accept-area">
                                <div className="proposal-accept-area-header">
                                    {t('Wizard.ACCEPT.Edit.Query')}
                                </div>
                                <div className="proposal-accept-area-body">
                                    <div className="proposal-provider">{selectedProposal().insurer}</div>
                                    <div className="proposal-price">
                                        <div className="price-sign">
                                            {'\u20AC '}
                                        </div>
                                        <div className="price-primary">
                                            {selectedProposal().premium.left}.
                                        </div>
                                        <div className="price-secondary">
                                            {selectedProposal().premium.right}
                                        </div>
                                    </div>
                                </div>
                                <div className="proposal-accept-area-footer">
                                    <AppButton onPress={() => handleSubmit()}>
                                        {t('Wizard.ACCEPT.Edit.AcceptShort')}
                                    </AppButton>
                                    <AppButton onPress={() => _wmService.chooseAnotherProposal()} type={AppButtonType.Tertiary}>
                                        {t('Wizard.ACCEPT.Edit.ChooseAnotherProposal')}
                                    </AppButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }       
        </React.Fragment>     
    )
}