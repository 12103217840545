import { useContext } from 'react';
import { WizardManagerService } from '../WizardManager.service';
import { StepNode } from '../WizardManager.models';
import { AppService } from '../../../App';
import { QUOTE_ID } from './QUOTE/QUOTE';
import { useTranslation } from 'react-i18next';

/**
* Provides some shared functionality for all steps. 
* Still work in progress as there are multiple other tings we could add here.
*/
const useWizardStep = (node: StepNode, isLast: boolean) => {
    const _wmService = useContext(WizardManagerService);
    const _appService = useContext(AppService);
    const { t } = useTranslation();

    const editable = () => {
        return !_wmService.policyState.ready
            && !_wmService.policyState.loading
            && !_wmService.interruptionState.enabled; 
            // checking if there are any enabled interruptions allows us to funnel user to clicking the 
            // prompt button rather than manually clicking edit on a particualr row
    }

    /** Only active step node and without interruption can be shown. */
    const active = () => {
        return _wmService.activeStep === node.id;
    }

    const hidden = () => {
        // if should hide on outdate it should not be shown when outdated, or loading, or quote step itself is activated
        return node.hideOnOutdate && _wmService.quotationState.outdated;
    }

    const readValue = (textFormatter?: (x: string) => string) => {
        let modelValue = _wmService.readModelValue(node.id);
        if (modelValue.truthy()) {
            if (textFormatter) {
                return textFormatter(modelValue.text);
            }

            return t(modelValue.text);
        }

        return '...';
    }

    return {
        _wmService,
        _appService,
        editable,
        active,
        hidden,
        readValue
    };
};

export default useWizardStep;