import { ReactNode, createContext, useMemo, useRef } from 'react';

export type AppRoutePageServiceSignature = {
        /** Exposes main-viewport DOM. */
    mainViewportRef?: React.RefObject<HTMLDivElement>;
    /** Exposes page-viewport DOM. */
    pageViewportRef?: React.RefObject<HTMLDivElement>;
    /** Exposes page-vbody DOM. */
    pageBodyRef?: React.RefObject<HTMLDivElement>;
};

export const AppRoutePageService = createContext<AppRoutePageServiceSignature>({} as any);

/** A service for future shared app route page component functionality. */
export const AppRoutePageServiceProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
    /** Reference to flow body DOM */
    const flowBodyRef = useRef<HTMLDivElement>(null);

    /** Reference to page viewport DOM */
    const pageViewportRef = useRef<HTMLDivElement>(null);

    /** Reference to page viewport DOM */
    const pageBodyRef = useRef<HTMLDivElement>(null);

    /** Memo insures that this will force a refresh of children once dependency changes */
    const providerValue = useMemo<AppRoutePageServiceSignature>(
        () => ({
            flowBodyRef,
            pageViewportRef,
            pageBodyRef
        } as AppRoutePageServiceSignature),
        [
            flowBodyRef,
            pageViewportRef,
            pageBodyRef
        ]
    );

    return (
        <AppRoutePageService.Provider value={providerValue}>
            {children}
        </AppRoutePageService.Provider>
    );
};