import { ReactElement, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InputControlContext, InputControlContextProvider, InputControlContextSignature } from '../../shared/AppInputControlContext';

export enum FlowMessageAssistanceRecordType {
    Informational = 'informational',
    Error = 'error'
}

export type FlowMessageAssistanceRecord = {
    /** Validation result description in translation key format. */
    key: string;
    /** Translation params if any (passed to transation fn). */
    params?: { [key: string]: string | number; };
    /** Type of assistance */
    type: FlowMessageAssistanceRecordType;
}

type FlowMessageProps = {
    /** Provides some contextual information when looking at DOM. */
    stepId: string;
    /** List of translation keys with params and type */
    assistanceRecords?: FlowMessageAssistanceRecord[];
    /** Whether input area will have a confirm button. */
    withConfirm?: boolean;
    /** Query area DOM */
    queryArea: ReactElement;
    /** Input area DOM */
    inputArea: (_cc: InputControlContextSignature) => ReactElement;
    /** Add area DOM */
    addArea?: ReactElement;
    /** Whether flow message should have padding applied to it */
    padded?: boolean;
    ignoreFocusTransfer?: boolean;
    ownScrollPrevention?: boolean;
}

const InternalFlowMessage: React.FC<FlowMessageProps> = ({
    stepId,
    queryArea,
    inputArea,
    addArea,
    withConfirm,
    assistanceRecords,
    ignoreFocusTransfer,
    ownScrollPrevention,
    padded = true
}) => {
    const _cc = useContext(InputControlContext);
    const { t } = useTranslation();
    const showAssistanceArea = (assistanceRecords ?? []).length > 0;

    let areaClassName = 'flow-message-area';
    if (withConfirm) {
        areaClassName += ' with-actions';
    }
    if (showAssistanceArea && (assistanceRecords ?? []).some(x => x.type === FlowMessageAssistanceRecordType.Error)) {
        areaClassName += ' invalid';
    }

    useEffect(() => {
        if (ownScrollPrevention) {
            _cc.setExternalScrollPrevention(true);
        }
    }, [ownScrollPrevention, _cc]);

    return (
        <div data-step-id={stepId} className={`flow-message ${!padded ? 'not-padded' : ''}`}>
            <div className={areaClassName}>
                {queryArea}
                <div className={`flow-message-input-area${!ignoreFocusTransfer && _cc.isFocused ? ' focused-input-area' : ''}`}>
                    {inputArea(_cc)}
                </div>
            </div>
            {
                showAssistanceArea
                && (
                    <div className="flow-message-input-assistance-area">
                        <div className="flow-message-input-assistance">
                            {
                                (assistanceRecords ?? []).map(x => (
                                    <div className={`flow-message-input-assistance-record type--${x.type}`} key={x.key}>
                                        {t(x.key, (x.params ?? {}))}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                addArea ?? <></>
            }
        </div>
    );
}

const FlowMessage: React.FC<FlowMessageProps> = (props) => {
    return (
        <InputControlContextProvider debounce={props.ownScrollPrevention ?? false}>
            <InternalFlowMessage {...props} />
        </InputControlContextProvider>
    );
}

export default FlowMessage;