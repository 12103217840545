import './AppAutocompleteInput.scss';
import React, { useContext, useEffect, useRef, useState } from "react";
import { InputLayer, InputLayerValue } from "../../flow-manager/WizardManager.models";
import AppButton, { AppButtonSize, AppButtonType } from "../app-button/AppButton";
import AppIconDisplay from "../app-icon-display/AppIconDisplay";
import { useTranslation } from "react-i18next";
import { ApplicationLanguge, _http } from "../../../App";
import {  debounce } from 'lodash';
import {
    Dialog, Input, Modal,
    ListBox, ListBoxItem, Selection
} from 'react-aria-components';
import { usePress } from "@react-aria/interactions";
import { useFocusRing } from "../../../services/useFocusRing";
import { PreventScrollMount } from '../../../services/usePreventScroll';
import { v4 as uuid } from 'uuid';
import { CodeValue } from '../../../models/App.models';
import AppQueryHeader from '../app-query-header/AppQueryHeader';
import { AppLoader, AppLoaderSize } from '../app-loader/AppLoader';
import { InputControlContext } from '../AppInputControlContext';

export type AutocompleteOptions = {
    lng: ApplicationLanguge,
    o: AutocompleteOption[],
    cached: number
}

export function asAutocompleteOption(value: CodeValue): AutocompleteOption {
    let result: AutocompleteOption = { code: value.code, value: value.value };
    if (Object.hasOwn(value, 'from')) {
        result.meta = { from: value.from };
    }
    if (Object.hasOwn(value, 'to')) {
        result.meta = { ...result.meta ?? {}, to: value.to };
    }
    return result;
}

export type AutocompleteOption = {
    /** code used to identify option e.g.'1001' */
    code: string;
    /** textual value used to describe value e.g 'One thousand and one' */
    value: string;
    /** stores any additional data regarding value */
    meta?: { [key: string]: any };
}

export type StaticOptionConfig = {
    type: 'static',
    options: AutocompleteOptions;
}

export type ApiOptionConfig = {
    type: 'api',
    threshold: number;
    endpoint: () => string;
    buildRequest: (term: string) => any;
    processResponse: (response: any) => AutocompleteOption[];
}
export type AutocompleteConfig = StaticOptionConfig | ApiOptionConfig;

export type AutocompleteInputLayer = InputLayer & {
    /** Input placeholder translation key.*/
    placeholder?: {
        entry: string;
        assistive: string;
        modal?: string;
    }
    configuration: AutocompleteConfig;
    hideSubmit?: boolean;
    clearable?: boolean;
    selectOnly?: boolean;
    popperXOffset?: number;
    popperYOffset?: number;
    modalPortal?: Element;
    scrollTarget?: HTMLElement | null;
}

export const AutocompleteInput: React.FC<AutocompleteInputLayer> = ({
    identifier,
    value,
    onValueChange,
    placeholder,
    onSubmit,
    hideSubmit,
    configuration,
    clearable = true,
    selectOnly = false,
    modalPortal = document.body,
    scrollTarget = document.documentElement,
    maxLength = 25
}) => {
    /** Note that this is only available if this control is within FlowMessage component */
    const _cc = useContext(InputControlContext);
    const { t, i18n } = useTranslation();
    const acid = useRef(`off-${uuid()}`);

    const defaultSourceOptions = () => {
        if (configuration.type === 'static') {
            return configuration.options;
        }
        const result: AutocompleteOptions = {
            lng: i18n.resolvedLanguage as ApplicationLanguge,
            o: [] as AutocompleteOption[],
            cached: Date.now()
        };
        if (value?.truthy()) {
            result.o = [
                {
                    code: value.text,
                    value: value.value,
                    meta: value.meta
                } as AutocompleteOption
            ];
        }
        return result;
    }

    const baseFilteredOptions = (stateOptions?: AutocompleteOptions): AutocompleteOptions => {
        if (configuration.type === 'static') {
            return { ...sourceOptions.current };
        }

        // configuration.type === 'api'
        if (stateOptions?.o?.length) {
            return { ...stateOptions };
        }

        return {
            lng: i18n.resolvedLanguage as ApplicationLanguge,
            o: [] as AutocompleteOption[],
            cached: Date.now()
        };
    }

    const sourceOptions = useRef(defaultSourceOptions());

    const [cs, setCs] = useState({
        trackedValue: value,
        displayValue: value,
        /** Used for debugging purposes when tracking events. */
        src: 'start',
        filteredOptions: baseFilteredOptions()
    });

    const [loading, setLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setSearchTerm('');
        setCs(x => ({
            trackedValue: value,
            displayValue: value,
            src: 'value-prop-update',
            filteredOptions: baseFilteredOptions(x.filteredOptions)
        }));
    }, [value]);

    //const debugging = false;
    //useEffect(() => {
    //    if (debugging) {
    //        logger.log('DEBUGGER: autocompleteDebugger', cs, configuration, identifier);
    //    }
    //}, [cs]);

    useEffect(() => {
        if (
            configuration.type === 'static'
            && (
                configuration.options.lng !== cs.filteredOptions.lng
                ||  configuration.options.cached !== cs.filteredOptions.cached
            )
        ) {
            sourceOptions.current = defaultSourceOptions();

            setCs(x => {
                let result = {
                    ...x,
                    src: 'config-update',
                    filteredOptions: baseFilteredOptions()
                };

                let filteredOptions = baseFilteredOptions();
                let displayValue = x.displayValue;
                if (displayValue.truthy()) {
                    filteredOptions.o = filteredOptions.o.filter(y => y.code === displayValue.value);
                    if (filteredOptions.o.length > 0) {
                        displayValue.text = filteredOptions.o?.[0]?.value;
                    } else {
                        // when configuiration changes and same value can't be found, clear it.
                        x.displayValue = new InputLayerValue();
                        x.trackedValue = new InputLayerValue();
                    }

                    //if (!selectOnly) {
                    //    result.filteredOptions = filteredOptions;
                    //}
                }
                return result;
            });
        }
    }, [configuration]);

    const emitOnSubmit = () => {
        if (!onSubmit) {
            return;
        }
        onSubmit(undefined, cs.displayValue);
    };

    const debouncedSearch = debounce(async (query: string) => {
        const finish = (newFilteredOptions: AutocompleteOption[]) => {
            if (configuration.type === 'api') {
                debounceLoadingOff(false);
            }
            setCs(
                x => ({
                    ...x,
                    filteredOptions: { ...x.filteredOptions, o: newFilteredOptions },
                    src: 'debounced-search'
                })
            );
        }

        if (configuration.type === 'static') {
            const newFilteredOptions = sourceOptions.current.o.filter(x =>
                x.value.toLowerCase().startsWith(query.toLowerCase())
            );
            finish(newFilteredOptions);
        } else if (query.trim().length < configuration.threshold) {
            // when term length slides under the the threshold just return last known options.
            finish(cs.filteredOptions.o);
        } else {
            let ep = configuration.endpoint();
            // NOTE: add the posibility to specify whether it's post or get 
            // or build this externally all together
            await _http
                .post(configuration.buildRequest(query), ep)
                .json<any>()
                .then(response => {
                    finish(configuration.processResponse(response));
                })
                .catch(() => {
                    finish([]);
                })
        }  
    }, configuration.type === 'static' ? 0 : 100);

    const debounceLoadingOff = debounce(async (change: boolean) => {
        setLoading(change); 
    }, 500);

    const canConfirm = () => {
        return cs.trackedValue?.value !== cs.displayValue?.value;
    }

    useEffect(() => {
        if (configuration.type === 'api') {
            if (searchTerm.length > 0) {
                setLoading(true);
                debouncedSearch(searchTerm);
            } else {
                setLoading(false);
            }
        } else if (configuration.type === 'static') {
            debouncedSearch(searchTerm);
        }
        return () => {
            debouncedSearch.cancel();
            debounceLoadingOff.cancel();
        };
    }, [searchTerm]);

    /** Search term change handler */
    const handleOnChange = (e: any) => {
        const newSearchTerm = e.target.value;
        if (newSearchTerm === searchTerm) {
            return;
        }
        setSearchTerm(newSearchTerm);  
    };

    const clear = () => {
        let newValue = new InputLayerValue();
        if (onValueChange) {
            onValueChange(newValue);
        }
        setCs(() => ({
            trackedValue: newValue,
            displayValue: newValue,
            src: 'clear',
            filteredOptions: baseFilteredOptions()
        }));
    }
    // controls modal state
    const [isOpen, setIsOpen] = useState(false);

    let mainRing = useFocusRing();
    let modalRing = useFocusRing();

    // replicate isFocused to context
    useEffect(() => {
        if (!_cc || !_cc.setIsFocused) {
            return;
        }

        if ((mainRing.isFocused || isOpen) && !_cc.isFocused) {
            _cc.setIsFocused(true);
        } else if (!mainRing.isFocused && !isOpen && _cc.isFocused) {
            _cc.setIsFocused(false);
        }   
    }, [isOpen, mainRing.isFocused, _cc]);

    const openModal = () => {
        if (isOpen) {
            return;
        }
        setIsOpen(() => true);
    }

    let onMainPress = usePress({ onPress: (e) => openModal()});

    return (
        <React.Fragment>
            <div className={`input-control type-autocomplete${cs.displayValue.truthy() && clearable ? ' clearable' : ''}${selectOnly ? ' select-only' : ''}${isOpen || mainRing.isFocused ? ' focused-input' : ''}`}>
                <Input
                    {...onMainPress.pressProps}
                    onKeyUp={(e) => { if (e.key === 'Enter') openModal() }}
                    {...mainRing.focusProps}
                    readOnly={true}
                    type="text"
                    className="search-query"
                    value={cs.displayValue.text}
                    placeholder={t(placeholder?.entry ?? '')}
                />
                {
                    selectOnly
                    && (
                        <AppButton
                            onPress={openModal}
                            className={`toggle-button${false ? ' toggled' : ''}`}
                            type={AppButtonType.Ghost}
                            size={AppButtonSize.Medium}
                            iconOnly={true}
                            title={t(false ? 'Input.Collapse' : 'Input.Expand')}
                        >
                            <AppIconDisplay name="expand" />
                        </AppButton>
                    )
                }
                <Modal UNSTABLE_portalContainer={modalPortal} isOpen={isOpen} onOpenChange={setIsOpen} className="react-aria-Modal cover-w cover-h">
                    <Dialog aria-label="Select an option">
                        {
                            ({ close }) => (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    gap: '1rem'
                                }}>
                                    {
                                        (placeholder?.assistive || !selectOnly)
                                        && (
                                            <div className="flow-message-area">
                                                {
                                                    placeholder?.assistive
                                                    && (
                                                        <AppQueryHeader>
                                                            {t(placeholder.assistive)}
                                                        </AppQueryHeader>
                                                    )
                                                }
                                                {
                                            
                                                    !selectOnly
                                                    && (
                                                        <div className="flow-message-input-area" style={{ flexShrink: '0', position: 'relative' }} >
                                                            <Input
                                                                {...modalRing.focusProps}
                                                                placeholder={
                                                                    cs.trackedValue.text
                                                                        ? cs.trackedValue.text
                                                                        : t(
                                                                            placeholder?.modal
                                                                                ? placeholder?.modal
                                                                                : 'Input.Autocomplete.Placeholder'
                                                                        )
                                                                }
                                                                autoFocus
                                                                style={{
                                                                    borderWidth: 0,
                                                                    padding: '12px 16px',
                                                                    outline: modalRing.isFocused ? '2px solid #00A3B4' : 'none',
                                                                }}
                                                                maxLength={maxLength}
                                                                autoComplete={acid.current}
                                                                type="text"
                                                                value={searchTerm}
                                                                onChange={handleOnChange}
                                                            />
                                                            { 
                                                                loading 
                                                                && (
                                                                    <div style={{ position: 'absolute', right: '12px', top: '16px' }}>
                                                                        <AppLoader size={AppLoaderSize.Small} />
                                                                    </div>
                                                                )                                                                                                                            
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    
                                    <ListBox
                                        aria-label={'search-results'}
                                        className="search-results"
                                        selectionMode="single"
                                        selectedKeys={cs.trackedValue.truthy() ? [cs.trackedValue.value] : []}
                                        style={{
                                            borderWidth: cs.filteredOptions.o.length > 0 ? '1px' : '0',
                                            borderColor: selectOnly ? '#00A3B4' : '#d6e8e5',
                                            marginTop: selectOnly ? '-14px' : '0',
                                            borderTopLeftRadius: selectOnly ? '0' : '10px',
                                        }}
                                        items={cs.filteredOptions.o}
                                        onSelectionChange={(key: Selection) => {
                                            let keyReadable = Array.from(key)[0]
                                            let selectedOption = cs.filteredOptions.o.find(o => o.code === keyReadable);
                                            if (!selectedOption) { 
                                                return;
                                            }   
                                            const trackedValue = new InputLayerValue(selectedOption.code, selectedOption.value, selectedOption.meta);
                                            setCs(x => ({
                                                ...x,
                                                trackedValue,
                                                src: 'on-option-select'
                                            }));
                                            setSearchTerm('');
                                        }}
                                    >
                                        {(item) => <ListBoxItem aria-label={item.value} id={item.code}>{item.value}</ListBoxItem>}
                                    </ListBox>

                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        height: 'auto',
                                        position: 'sticky',
                                        bottom: '0'
                                    }}>
                                        <AppButton
                                            className="cancel-button"
                                            type={AppButtonType.Tertiary}
                                            title={'Cancel'}
                                            onPress={() => {
                                                setCs(x => {
                                                    setSearchTerm('');

                                                    return {
                                                        ...x,
                                                        trackedValue: x.displayValue,
                                                        src: 'cancel',
                                                        filteredOptions: configuration.type === 'api'
                                                            ? baseFilteredOptions()
                                                            : x.filteredOptions
                                                    };
                                                });
                                                close();
                                            }}
                                        >
                                            {t('Input.Autocomplete.Cancel')}
                                        </AppButton>

                                        <AppButton
                                            disabled={!canConfirm()}
                                            className="confirm-button"
                                            title={'Confirm'}
                                            onPress={() => {
                                                setCs(x => {
                                                    const displayValue = x.trackedValue;
                                                    if (onValueChange) {
                                                        onValueChange(displayValue);
                                                    } 

                                                    setSearchTerm('');

                                                    return {
                                                        ...x,
                                                        displayValue,
                                                        src: 'confirm',
                                                        filteredOptions: configuration.type === 'api'
                                                            ? baseFilteredOptions()
                                                            : x.filteredOptions
                                                    };
                                                });
                                                close();
                                            }}
                                        >
                                            {t('Input.Autocomplete.Confirm')}
                                        </AppButton>
                                    </div>
                                </div>
                            )
                        }
                    </Dialog>
                </Modal> 
                {
                    cs.displayValue.truthy() && clearable
                    && (
                        <AppButton
                            className="clear-button"
                            onPress={clear}
                            type={AppButtonType.Ghost}
                            size={AppButtonSize.Medium}
                            iconOnly={true}
                            title={t('Input.Clear')}
                        >
                            <AppIconDisplay name="close" />
                        </AppButton>
                    )
                }
                {
                    !hideSubmit
                    && (
                        <AppButton
                            className="approve-button"
                            onPress={emitOnSubmit}
                            type={AppButtonType.Secondary}
                            iconOnly={true}
                            title={t('Input.Confirm')}
                        >
                            <AppIconDisplay name="send" />
                        </AppButton>
                    )
                }
            </div>
            {
                !_cc?.externalScrollPrevention
                && modalRing.isFocused
                && <PreventScrollMount scrollTarget={scrollTarget} />
            }  
        </React.Fragment> 
    );
}