import './QUOTE.scss'
import React, { useContext, useEffect, useState } from "react";
import { InputLayerValue, InsurerIdMapping, WizardStepProps } from "../../WizardManager.models";
import { WizardManagerService } from "../../WizardManager.service";
import { FlowMessageReadEdit } from "../../WizardManager";
import AppButton from "../../../shared/app-button/AppButton";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import { ApiCalculation } from '../../../../models/Comm.models';
import FlowMessage from '../../flow-message/FlowMessage';
import { Pressable } from '@react-aria/interactions';
import { AppLoader } from '../../../shared/app-loader/AppLoader';
import AppQueryHeader from '../../../shared/app-query-header/AppQueryHeader';
import { useInit } from '../../../../services/useInit';

export const QUOTE_ID = 'QUOTE';

export const QuoteHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [submitValidationRun, setSubmitValidationRun] = useState(false);

    useInit(() => {
        _wmService.initiateQuotationCreation();
    });

    const handlePremiumSelection = (value: InputLayerValue,) => {
        if (!submitValidationRun) { 
            setSubmitValidationRun(true);
        }
        
        // of validation is ok, just bubble up
        _wmService.confirmQuotation(value, node);
    }

    const rootClassNames = `flow-message${!active() ? ' disabled' : ''}`;

    const readableValue = () => {
        let modelValue = _wmService.readModelValue(node.id);
        if (modelValue.truthy()) {
            return t(modelValue.text);
        }
        if (_wmService.quotationState.outdated) {
            return '-';
        }
        if (!_wmService.quotationState.ready) {
            return t('Wizard.QUOTE.InProgress');
        }
        return t('Wizard.QUOTE.Read.Select');
    }
    return (
        <div data-step-id={QUOTE_ID} className={rootClassNames}>   
            <div className={`flow-message-area`}>
            {
                active()
                    ? (
                        <div className="proposal-area">
                            {
                                _wmService.quotationState.outdated
                                ? (
                                    <FlowMessage
                                        stepId={'OUTDATED-QUOTATION'}
                                        queryArea={
                                            <AppQueryHeader>{t('Wizard.QUOTE.Outdated.Title')}</AppQueryHeader>
                                        }
                                        inputArea={() => 
                                            <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
                                                <AppButton onPress={() => _wmService.runOutdatedQuotation()}>
                                                    {t('Wizard.QUOTE.Outdated.Retry')}
                                                </AppButton>
                                            </div>
                                        }
                                        padded={false}
                                    />
                                )
                                : (
                                    <React.Fragment>
                                        {
                                            (_wmService.quotationState?.result ?? []).length === 0
                                            && (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <AppLoader />
                                                </div>
                                            )
                                        }
                                        {
                                            (_wmService.quotationState?.result ?? []).length > 0
                                            && (
                                                <div className="proposal-body">
                                                    {(_wmService.quotationState?.result ?? []).map(x => (
                                                        <QuotationMarkup
                                                            quotation={x}
                                                            key={x.insurerId}
                                                            onSelect={() => handlePremiumSelection(
                                                                new InputLayerValue(
                                                                    x.insurerId.toString(),
                                                                    `\u20AC ${x.premium ?? '-'} (${InsurerIdMapping[x.insurerId]})`
                                                                )
                                                            )}
                                                        />
                                                    ))}
                                                </div>
                                            )
                                        }
                                        {
                                            (
                                                _wmService.quotationState.loading
                                                || (_wmService.quotationState?.result ?? []).length === 0
                                            )
                                            && (
                                                <div className="proposal-footer">
                                                    {t('Wizard.QUOTE.InProgress')}
                                                </div>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </div>
                    )
                    : (
                        <div className="flow-message-read">
                            <div className="flow-message-read-icon">
                                <AppIconDisplay name="proposal" size={AppIconDisplaySize.S} />
                            </div>
                            <div className="flow-message-read-label">
                                {t('Wizard.QUOTE.Read.Query')}
                            </div>
                            <div className="flow-message-read-value">
                                {readableValue()}
                            </div>
                            {
                                editable()
                                && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                            }
                        </div>
                    )
            }        
            </div>
        </div>
    )
}

type QuotationMarkupProps = {
    quotation: ApiCalculation;
    onSelect: () => void;
}

const QuotationMarkup: React.FC<QuotationMarkupProps> = ({ quotation, onSelect }) => {
    const _wmService = useContext(WizardManagerService);

    const selected = () => _wmService.readModelValue(QUOTE_ID).valueAsInt() === quotation.insurerId;
    const selectable = () => (quotation.premium ?? 0) > 0 || _wmService.quotationState.result?.every(x => x.error || !x.premium);
    const splitPremium = () => {
        if (quotation.premium) {
            let sp = quotation.premium.toString().split(/[.,]/);
            if (sp.length == 1) {
                sp.push('00');
            }
            if (sp[1].length == 1) {
                sp[1] += '0';
            }
            return { left: sp[0], right: sp[1] };
        }

        return { left: '', right: '' };
    }

    const progressClasses = () => {
        let result = 'load-progress';

        if (quotation.progress.isCompleted()) {
            result += ' lpb-completed';
        }

        return result;
    }

    const rootClasses = () => {
        let result = 'quotation-result';

        if (_wmService.quotationState.afterEffects) {
            result += ' rendered';
        }

        if (selected()) {
            result += ' selected';
        }

        if (selectable()) {
            result += ' selectable';
        }

        if (quotation.progress.isIdling()) {
            result += ' lpb-idling';
        }

        if (quotation.progress.isPulling()) {
            result += ' lpb-pulling';
        }

        if (quotation.progress.isCompleted()) {
            result += ' lpb-completed';
        }


        return result;
    }

    return (
        <Pressable onPress={() => { selectable() && onSelect() } }>
            <div tabIndex={0} className={rootClasses()}>
                <div className="main">
                    <div className="provider">{InsurerIdMapping[quotation.insurerId]}</div>
                    <div className={`price${!!quotation.premium && quotation.cheapest ? ' cheapest' : ''}`}>
                        {
                            quotation.premium
                                ? (
                                    <React.Fragment>
                                        {
                                            !quotation.progress.isIdling()
                                            && (
                                                <React.Fragment>
                                                    <div className="price-sign">
                                                        {'\u20AC '}
                                                    </div>
                                                    <div className="price-primary">
                                                        {splitPremium().left}.
                                                    </div>
                                                    <div className="price-secondary">
                                                        {splitPremium().right}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }

                                    </React.Fragment>
                                )
                                : (
                                    <React.Fragment>
                                        {
                                            quotation.failed
                                            && (
                                                <div className="price-primary">
                                                    -
                                                </div>
                                            )
                                        }

                                    </React.Fragment>
                                )
                        }
                    </div>

                    <div className={progressClasses()}>
                        <div className="load-progress-bar-track">
                            <div className="load-progress-bar" style={{ width: `${quotation.progress.completionPercent()}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </Pressable>
    )
}