import { Pressable } from '@react-aria/interactions';
import './AppTogglebox.scss';
import React, { useEffect, useState } from "react";

export type AppToggleBoxProps = {
    toggled: boolean;
    onToggleChange: (value: boolean) => void;
    disabled?: boolean;
}

const AppToggleBox: React.FC<AppToggleBoxProps> = ({
    toggled,
    onToggleChange,
    disabled
}) => {
    const [trackedValue, setTrackedValue] = useState(toggled);

    useEffect(() => {
        setTrackedValue(toggled);
    }, [toggled]);

    const toggleIt = () => {
        if (disabled) {
            return;
        }
        const newValue = !trackedValue;
        setTrackedValue(newValue);
        onToggleChange(newValue);
    }

    return (
        <Pressable onPress={toggleIt}>
            <div className={`app-togglebox${trackedValue ? ' at-on' : ' at-off'}${disabled ? ' at-disabled' : ''}`}>
                <div className={`app-togglebox-dot`}>
                </div>
            </div>
        </Pressable>
    );
}

export default AppToggleBox;