// **************************************************************************************
// Communication models. Back-end request signatures and back-end response signatures.
// **************************************************************************************

import { CodeListName, CodeValue } from "./App.models";

/** Request to issue a policy */
export type IssuePolicyRequest = {
    /** Insurer identifier */
    insurerId: number;
    /** Policy holder information */
    holder?: {
        /**  Policy holder first name */
        name?: string;
        /** Policy holder last name */
        lastName?: string;
        /** Policy holder telephone number */
        phone?: string;
        /** Policy holder e-mail address */
        email?: string;
        /** Any of the following address identifiers
         * - Policy holder address village
         * - Policy holder address street
         * - Policy holder address House
         * - policy holder address flat
         */
        addressId?: number;
    };
}

/** Reponse to issue policy request */
export type IssuePolicyResponse = {
    /** Policy identifier */
    id: string;
}

/** Policy information */
type ApiPolicy = {
    /** Policy premium */
    premium: number;
    /** Represent either proposal or policy number. */
    number?: string;
    /** Indicates that proposal/policy issued successfully. */
    success: boolean;
    /** Indicates that proposal/policy processing is complete. */
    ready: boolean;
    /** Policy holder */
    holder: {
        email: string;
    };
}

/** Get policy response */
export type GetPolicyResponse = ApiPolicy;

/** Policy document */
export type ApiPolicyDocument = {
    /**  Policy document identifier */
    id: string;
    /** Policy document type */
    type: PolicyDocumentType;
    /** Content type */
    contentType: string;
    /** Document size */
    size: number;

    /** UI - Blob that is side-loaded by UI */
    blob?: Blob;
    /** UI - Indicates whether blob was loaded */
    blobReady?: boolean;
    /** UI - Indicates whether blob was loaded OK or NOK */
    blobSuccess?: boolean;
}

/** Policy documents */
type ApiPolicyDocuments = {
    /** Policy documents */
    documents: ApiPolicyDocument[];
    /** Indicator that policy documents are available */
    ready: boolean;
}

/** Response to get policy documents request */
export type GetPolicyDocumentsResponse = ApiPolicyDocuments;

/** Insured vehicle information */
type ApiVehicle = {
    /** Vehicle plate number */
    number: string;
}

/** Quotation creation request */
export type CreateQuotationRequest = {
    /** Insurer identifier */
    insurerId?: number;
    /** Policy holder information */
    holder: {
        /** Personal identification code */
        code: string;
        /** Driving license issue date */
        licence?: string;
    },
    /** Insured vehicle information */
    vehicle: ApiVehicle;
    /** Residential area identifier */
    residentialAreaId?: number | null;
    /** Less experienced driver's driving experience code from driver experience code list */
    experience?: string;
    /** Youngest driver age code from driver age code list */
    age?: string;
    /** Policy start date */
    start: string;
    /** Policy duration in months */
    duration?: number | null;
    /** Additional risk identifier. [0,null,undefined] = Personal use (default) */
    risk?: VehicleUseRisk | null;
}

/** Encoded insurer error */
export enum InsurerError {
    None = 0,
    /** Theoretically should not be returned for the calculation endpoints */
    FunctionalityNotImplemented = 1,
    /** No special handling can be treated as unknown insurer error */
    MandatoryAttributeMissing = 2,
    /** User already have policy */
    ActiveVehicleInsurance = 3,
    /** Was unable to look up address */
    AddressLookupFailed = 4,
    /** Calculation request timeouted */
    Timeouted = 5,
    /** Vehicle is neither owned nor leased by the policyholder */
    VehicleNotOwned = 6,
    /** Insurer was unable to retrieve vehicle information */
    VehicleInformationUnavailable = 7
} 

export enum ApiCalculationProgressStatus {
    /** 
    * Indicates that the progress bar is moving somewhere in the 0 -> 80 percent range (see statusPercentCeiling).
    * Premium not shown.
    */
    Idling = 1,

    /** 
    * Indicates that the progress bar is somewhere in the n -> 100 percent range. 
    * But it will reach 100 under 1 second becaause it's being actively pulled towards it.
    * Premium not shown.
    */
    Pulling = 2,

    /**
    * Indicates that the progress bar is hidden and the animation staging is complete.
    * Premium shown.
    */
    Completed = 3
}

export class ApiCalculationProgress {
    /** Start time (epoch) */
    private startTime = 0;
    /** Estimated duration of calculation */
    private maxDuration = 0;
    /** Current duration A.K.A. completion rate (%) */
    private duration = 0;
    /** Status */
    status = ApiCalculationProgressStatus.Idling
    /** Last updated */
    private lastUpdated = 0;

    constructor() {
        /** Max duration in seconds */
        const ceiling = 180;
        /** Min duration in seconds */
        const floor = 60;
        // calculate random number between ceiling and floor.
        this.maxDuration = Math.floor(Math.random() * (ceiling - floor + 1)) + floor;
        // write down start time
        this.lastUpdated = this.startTime = Date.now();
    }

    update = () => {   
        if (!this.isIdling()) {
            // lock to 100 completion
            this.duration = 100;
        } else {
            /** Locking boundary for max possible completiion %*/
            const statusPercentCeiling = 80;
            /** How long has the progress lasted currently*/
            const currentDuration = Math.floor((Date.now() - this.startTime) / 1000);
            /** % complete */
            let currentDurationInPercent = currentDuration / this.maxDuration * 100;
            // lock it in place of ceiling if it exceeds it
            if (currentDurationInPercent > statusPercentCeiling) {
                currentDurationInPercent = statusPercentCeiling;
            }
            this.duration = currentDurationInPercent;
        } 

        this.lastUpdated = Date.now();
    }

    /** Whether progress bar slowly progressing to "statusPercentCeiling". */
    isIdling = () => {
        return this.status === ApiCalculationProgressStatus.Idling;
    }

    /** Whether progress bar is currently in step where it's skipped to the end. */
    isPulling = () => {
        return this.status === ApiCalculationProgressStatus.Pulling;
    }

    /** Whether progress bar has completed its length. */
    isCompleted = () => {
        return this.status === ApiCalculationProgressStatus.Completed;
    }

    idleIt = () => {
        this.status = ApiCalculationProgressStatus.Idling;
        this.lastUpdated = Date.now();
    }

    pullIt = () => {
        this.status = ApiCalculationProgressStatus.Pulling;
        this.lastUpdated = Date.now();
    }

    completeIt = () => {
        this.status = ApiCalculationProgressStatus.Completed;
        this.lastUpdated = Date.now();
    }

    lastChanged = () => {
        return this.lastUpdated;
    }

    completionPercent = () => {
        return this.duration;
    }
}

/** Calculated insurer quotation */
export type ApiCalculation = {
    /** Insurer identifier. Decoded with 'InsurerIdMapping' */
    insurerId: number;
    /** Calculated premium */
    premium?: number;
    /** Encoded insurer error */
    error?: InsurerError;
    /** UI - Indicates that this calculation is the cheapest of the bunch */
    cheapest?: boolean;
    /** UI */
    progress: ApiCalculationProgress;
    /** UI */
    failed: boolean;
}

/** Policy holder information */
type ApiVehicleOwnerInformation = {
    /** Indicator that policy holder is vehicle owner*/
    holder: boolean;
    /** Indicator that policy holder's driver licence issue date is known*/
    licence: string;
}

/** Insured vehicle information */
export type ApiVehicleInformation = {
    /** Vehicle plate number */
    plateNumber: string;
    /** Vehicle make */
    make?: string;
    /** Vehicle model */
    model?: string;
    /** Vehicle manufacturing year */
    year?: number;
    /** Vehicle owner */
    owner?: ApiVehicleOwnerInformation;
}

/** Quotation */
type ApiQuotation = {
    /** Vehicle owner */
    vehicle?: ApiVehicleInformation;
    /** Indicator that all premiums were calculated and vehicle information was retrieved */
    ready: boolean;
    /** Calculated premiums */
    calculations?: ApiCalculation[];
}

/** Quotation creation response */
export type CreateQuotationResponse = ApiQuotation & {
    /** Quotation identifier */
    id: string;
}

/** Get quotation response */
export type GetQuotationResponse = ApiQuotation;

/** API call type */
export enum ApiCallType {
    /** Unknown */
    Unknown = 0,
    /** Calculate premium */
    CalculatePremium = 1,
    /** Issue proposal */
    IssueProposal = 2,
    /** Issue policy */
    IssuePolicy = 3,
    /** Get policy documents */
    GetDocuments = 4,
    /** Download policy document */
    DownloadDocument = 5,
    /** Create an invoice */
    CreateInvoice = 6,
    /** Get code lists */
    GetCodeLists = 7,
    /** Authorization */
    Authentication = 8,
    /** Get vehicle information */
    GetVehicleInfo = 9,
    /** Issue Green Card */
    IssueGreenCard = 10,
    /** Get policy holder information */
    GetPolicyHolder = 11,
    /** Create or update policy holder information */
    RegisterPolicyHolder = 12,
    /** Look-up address */
    LookupAddress = 13
}

/** Policy issue process action type */
export enum ActionType {
    /** Unknown */
    Unknown = 0,
    /** Calculate policy premium */
    CaclulatePremium = 1,
    /** Issue policy */
    IssuePolicy = 2,
    /** Get policy document */
    GetDocuments = 3,
    /** Get vehicle information */
    GetVehicleInformation = 4
}

/** Policy document type */
export enum PolicyDocumentType {
    /** Contract */
    Contract = 1,
    /** Policy */
    Policy = 2,
    /** Green card */
    GreenCard = 3,
    /** Short information document */
    ShortInformationDocument = 4,
    /** Terms & Conditions */
    TermsAndConditions = 5,
    /** Invoice */
    Invoice = 6,
    /** Any other not classified document provided by the insurer */
    Other = 999
}

/** Risk associated with vehicle use */
export enum VehicleUseRisk {
    /** Personal use (Standard riks) */
    PersonalUse = 0,
    /** Paid passenger or freight transportation (Bolt, Uber, etc.) */
    PassengerOrFreightTransportation = 1,
    /** Sort or long term vehicle rental */
    Rental = 2,
    /** Driving lessions*/
    DrivingLessons = 3,
    /** Other( dangerous cargo, security servicies, and other) */
    Other = 4
}

/** Payment info */
export type PaymentInfo = {
    /** Policy identifier */
    policyExternalId: string;
    /** Default locale (uppercase language code in ISO 639-1 format) */
    defaultLocale?: string;
}

export type PaymentInitiationResponse = {
    dataToken?: string;
    paymentAlreadyProcessing: boolean;
}

export type PaymentCallbackContext = {
    token: string,
    isSuccessToken: boolean,
    paymentStatus?: PaymentStatus | null
}

export enum PaymentStatus {
    None = 'None',
    Pending = 'Pending',
    Canceled = 'Canceled',
    Success = 'Success'
}

export type PaymentClientSideCallbackResponse = {
    policyId: string;
    paymentStatus: PaymentStatus;
}

export type GetPolicyPaymentStatusResponse = {
    paymentStatus: PaymentStatus;
}

export type GetCodeListsResponse = {
    [key in CodeListName]: CodeValue[]
}

export type CreateSessionRequest = {
    /** Authorization code (Optional). Max length: 20. Is required only for environments where "promo code" authorization is enabled. */
    code?: string;
}
