import './Home.scss';
import React, { useContext, useRef, useState }  from "react";
import AppRoutePage from "../shared/app-route-page/AppRoutePage";
import FlowMessage, { FlowMessageAssistanceRecordType } from '../flow-manager/flow-message/FlowMessage';
import { TextInput } from '../shared/app-text-input/AppTextInput';
import { Trans, useTranslation } from 'react-i18next';
import { InputLayerValue, InsurerIdMapping } from '../flow-manager/WizardManager.models';
import AppEmphasizedText, { AppEmphasizedTextTheme, AppEmphasizedTextWeight } from '../shared/app-emphasized-text/AppEmphasizedText';
import AppIconDisplay from '../shared/app-icon-display/AppIconDisplay';
import AppButton, { AppButtonSize, AppButtonType } from '../shared/app-button/AppButton';
import { shuffleArray } from '../../services/Util';
import { useNavigate } from 'react-router-dom';
import { AppService } from '../../App';
import { Pressable } from '@react-aria/interactions';
import { PdfType } from '../pdf-viewer/PdfViewer';
import AppQueryHeader from '../shared/app-query-header/AppQueryHeader';

const Home: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sessionResolved, buildSession, showPdfViewer} = useContext(AppService);
    const [buildingSession, setBuildingSession] = useState(false);
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const promoValue = useRef(new InputLayerValue());
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (value: InputLayerValue) => {
        const results = [];
        const x: string = value.value;
        if (x.length === 0) {
            results.push('Validation.Mandatory');
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (submitValidationRun) {
            setDisableSubmit(validate(data).length !== 0);
        }
    };

    const handleSubmit = (data: InputLayerValue) => {
        if (buildingSession) {
            return;
        }

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }

        if (validate(data).length === 0) {
            setBuildingSession(true);
            buildSession(true, data.value)
                .catch(() => setValidationResults(['Landing.Welcome.Promo.InvalidCode']))
                .finally(() => setBuildingSession(false));
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    const socials = () => {
        return (
            <div className="socials-list">
                <AppButton
                    className="social-button"
                    type={AppButtonType.Ghost}
                    size={AppButtonSize.Medium}
                    onPress={() => navToSocial('https://www.facebook.com/manramu.lt')}
                    iconOnly={true}
                    title={'Visit Facebook'}
                >
                    <AppIconDisplay name="facebook" />
                </AppButton>
                <AppButton
                    className="social-button"
                    type={AppButtonType.Ghost}
                    size={AppButtonSize.Medium}
                    onPress={() => navToSocial('https://www.instagram.com/manramu.lt')}
                    iconOnly={true}
                    title={'Visit Instagram'}
                >
                    <AppIconDisplay name="instagram" />
                </AppButton>
                <AppButton
                    className="social-button"
                    type={AppButtonType.Ghost}
                    size={AppButtonSize.Medium}
                    onPress={() => navToSocial('https://www.linkedin.com/company/manramu')}
                    iconOnly={true}
                    title={'Visit LinkedIn'}
                >
                    <AppIconDisplay name="linkedin" />
                </AppButton>
            </div>
        );
    }

    const navToSocial = (url: string) => {
        window.open(url, '_blank')
    }

    const partners = useRef(
        shuffleArray(
            Object
                .values(InsurerIdMapping)
                .map(x => x.replace(/\s/g, ''))
        )
    );

    const tryAccess = () => {
        navigate('/insure');
    }

    /** Reference to body DOM */
    const homeBodyRef = useRef<HTMLDivElement>(null);

    return (
        <AppRoutePage
            id="page-home"
            pageBodyRef={homeBodyRef}
        > 
            <img alt="" className="bubble-one" src={require(`./bubble-one.png`)} />
            <img alt="" className="bubble-two" src={require(`./bubble-two.png`)} />
            <img alt="" className="bubble-three" src={require(`./bubble-three.png`)} />

            <div className="welcome-area">
                <div className="w-title">
                    {t('Landing.Welcome.Title')}
                </div>

                {
                    sessionResolved
                        ? (
                            <React.Fragment>
                                <div style={{ display: 'flex', justifyContent: 'center', height: '135px', alignItems: 'center' }}>
                                    <AppButton onPress={() => { tryAccess() }}>
                                        {t('Landing.Welcome.Promo.AlreadySelected.Confirm')}
                                    </AppButton>
                                </div>

                                <div className="w-note" style={{ height: '44px',}}>
                                    {t('Landing.Welcome.Promo.AlreadySelected.Note')}
                                </div>
                            </React.Fragment>
                        )
                        : (
                            <React.Fragment>
                                <FlowMessage
                                    stepId={'PROMO-ENTRY'}
                                    queryArea={
                                        <AppQueryHeader>{t('Landing.Welcome.Promo.Header')}</AppQueryHeader>
                                    }
                                    inputArea={() => 
                                        <TextInput
                                            onValueChange={handleInputChange}
                                            placeholder={'Landing.Welcome.Promo.Placeholder'}
                                            onSubmit={(x, y) => handleSubmit(y)}
                                            disableSubmit={disableSubmit}
                                            value={promoValue.current}
                                            scrollTarget={homeBodyRef.current}
                                            maxLength={6}
                                        />
                                    }
                                    padded={false}
                                    assistanceRecords={validationResults.map(vr => ({ key: vr, type: FlowMessageAssistanceRecordType.Error}))}
                                    withConfirm={true}
                                />
                                <div className="w-note">
                                    <Trans
                                        i18nKey={'Landing.Welcome.Promo.Note'}
                                        values={{ email: 'info@manramu.lt' }}
                                        components={{
                                            emp: <AppEmphasizedText weight={AppEmphasizedTextWeight.Normal} theme={AppEmphasizedTextTheme.Green} />
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        )
                }
                

                <div className="w-socials">
                    {t('Landing.More')}
                    {socials()}
                </div>
            </div>

            <div className="partner-area">
                <div className="pa-title" id="partnerlist">
                    {t('Landing.Partners.Title')}
                </div>
                <div className="pa-partners">
                    {
                        partners.current.map(p => {
                            return (
                                <div className="partner" key={p}>
                                    <div className="p-logo">
                                        <img alt="Logo" src={require(`./${p}-logo.png`)} />
                                    </div>
                                    <div className="p-title">{t(`Partner.${p}.Title`)}</div>
                                    <div className="p-address">{t(`Partner.${p}.Address`)}</div>
                                    <div className="p-phone">{t(`Partner.${p}.Phone`)}</div>
                                    <div className="p-email">
                                        <Trans
                                            i18nKey={`Partner.${p}.EmailContainer`}
                                            values={{ email: t(`Partner.${p}.Email`) }}
                                            components={{
                                                emp: <AppEmphasizedText weight={AppEmphasizedTextWeight.Normal} theme={AppEmphasizedTextTheme.Green} />
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="footer-area">
                <div className="logo svg-logo-green">
                </div>

                <div className="legal">
                    <div className="company">
                        {t('Landing.Legal.Company')}
                    </div>
                    <div className="code">
                        {t('Landing.Legal.Code')}
                    </div>
                    <div className="vat">
                        {t('Landing.Legal.Vat')}
                    </div>
                    <div className="address">
                        {t('Landing.Legal.Address')}
                    </div>
                </div>

                <div className="links">
                    <div className="links-title">
                        {t('Landing.Links.Title')}
                    </div>
                    <a href="#partnerlist">
                        <div className="links-item clickable">
                            {t('Landing.Links.Companies')}
                        </div>
                    </a>
                    <Pressable onPress={() => { showPdfViewer(PdfType.CustomerInformation); }}>
                        <div className="links-item clickable">
                            {t('Landing.Links.Customer')}
                        </div>
                    </Pressable>
                    <Pressable onPress={() => { showPdfViewer(PdfType.TermsAndConditions); }}>
                        <div className="links-item clickable">
                            {t('Landing.Links.Terms')}
                        </div>
                    </Pressable>
                    <Pressable onPress={() => { showPdfViewer(PdfType.CookiePolicy); }}>
                        <div className="links-item clickable">
                            {t('Landing.Links.Cookies')}
                        </div>
                    </Pressable>
                    <Pressable onPress={() => { showPdfViewer(PdfType.PrivacyPolicy); }}>
                        <div className="links-item clickable">
                            {t('Landing.Links.Privacy')}
                        </div>
                    </Pressable>
                </div>

                <div className="contacts">
                    <div className="contacts-title">
                        {t('Landing.Contacts.Title')}
                    </div>
                    <div className="phone">
                        {t('Landing.Contacts.Phone')}
                    </div>
                    <div className="email">
                        {t('Landing.Contacts.Email')}
                    </div>
                </div>

                {socials()}

                <div className="rights">
                    {t('Landing.Footer.Rights')}
                </div>
            </div>
        </AppRoutePage>
    );
}

export default Home;