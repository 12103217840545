import { _http } from "../../../../App";
import logger from "../../../../services/Util";

type Address = {
    /** Address identifier */
    id: number;
    /** House number */
    number: string;
    /** Housing (korpus) number */
    housingNumber?: string;
    rooms: boolean;
    streets: boolean;
    addresses: boolean;
}

class CacheIdentifierContainer {
    /** Cache store */
    private items: Map<number, Address[]> = new Map();

    /** Get address list by id */
    getAddresses(id: number): Address[] | undefined {
        return this.items.get(id);
    }

    /** Add address list for id  */
    addAddresses(id: number, addresses: Address[]): void {
        this.items.set(id, addresses);
    }
}

class AddressCache {
    protected cache = new CacheIdentifierContainer();

    constructor(
        private urlTemplate: string
    ) { }

    /** Get address list by id. Caches results. */
    getAddresses(id: number): Promise<Address[]> {
        const existingCacheItem = this.cache.getAddresses(id);
        if (existingCacheItem) {
            return Promise.resolve(existingCacheItem);
        }
        let url = this.urlTemplate.replace('{id}', id.toString());
        return _http
            .get(url)
            .json<any>()
            .catch(error => {
                logger.error(`DEBUGGER: Was unable to retrieve address list by id #${id} from #${url} `, { details: error });
                return { addresses: [] };
            })
            .then(response => {
                const addresses = response.addresses ?? [];
                this.cache.addAddresses(id, addresses);
                return addresses;
            });
    }
}

export class ResidentialAreaAddressCache extends AddressCache {
    constructor() {
        super('/api/address/residentialArea/{id}/address');
    }
}

export class StreetAddressCache extends AddressCache {
    constructor() {
        super('/api/address/street/{id}/address');
    }
}