import 'react-day-picker/dist/style.css';
import './AppCalendarInput.scss';
import React, { ChangeEventHandler, useContext, useEffect, useState } from "react";
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { format, isValid, parse } from 'date-fns';
import { InputLayer, InputLayerValue, DT_FORMAT } from "../../flow-manager/WizardManager.models";
import AppButton, { AppButtonSize, AppButtonType } from "../app-button/AppButton";
import AppIconDisplay from "../app-icon-display/AppIconDisplay";
import { useTranslation } from "react-i18next";
import { lt, enUS } from 'date-fns/locale';
import { Dialog, Input, Modal } from "react-aria-components";
import { useFocusRing } from "../../../services/useFocusRing";
import { PreventScrollMount } from '../../../services/usePreventScroll';
import { InputControlContext } from '../AppInputControlContext';

export type CalendarInputLayer = InputLayer & {
    dayPickerProps?: any;
    modalPortal?: Element;
    scrollTarget?: HTMLElement | null;
}

const getDateFromString = (stringDate: string) => {
    const date = parse(stringDate, DT_FORMAT, new Date());
    if (isValid(date)) {
        return date;
    }
    return undefined;
}

export const CalendarInput: React.FC<CalendarInputLayer> = ({
    onValueChange,
    value,
    onSubmit,
    disableSubmit,
    dayPickerProps,
    modalPortal = document.body,
    scrollTarget = document.documentElement,
}) => {
    const _cc = useContext(InputControlContext);

    const { t, i18n } = useTranslation();

    const [selected, setSelected] = useState<Date | undefined>(getDateFromString(value.value));
    const [inputValue, setInputValue] = useState<string>(value.value);

    // controls modal state
    const [isOpen, setIsOpen] = useState(false);

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        let val = e.target.value;
        let prevVal = inputValue;

        // consider only additive changes for replacement
        if (val > prevVal) {
            val = val.replace(/\D/g, '');

            if (val.length >= 4) {
                val = val.slice(0, 4) + '-' + val.slice(4);
            }
            if (val.length >= 7) {
                val = val.slice(0, 7) + '-' + val.slice(7, 9);
            }

            e.target.value = val;
        }
        
        setInputValue(() => val);
        controlValueChange(new InputLayerValue(val));

        const date = parse(val, DT_FORMAT, new Date());
        if (isValid(date)) {
            setSelected(() => date);
        } else {
            setSelected(() => undefined);
        }
    };

    const handleDaySelect: SelectSingleEventHandler = (date) => {
        setSelected(() => date);
    };

    const confirmDaySelection = (closeModal: () => void) => {
        if (selected) {
            const formatted = format(selected, DT_FORMAT);
            setInputValue(() => formatted);
            controlValueChange(new InputLayerValue(formatted));
        } else {
            setInputValue(() => '');
            controlValueChange(new InputLayerValue());
        }

        closeModal();
    }

    const cancelDaySelection = (closeModal: () => void) => {
        setSelected(getDateFromString(inputValue));
        closeModal();
    }

    const dayPickerLocale = () => {
        if (i18n.resolvedLanguage === 'lt') {
            return lt;
        }
        return enUS;
    }

    const emitOnSubmit = () => {
        if (disableSubmit || !onSubmit) {
            return;
        }
        
        onSubmit(undefined, new InputLayerValue(inputValue));
    };

    const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            emitOnSubmit();
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        let val = e.clipboardData.getData('text/plain');
        val = val.replace(/\D/g, '');

        if (val.length >= 4) {
            val = val.slice(0, 4) + '-' + val.slice(4);
        }
        if (val.length >= 7) {
            val = val.slice(0, 7) + '-' + val.slice(7, 9);
        }

        (e.target as any).value = val;

        setInputValue(() => val);
        controlValueChange(new InputLayerValue(val));

        const date = parse(val, DT_FORMAT, new Date());
        if (isValid(date)) {
            setSelected(() => date);
        } else {
            setSelected(() => undefined);
        }
    };

    const controlValueChange = (newValue: InputLayerValue) => {
        if (!onValueChange) {
            return;
        }
        onValueChange(newValue);
    }

    const clear = () => {
        let newValue = new InputLayerValue();
        setInputValue(() => newValue.value);


        setSelected(() => undefined);
    }

    let { isFocused, focusProps } = useFocusRing();
    
    // replicate isFocused to context
    useEffect(() => {
        if (!_cc || !_cc.setIsFocused) {
            return;
        }
        if ((isFocused || isOpen) && !_cc.isFocused) {
            _cc.setIsFocused(true);
        } else if (!isFocused && !isOpen && _cc.isFocused) {
            _cc.setIsFocused(false);
        }      
    }, [isFocused, isOpen, _cc]);

    const openModal = () => {
        if (isOpen) {
            return;
        }
        setIsOpen(() => true);
    }

    const canConfirm = () => {
        return selected?.toDateString() !== getDateFromString(inputValue)?.toDateString();
    }

    return (
        <React.Fragment>
            <div className={`input-control type-calendar${isFocused || isOpen ? ' focused-input' : ''}`}>
                <Input
                    {...focusProps}
                    autoFocus
                    type="text"
                    maxLength={10}
                    className="search-query"
                    placeholder={t('Input.Datepicker.Placeholder', { format: format(new Date(), DT_FORMAT) })}
                    value={inputValue}
                    onKeyDown={handleOnKeyDown}
                    onKeyUp={handleOnKeyUp}
                    onChange={handleInputChange}
                    onPaste={handleOnPaste}
                />
                {
                    !!inputValue
                    && (
                        <AppButton
                            className="clear-button"
                            onPress={clear}
                            type={AppButtonType.Ghost}
                            size={AppButtonSize.Medium}
                            iconOnly={true}
                            title={t('Input.Clear')}
                        >
                            <AppIconDisplay name="close" />
                        </AppButton>
                    )
                }

                <AppButton
                    className="calendar-button"
                    onPress={openModal}
                    type={AppButtonType.Ghost}
                    iconOnly={true}
                    size={AppButtonSize.Medium}
                    title={t('Input.Datepicker.Pick')}
                >
                    <AppIconDisplay name="calendar-light" />
                </AppButton>

                <Modal UNSTABLE_portalContainer={modalPortal} isOpen={isOpen} onOpenChange={setIsOpen} className="react-aria-Modal auto">
                    <Dialog aria-label="Select a date">
                        {
                            ({ close }) => (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem'} }>
                                    <DayPicker
                                        initialFocus={isOpen}
                                        fixedWeeks
                                        mode="single"
                                        showOutsideDays
                                        selected={selected}
                                        onSelect={handleDaySelect}
                                        defaultMonth={selected}
                                        locale={dayPickerLocale()}
                                        weekStartsOn={1}
                                        {...(dayPickerProps ?? {})}
                                    />
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                    }}>
                                        <AppButton
                                            className="cancel-button"
                                            type={AppButtonType.Tertiary}
                                            title={'Cancel'}
                                            onPress={() => cancelDaySelection(close)}
                                        >
                                            {t('Input.Datepicker.Cancel')}
                                        </AppButton>
                                        <AppButton
                                            disabled={!canConfirm()}
                                            className="confirm-button"
                                            title={'Confirm'}
                                            onPress={() => confirmDaySelection(close)}
                                        >
                                            {t('Input.Datepicker.Confirm')}
                                        </AppButton>
                                    </div>
                                </div>
                            )
                        }
                    </Dialog>
                </Modal>

                <AppButton
                    className="approve-button"
                    onPress={emitOnSubmit}
                    type={AppButtonType.Secondary}
                    iconOnly={true}
                    title={t('Input.Confirm')}
                >
                    <AppIconDisplay name="send" />
                </AppButton>
            </div>
            {
                !_cc?.externalScrollPrevention
                && isFocused
                && <PreventScrollMount scrollTarget={scrollTarget} />
            } 
        </React.Fragment>
    );
}