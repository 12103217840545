import './PHONE.scss'
import React, { RefObject, useState } from "react";
import { InputLayerValue, WizardStepProps } from "../../WizardManager.models";
import { TextInput } from "../../../shared/app-text-input/AppTextInput";
import { FlowMessageReadEdit } from "../../WizardManager";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../../shared/app-query-header/AppQueryHeader";
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../../flow-message/FlowMessage';
import AppCheckbox from '../../../shared/app-checkbox/AppCheckbox';

export const PHONE_ID = 'PHONE';
export const PHONE_CONSENT_ID = 'PHONE.CONSENT';

export const PhoneHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, hidden, readValue } = useWizardStep(node, isLast);
    const { t } = useTranslation();
    const [stepValue, setStepValue] = useState(_wmService.readModelValue(node.id));
    const [consentValue, setConsentValue] = useState(false);
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [validationResults, setValidationResults] = useState([] as string[]);

    const validate = (value: InputLayerValue) => {
        const results = [];
        const x = value.value;
        if (x.length === 0) {
            results.push('Validation.Mandatory');
        } else if (!/^(?:\+3706|\(8 6\)|86)\d{7}$/.test(x)) {
            results.push('Validation.InvalidPhoneNumber');
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (submitValidationRun) {
            setDisableSubmit(validate(data).length !== 0);
        }      
    };

    const handleConsentChange = (value: boolean) => {
        setConsentValue(value);

        // I know this is odd... For now input layer doesn't work with generic value types, it is supposed to handle only string values, that's why this nonsense.
        _wmService.handleStepValueChange(new InputLayerValue(value ? 'TRUE' : 'FALSE', value ? 'YES' : 'NO'), true, node, true, undefined, PHONE_CONSENT_ID);
    }
    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        setStepValue(value);

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }
        
        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            // of validation is ok, just bubble up
            _wmService.handleStepValueChange(value, true, node, false)
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    return (
        <React.Fragment>
             {
                !hidden()
                && (
                    <React.Fragment>
                        {
                            active()
                                ? (
                                    <FlowMessage
                                        stepId={PHONE_ID}
                                        queryArea={
                                            <AppQueryHeader>{t('Wizard.PHONE.Edit.Query')}</AppQueryHeader>
                                        }
                                        inputArea={() => 
                                            <TextInput
                                                onValueChange={handleInputChange}
                                                placeholder={'Wizard.PHONE.Edit.Placeholder'}
                                                onSubmit={(x, y) => handleSubmit(x, y)}
                                                disableSubmit={!active() && disableSubmit}
                                                value={stepValue}
                                                maxLength={12}
                                                type={'tel'}
                                                scrollTarget={_wmService.flowBodyRef.current}
                                            />
                                        }
                                        addArea={
                                            <div className="flow-message-consent-area">
                                                <AppCheckbox checked={consentValue} onCheckedChange={handleConsentChange}>
                                                   {t('Wizard.PHONE.Consent')}
                                                </AppCheckbox>
                                            </div>
                                        }
                                        assistanceRecords={validationResults.map(x => ({ key: x, type: FlowMessageAssistanceRecordType.Error } as FlowMessageAssistanceRecord))}
                                        withConfirm={true}
                                    /> 
                                )
                                : (
                                    <div data-step-id={PHONE_ID} className={`flow-message disabled`}>
                                        <div className={`flow-message-area`}>
                                            <div className="flow-message-read">
                                                <div className="flow-message-read-icon">
                                                    <AppIconDisplay name="phone" size={AppIconDisplaySize.S} />
                                                </div>
                                                <div className="flow-message-read-label">
                                                    {t('Wizard.PHONE.Read.Query')}
                                                </div>
                                                <div className="flow-message-read-value">
                                                    {readValue()}
                                                </div>
                                                {
                                                    editable()
                                                    && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </React.Fragment>
                )
            }
        </React.Fragment>

    )
}