import './GetDocuments.scss';
import React, { RefObject, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputLayerValue } from "../flow-manager/WizardManager.models";
import AppRoutePage from "../shared/app-route-page/AppRoutePage";
import { TextInput } from "../shared/app-text-input/AppTextInput";
import PolicyDocuments from './policy-documents/PolicyDocuments';
import { validate as uuidValidate } from 'uuid';
import AppButton  from '../shared/app-button/AppButton';
import FlowMessage, { FlowMessageAssistanceRecord, FlowMessageAssistanceRecordType } from '../flow-manager/flow-message/FlowMessage';
import AppQueryHeader from '../shared/app-query-header/AppQueryHeader';

const GetDocuments: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const [initialized, setInitialized] = useState({ ready: false, error: false, policyIdentifier: '' });
    const [submitValidationRun, setSubmitValidationRun] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [entryInProgress, setEntryInProgress] = useState(true);
    const [validationResults, setValidationResults] = useState([] as FlowMessageAssistanceRecord[]);
    const [personalCodePart, setPersonalCodePart] = useState(new InputLayerValue());

    useEffect(() => {
        let policyIdentifier = id ?? '';
        setInitialized({
            ready: true,
            error: !uuidValidate(policyIdentifier),
            policyIdentifier
        });
    }, [id]);

    const validate = (value: InputLayerValue) => {
        const results: FlowMessageAssistanceRecord[] = [];
        const x: string = value.value;
        if (x.length === 0) {
            results.push({ key: 'Validation.Mandatory', type: FlowMessageAssistanceRecordType.Error });
        } else if (x.length < 4) {
            results.push({ key: 'Validation.ExactlyNCharacters', params: { n: 4 }, type: FlowMessageAssistanceRecordType.Error });
        } else if (!/\d/.test(x)) {
            results.push({ key: 'Validation.OnlyDigits', type: FlowMessageAssistanceRecordType.Error });
        }

        setValidationResults(results);
        return results;
    }

    const handleInputChange = (data: InputLayerValue) => {
        if (submitValidationRun) {
            setDisableSubmit(validate(data).length !== 0);
        }
    };

    const handleSubmit = (
        ref: RefObject<HTMLInputElement> | undefined,
        value: InputLayerValue
    ) => {
        setPersonalCodePart(value);

        if (!submitValidationRun) {
            setSubmitValidationRun(true);
        }

        if (validate(value).length === 0) {
            // blur input
            ref?.current?.blur();
            setEntryInProgress(false);
        } else {
            // keep field active
            setDisableSubmit(true);
        }
    }

    return (
        <AppRoutePage id="page-get-documents">
            {
                initialized.ready
                && (
                    initialized.error
                        ? (
                            <FlowMessage
                                stepId="POLICY-DOCUMENTS-ERROR"
                                queryArea={
                                    <AppQueryHeader>{t('ExternalAccessPolicy.DocumentsError.Title')}</AppQueryHeader>
                                }
                                inputArea={() => 
                                    <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
                                        <AppButton mailto={{
                                            email: 'mailto:info@manramu.lt',
                                            subject: t('ExternalAccessPolicy.DocumentsError.ContactUs.Subject'),
                                            body: t('ExternalAccessPolicy.DocumentsError.ContactUs.Body', { policyid: initialized.policyIdentifier })
                                        }}>
                                            {t('ExternalAccessPolicy.DocumentsError.ContactUs.Title')}
                                        </AppButton>
                                    </div>
                                }
                                padded={false}
                            />
                        )
                        : (
                            entryInProgress
                                ? (
                                    <FlowMessage
                                        stepId="POLICY-DOCUMENTS"
                                        withConfirm={true}
                                        assistanceRecords={validationResults}
                                        queryArea={
                                            <AppQueryHeader>{t('ExternalAccessPolicy.Edit.Query')}</AppQueryHeader>
                                        }
                                        inputArea={() => 
                                            <TextInput
                                                onValueChange={handleInputChange}
                                                onSubmit={(x, y) => handleSubmit(x, y)}
                                                placeholder={'ExternalAccessPolicy.Edit.Placeholder'}
                                                disableSubmit={disableSubmit}
                                                value={personalCodePart}
                                                maxLength={4}
                                            />
                                        }
                                        padded={false}
                                    />
                                )
                                : (
                                    <PolicyDocuments
                                        policyIdentifier={initialized.policyIdentifier}
                                        personalCodePart={personalCodePart.value}
                                        onFixPersonalCodePart={() => setEntryInProgress(true)}
                                    />
                                )
                        )
                )
            }
        </AppRoutePage>
    );
}

export default GetDocuments;