import './AppButton.scss';
import React, { RefObject, CSSProperties, ReactNode } from "react";
import { Button, PressEvent } from "react-aria-components";
import { useTranslation } from 'react-i18next';

export enum AppButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Ghost = 'ghost'
}

export enum AppButtonSize {
    Large = 'large',
    Medium = 'medium',
    Small = 'small'
}

export type AppButtonProps = {
    /** DOM children that are projected to inside the button.*/
    children?: ReactNode;
    /** Type of button. [Primary, Secondary, Tertiary] */
    type?: AppButtonType;
    /** Size of button. [Default, Small, ExtraSmall] */
    size?: AppButtonSize;
    /** Whether button children is only an icon. */
    iconOnly?: boolean;
    /** Proxy for HTML attribute [title] of button (internal browser hover label). */
    title?: string;
    /** Proxy for HTML attribute [class].*/
    className?: string;
    /** Proxy for HTML [onClick] button handler. */
    onPress?: (e: PressEvent) => void;
    /** Proxy for HTML [style] button prop. */
    style?: CSSProperties;
    /** Exposes buttonref */
    buttonRef?: RefObject<HTMLButtonElement>;
    /** Proxy for HTML [href] button prop. */
    mailto?: {
        email: string;
        subject?: string;
        body?: string;
    },
    /** Proxy for HTML [disabled] button prop. */
    disabled?: boolean;
}

const AppButton: React.FC<AppButtonProps> = ({
    children,
    type = AppButtonType.Primary,
    size = AppButtonSize.Large,
    iconOnly = false,
    className,
    title,
    buttonRef,
    onPress,
    style,
    mailto,
    disabled
}) => {
    const { t } = useTranslation();
    const onPressProxy = (e: PressEvent) => {
        if (mailto) {
            // Construct the mailto link
            let mailtoLink: string = mailto.email;
            let mailtoData = [];

            if (mailto.subject) {
                mailtoData.push(`subject=${encodeURIComponent(t(mailto.subject))}`);
            }

            if (mailto.body) {
                mailtoData.push(`body=${encodeURIComponent(t(mailto.body))}`);
            }

            if (mailtoData.length > 0) {
                mailtoLink += `${mailtoLink}?${mailtoData.join('&')}`
            }

            const tempLink: HTMLAnchorElement = document.createElement('a');
            tempLink.href = mailtoLink;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        }
        onPress && onPress(e);
    }

    return (
        <Button
            isDisabled={disabled}
            style={style}
            ref={buttonRef}
            className={`app-button type--${type} size--${size}${iconOnly ? ' icon-only' : ''}${className ? ' ' + className : ''}`}
            onPress={onPressProxy}
            aria-label={title}
        >
            {children}     
        </Button>
    );
}

export default AppButton;