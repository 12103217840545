import './CONFIRMATION.scss'
import React from "react";
import { InputLayerValue, InsurerIdMapping, WizardStepProps } from "../../WizardManager.models";
import { QUOTE_ID } from "../QUOTE/QUOTE";
import AppButton from "../../../shared/app-button/AppButton";
import { Trans, useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import { AppLoader } from '../../../shared/app-loader/AppLoader';

export const CONFIRMATION_ID = 'CONFIRMATION';

/**
* Proposal confirmation
*/
export const ConfirmationHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, active } = useWizardStep(node, isLast);
    const { t } = useTranslation();

    const confirmSelectedProposal = (value: InputLayerValue) => {
        _wmService.handleStepValueChange(value, true, node, false)
    }

    const rootClassNames = `flow-message${!active() ? ' disabled' : ''}`;

    const selectedProposal = () => {
        let result = {} as any;
        let selectedInsurer = _wmService.readModelValue(QUOTE_ID).valueAsInt();
        if (selectedInsurer) {
            result.insurer = InsurerIdMapping[selectedInsurer];
        }
        let selectedQuotation = (_wmService.quotationState.result ?? []).find(x => x.insurerId === selectedInsurer);
        if (selectedQuotation) {
            if (selectedQuotation.premium) {
                let sp = selectedQuotation.premium.toString().split(/[.,]/);
                if (sp.length == 1) {
                    sp.push('00');
                }
                result.premium = { left: sp[0], right: sp[1] };
            } else {
                result.premium = { left: '', right: '' };
            }
        }

        return result;
    }

    return (
        <React.Fragment>
            {
                active()
                && (
                    <div data-step-id={CONFIRMATION_ID} className={rootClassNames}>
                        <div className={`flow-message-area`}>
                            <div className="confirm-pay-area">
                                {
                                    !_wmService.quotationState.ready
                                        ? (
                                            <React.Fragment>
                                                <div className="confirm-pay-area-loading-header">
                                                    <AppLoader />
                                                </div>
                                                <div className="confirm-pay-area-loading-body">
                                                    {t('Wizard.CONFIRM.QuotationInProgress')}
                                                </div>
                                            </React.Fragment>
                                        )
                                        : (
                                            <React.Fragment>
                                                <div className="confirm-pay-area-header">
                                                    {selectedProposal().insurer}
                                                </div>
                                                <div className="confirm-pay-area-body">
                                                    <AppButton
                                                        onPress={() => { confirmSelectedProposal(new InputLayerValue('PAY.OK')); _wmService.initiateIssuePolicy() }}
                                                    >
                                                        <div className="pay-price">
                                                            <Trans
                                                                i18nKey="Wizard.CONFIRM.Confirm"
                                                                components={{
                                                                    price:
                                                                        <React.Fragment>
                                                                            <div className="pay-sign">
                                                                                {'\u20AC '}
                                                                            </div>
                                                                            <div className="pay-primary">
                                                                                {selectedProposal().premium.left}.
                                                                            </div>
                                                                            <div className="pay-secondary">
                                                                                {selectedProposal().premium.right}
                                                                            </div>
                                                                        </React.Fragment>
                                                                }}
                                                            />
                                                        </div>
                                                    </AppButton>
                                                </div>
                                                <div className="confirm-pay-area-footer">
                                                    {t('Wizard.CONFIRM.Note')}
                                                </div>
                                            </React.Fragment>
                                        )
                                }
                               
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>   
    )
}