// this file configures the behavior of translation mechanism

import i18n, { BackendModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions, CustomDetector } from 'i18next-browser-languagedetector';
import logger from './services/Util';
import { C_LANGUAGE } from './cookies';
import { ApplicationLanguge } from './App';
import { checkConsent, CookieGroup } from './services/useCookieConsent';

const languageDetectorName = 'customLanguageDetector';

const customLanguageDetector: CustomDetector = {
    name: languageDetectorName,

    lookup({ lookupLocalStorage }: DetectorOptions) {
        if (lookupLocalStorage) {
            return localStorage.getItem(lookupLocalStorage) || void(0);
        }

        return void(0);
    },

    cacheUserLanguage(lng: string, { lookupLocalStorage }: DetectorOptions) {
        if (lookupLocalStorage && checkConsent(CookieGroup.Preferences)) {
            localStorage.setItem(lookupLocalStorage, lng);
        }
    }
}

const languageDetector = new LanguageDetector()
    .addDetector(customLanguageDetector);

const languageDetectorOptions: DetectorOptions = {
    order: [languageDetectorName],
    caches: [languageDetectorName],
    lookupLocalStorage: C_LANGUAGE,
    excludeCacheFor: ['cimode'],
};

const fileLoader: BackendModule = {
    type: 'backend',
    init: (services, backendOptions, i18nextOptions) => {
        logger.log('DEBUGGER: Loader.init', { services, backendOptions, i18nextOptions });
    },
    read: (language, namespace, callback) => {
        logger.log('DEBUGGER: Loader.read', { language, namespace });

        const headers = new Headers();
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');

        const init: RequestInit = {
            method: 'GET',
            headers
        }

        fetch('/translations.json', init)
            .then(response => response.json())
            .then(data => {
                const translations = data[language] || {};
                callback(null, translations);
            })
            .catch(error => {
                callback(error, null);
            });
    }
}

i18n
    .use(fileLoader)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: [ApplicationLanguge.Lithuanian, ApplicationLanguge.English], 
        interpolation: {
            escapeValue: false,
        },
        load: 'languageOnly',
        detection: languageDetectorOptions
    });

export default i18n;