import { Pressable } from '@react-aria/interactions';
import './AppInlineLink.scss';
import React, { MouseEventHandler, ReactNode } from "react";

export type AppInlineLinkProps = {
    children?: ReactNode;
    onClick?: MouseEventHandler<HTMLSpanElement>;
}
const AppInlineLink: React.FC<AppInlineLinkProps> = ({ children, onClick }) => {
    return (
        <Pressable onPress={() => {
            if (onClick) {
                onClick({} as any);
            }
        }}>
            <span tabIndex={0} className="app-inline-link">
                {children}
            </span>
        </Pressable>
    );
}

export default AppInlineLink;