import { useState, useEffect } from 'react';

declare var Cookiebot: any;

export enum CookieGroup {
    Necessary = 'necessary',
    Preferences = 'preferences',
    Statistics = 'statistics',
    Marketing = 'marketing'
}

export function useCookieConsent(cookieGroup: CookieGroup) {
    const [isConsented, setIsConsented] = useState(checkConsent(cookieGroup));

    useEffect(() => {
        function onConsentChanged() {
            setIsConsented(checkConsent(cookieGroup));
        }

        window.addEventListener('CookiebotOnLoad', onConsentChanged);

        return () => {
            window.removeEventListener('CookiebotOnLoad', onConsentChanged);
        };
    }, [cookieGroup]);

    return isConsented;
}

export function checkConsent(cookieGroup: CookieGroup) {
    return Cookiebot?.consent?.[cookieGroup] === true;
}