export type SystemOptions = {
    limitedAccess: boolean;
}

export type ApplicationOptions = {
    assemblyVersion: string;
    environmentName: string;
}

/** Code list enum. Only codelists specified in this enum will be available to retrieve via interface method.*/
export enum CodeListName {
    Age = 'age',
    Experience = 'experience'
}

export type CodeValue = {
    /** code used to identify option e.g.'1001' */
    code: string;
    /** textual value used to describe value e.g 'One thousand and one' */
    value: string;
    /** from value if a code list value represents an interval */
    from: number;
    /** to value if a code list value represents an interval */
    to: number;
}

/** Legal person age used through out the UI */
export const MIN_PERSON_AGE: number = 18;