import { useCallback, useEffect, useRef, useState } from "react";
import { C_LOGGER_KEY } from "../cookies";
import { debounce } from 'lodash';

const getEnumName = (value: number, enumRef: any): string | undefined => {
    for (const key in enumRef) {
        if (enumRef[key] === value) {
            return key;
        }
    }
    return undefined; // Value not found in the enum
}

/** Randomizes provided array order. The de-facto unbiased shuffle algorithm is the Fisher�Yates (aka Knuth) Shuffle. */
const shuffleArray = <T = any>(array: T[]) => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

/**
*   Wraps useState (supports functional updates) and provides a way to add callback to it.
*   USAGE:
*       ...
*       const [progressBarVisibility, setProgressBarVisibility] = useStateCallback({ somea1: 0, somea2: 'sss');
*       ...
*       setProgressBarVisibility(x => ({ ...x, somea1: 99}), () => {
*           ... <your callback logic goes here>
*       });
*       ...
*/
const useStateCallback = <T>(initialState: T): [T, (state: T | ((prevState: T) => T), callback?: (state: T) => void) => void] => {
    const [state, setState] = useState(initialState);
    const callbackRef = useRef<((state: T) => void) | undefined>(undefined);

    const setStateCallback = useCallback(
        (newState: T | ((prevState: T) => T), callback?: (state: T) => void) => {
            callbackRef.current = callback;
            setState(prevState => (typeof newState === 'function' ? (newState as (prevState: T) => T)(prevState) : newState));
        },
        []
    );

    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current(state);
            callbackRef.current = undefined;
        }
    }, [state]);

    return [state, setStateCallback];
}

/**
*   Provides a way to detect clicks outside a component (DOM REF) and adds a callback when that happens.
*   USAGE:
*       ...
*       const client = () => {
*           const innerRef = useOuterClick(ev => { .
*               ... <your callback logic goes here
            });
*           return <div ref={ innerRef }> Inside < /div> 
*       };
*       ...
*/
const useOuterClick = (enabled: boolean, callback: any) => {
    const enabledRef = useRef(false);
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to the client, who marks "border" element

    // update cb on each render, so the second useEffect has access to the current value 
    useEffect(() => {
        enabledRef.current = enabled;
        callbackRef.current = callback;
    });

    useEffect(() => {
        const handleClick = (e: Event) => {
            if (innerRef.current
                && callbackRef.current
                && !innerRef.current.contains(e.target)
            ) {
                callbackRef.current(e);
            }
        };

        if (enabledRef.current) {
            document.getElementById('root')?.addEventListener('click', handleClick);
        }   

        return () => document.removeEventListener('click', handleClick);
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for the client (doesn't need to initialize ref by itself) 
};

interface IntervalProps {
    callback: (iteration: number) => void;
    delay: number;
    maxIterations: number;
}

const useInterval = ({ callback, delay, maxIterations }: IntervalProps) => {
    const [iteration, setIteration] = useState(0);
    const intervalIdRef = useRef<number | null>(null);

    useEffect(() => {
        intervalIdRef.current = window.setInterval(() => {
            callback(iteration);
            setIteration((prevIteration) => prevIteration + 1);
        }, delay);

        // Cleanup function to clear the interval when the component unmounts or maxIterations is reached
        return () => {
            if (intervalIdRef.current !== null) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, [iteration, delay, callback]);

    // Additional logic to stop the interval after a certain number of iterations
    useEffect(() => {
        if (iteration >= maxIterations) {
            if (intervalIdRef.current !== null) {
                clearInterval(intervalIdRef.current);
            }
        }
    }, [iteration, maxIterations]);

    return iteration;
};

const useDebouncedState = <T>(initialValue: T, delay: number): [T, (value: T) => void] => {
    const [state, setState] = useState<T>(initialValue);
    const [debouncedState, setDebouncedState] = useState<T>(initialValue);
    const debouncedSetState = useRef(debounce(setDebouncedState, delay)).current;

    useEffect(() => {
        // Only update the actual state when the debounced state has settled
        setState(debouncedState);
    }, [debouncedState]);

    useEffect(() => {
        // Cancel debounce on unmount to prevent state update after component unmount
        return () => {
            debouncedSetState.cancel();
        };
    }, [debouncedSetState]);

    const setDebouncedValue = (value: T) => {
        debouncedSetState(value);
    };

    return [state, setDebouncedValue];
}

const checko537038ug = () => {
    return localStorage.getItem(C_LOGGER_KEY) === 'true' ;
};

const logger = {
    log: (...args: any) => {
        if (checko537038ug()) {
            console.groupCollapsed(args[0]);
            const [,...restArgs] = args;
            console.log('arguments', ...restArgs);
            console.trace();
            console.groupEnd();
        }
    },
    warn: (...args: any) => {
        if (checko537038ug()) {
            console.groupCollapsed(args[0]);
            const [, ...restArgs] = args;
            console.warn('arguments',...restArgs);
            console.trace();
            console.groupEnd();
        }
    },
    error: (...args: any) => {
        if (checko537038ug()) {
            console.groupCollapsed(args[0]);
            const [, ...restArgs] = args;
            console.error('arguments', ...restArgs);
            console.trace();
            console.groupEnd();
        }
    },
    o537038ug: () => {
        localStorage.setItem(C_LOGGER_KEY, 'true');
        return `debug output enabled`;
    }
};

export default logger;

export { getEnumName, useStateCallback, useOuterClick, useInterval, shuffleArray, useDebouncedState }