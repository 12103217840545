import './PAYMENT.scss'
import React from "react";
import { WizardStepProps } from "../../WizardManager.models";
import { useTranslation } from "react-i18next";
import useWizardStep from "../WizardStep";
import AppButton, { AppButtonType } from "../../../shared/app-button/AppButton";
import { AppLoader } from "../../../shared/app-loader/AppLoader";
import FlowMessage from '../../flow-message/FlowMessage';
import { WizardManagerServiceSignature } from '../../WizardManager.service';
import { TFunction } from 'i18next';
import AppQueryHeader from '../../../shared/app-query-header/AppQueryHeader';

export const PAYMENT_ID = 'PAYMENT';

export const PaymentHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, active } = useWizardStep(node, isLast);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {
                active() 
                && (
                    <div data-step-id={PAYMENT_ID} className={`flow-message${!active() ? ' disabled' : ''}`}>
                        <div className={`flow-message-area`}>
                            <div className={`payment-area ${(!_wmService.policyState.loading && !_wmService.policyState.success) && 'policy-error'}`}>
                                {
                                    _wmService.policyState.loading
                                        ? (
                                            <div className="payment-initiation">
                                                <span>{t('Wizard.PAYMENT.PolicyLoading')}</span>
                                                <AppLoader />
                                            </div>
                                        )
                                        : (
                                            <React.Fragment>
                                                {
                                                    !_wmService.policyState.success
                                                        ? (
                                                            <FlowMessage
                                                                stepId={'POLICY-ERROR'}
                                                                queryArea={
                                                                    <AppQueryHeader>{t('Wizard.PAYMENT.PolicyError.Title')}</AppQueryHeader>
                                                                }
                                                                inputArea={() => 
                                                                    <div className="flow-message-suggestions" style={{ justifyContent: "center" }}>
                                                                        <AppButton onPress={() => _wmService.chooseAnotherProposal()}>
                                                                            {t('Wizard.PAYMENT.PolicyError.ChooseAnotherProposal')}
                                                                        </AppButton>
                                                                        <AppButton
                                                                            type={AppButtonType.Tertiary}
                                                                            mailto={{
                                                                                email: 'mailto:info@manramu.lt',
                                                                                subject: t('Wizard.PAYMENT.PolicyError.ContactUs.Subject'),
                                                                                body: t('Wizard.PAYMENT.PolicyError.ContactUs.Body', { policyid: _wmService.policyState.id })
                                                                            }}
                                                                        >
                                                                            {t('Wizard.PAYMENT.PolicyError.ContactUs.Title')}
                                                                        </AppButton>
                                                                    </div>
                                                                }
                                                                padded={false}
                                                            />
                                                        )
                                                        : (
                                                            <React.Fragment>
                                                                <PaymentInitiation t={t} _wmService={_wmService} />
                                                                <PaymentErrorSection t={t} _wmService={_wmService} />
                                                            </React.Fragment>
                                                        )
                                                }
                                            </React.Fragment>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>       
    )
}

const PaymentInitiation: React.FC<{ _wmService: WizardManagerServiceSignature, t: TFunction<"translation", undefined> }> = ({ _wmService, t }) => {
    if (_wmService.paymentInitiationState.loading) {
        return (
            <div className="payment-initiation">
                <span>{t('Wizard.PAYMENT.PolicyRedirecting')}</span>
                <AppLoader />
            </div>
        );
    }

    if (_wmService.paymentInitiationState.paymentAlreadyProcessing) {
        return (
            <div className="payment-initiation">
                <span>{t('Wizard.PAYMENT.PolicyPaymentPending')}</span>
                <AppLoader />
            </div>
        );
    }

    return (
        <div className="payment-initiation">
            <span>{t('Wizard.PAYMENT.PolicyIssued')}</span>
            <AppButton onPress={() => _wmService.initiateIssuedPolicyPayment()}>
                {t('Wizard.PAYMENT.InitiatePayment')}
            </AppButton>
        </div>
    );
}

const PaymentErrorSection: React.FC<{ _wmService: WizardManagerServiceSignature, t: TFunction<"translation", undefined> }> = ({ _wmService, t }) => {
    if (!_wmService.paymentInitiationState.loading &&
        (_wmService.paymentInitiationState.ready && !_wmService.paymentInitiationState.success)
    ) {
        return (
            <div className="payment-error">
                {t('Wizard.PAYMENT.PolicyPaymentError')}
            </div>
        );
    }
}