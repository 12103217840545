import './AppQueryHeader.scss';
import React, { ReactNode } from "react";


export type AppQueryHeaderProps = {
    /** DOM children that are projected to inside the element.*/
    children?: ReactNode;
}

const AppQueryHeader: React.FC<AppQueryHeaderProps> = ({ children }) => {
    return (
        <div className={`app-query-header`}> 
            {children}
        </div>
    );
}

export default AppQueryHeader;