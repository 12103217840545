import { ApiCalculation, ApiCalculationProgress } from "../../../models/Comm.models";

/** Merge existing and new calculations. */
export const mergeAndUpdateQuotationCalculations = (existingCalculations: ApiCalculation[], newCalculations: ApiCalculation[], ready: boolean) => {
    let mergedCalculations = [...existingCalculations];
    for (const nc of newCalculations) {
        let newExistsIndex = mergedCalculations.findIndex(x => x.insurerId === nc.insurerId);
        if (newExistsIndex === -1) {
            nc.progress = new ApiCalculationProgress();

            // assign failure state if received delta from poll has no OK validity qualifiers
            if (nc.error || (ready && !nc.premium)) {
                nc.failed = true;
            }

            // failure or premium existance means that the result has finished processing
            if (nc.failed || nc.premium) {
                // begin finishing
                nc.progress.completeIt();
            }

            mergedCalculations.push(nc);
        } else {
            // assign failure state if received delta from poll has no OK validity qualifiers
            if (nc.error || !nc.premium) {
                nc.failed = true;
            }

            // dont lose initial progress instance
            nc.progress = mergedCalculations[newExistsIndex].progress;

            // update progress
            if (nc.progress) {
                // failure or premium existance means that the result has finished processing
                if (nc.failed || nc.premium) {
                    // begin finishing
                    if (nc.progress.isIdling()) {
                        nc.progress.pullIt();
                    }
                }
            }

            mergedCalculations[newExistsIndex] = nc;
        }
    }

    // Cheap-mark calculations
    // store separately
    let cheapestSortedCalculations = [...mergedCalculations];

    // sort for cheapness
    cheapestSortedCalculations.sort((a, b) => {
        // sort premiums
        const aPremium = (a.premium ?? 3713295916971);
        const bPremium = (b.premium ?? 3713295916971);

        if (aPremium < bPremium) return -1;
        if (aPremium > bPremium) return 1;

        // if add times are identical (could happen if several premiums were not calculated) sort by insurer id.
        if (a.insurerId < b.insurerId) return -1;
        if (a.insurerId > b.insurerId) return 1;

        return 0;
    });

    // mark cheapest
    mergedCalculations.forEach(x => {
        x.cheapest = x.insurerId === cheapestSortedCalculations[0].insurerId || x.premium === cheapestSortedCalculations[0].premium;

        if (!x.progress) {
            x.progress = new ApiCalculationProgress();
        }
    });

    return mergedCalculations;
}