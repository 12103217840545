import React from "react";
import { InputLayerValue, WizardStepProps } from "../WizardManager.models";
import { FlowMessageReadEdit } from "../WizardManager";
import AppButton, { AppButtonType } from "../../shared/app-button/AppButton";
import { useTranslation } from "react-i18next";
import useWizardStep from "./WizardStep";
import AppIconDisplay, { AppIconDisplaySize } from "../../shared/app-icon-display/AppIconDisplay";
import AppQueryHeader from "../../shared/app-query-header/AppQueryHeader";
import FlowMessage from '../flow-message/FlowMessage';

export const POLICY_DURATION_ID = 'PD';

export const PolicyDurationHandler: React.FC<WizardStepProps> = ({ node, isLast }) => {
    const { _wmService, editable, active, readValue } = useWizardStep(node, isLast);
    const { t } = useTranslation();

    const handleDurationSelection = (value: InputLayerValue) => {
        _wmService.handleStepValueChange(value, true, node, false);
    }

    return (
        <React.Fragment>
            {
                active()
                    ? (
                        <FlowMessage
                            stepId={POLICY_DURATION_ID}
                            queryArea={
                                <AppQueryHeader>{t('Wizard.PD.Edit.Query')}</AppQueryHeader>
                            }
                            inputArea={() => 
                               <div className="flow-message-suggestions" style={{ justifyContent: 'center'} }>
                                    <AppButton
                                        type={AppButtonType.Secondary}
                                        onPress={(e) => handleDurationSelection(new InputLayerValue('1', 'Wizard.PD.OneMonth'))}
                                    >
                                        {t('Wizard.PD.OneMonth')}
                                    </AppButton>
                                    <AppButton 
                                        type={AppButtonType.Secondary}
                                        onPress={(e) => handleDurationSelection(new InputLayerValue('12', 'Wizard.PD.TwelveMonths'))}
                                    >
                                        {t('Wizard.PD.TwelveMonths')}
                                    </AppButton>
                                </div>
                            }
                        /> 
                    )
                    : (
                         <div data-step-id={POLICY_DURATION_ID} className={`flow-message disabled`}>   
                            <div className={`flow-message-area`}>
                                <div className="flow-message-read" >
                                    <div className="flow-message-read-icon">
                                        <AppIconDisplay name="duration" size={AppIconDisplaySize.S} />
                                    </div>
                                    <div className="flow-message-read-label">
                                        {t('Wizard.PD.Read.Query')}
                                    </div>
                                    <div className="flow-message-read-value">
                                        {readValue()}
                                    </div>
                                    {
                                        editable()
                                        && <FlowMessageReadEdit onPress={() => _wmService.onConfirmEditStep(node.id)} />
                                    }
                                </div>
                            </div>
                        </div>
                    )
            }
        </React.Fragment>    
    );
}