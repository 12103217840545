import { useEffect, useState } from "react";
import { _http } from "../../../App";
import { GetPolicyResponse, IssuePolicyRequest, IssuePolicyResponse } from "../../../models/Comm.models";
import { InputLayerValue, PolicyState, QuotationState } from "../WizardManager.models";
import { POLICY_HOLDER_ID } from "../wizard-steps/PH/PH";
import { PHONE_CONSENT_ID, PHONE_ID } from "../wizard-steps/PHONE/PHONE";
import { EMAIL_CONSENT_ID, EMAIL_ID } from "../wizard-steps/EMAIL/EMAIL";
import { POLICY_HOLDER_ADDRESS_FLAT, POLICY_HOLDER_ADDRESS_HOUSE, POLICY_HOLDER_ADDRESS_STREET } from "../wizard-steps/ADDRESS/PHA";
import { QUOTE_ID } from "../wizard-steps/QUOTE/QUOTE";
import logger from "../../../services/Util";
import { useTranslation } from 'react-i18next';

type PollContext = {
    id: string;
    count: number;
}

type Props = {
    readModelValue: (key: string, fallback?: string) => InputLayerValue;
    quotationState: QuotationState;
}
export function usePolicy(props: Props): [PolicyState, () => void, () => void] {
    let {
        readModelValue,
        quotationState
    } = props;

    const { i18n } = useTranslation();

    const getInitialPolicyState = () => ({
        id: '',
        result: 0,

        loading: false,
        ready: false,
        success: false
    });

    // Policy information & ux state */
    const [policyState, setPolicyState] = useState<PolicyState>(getInitialPolicyState());

    // Policy polling context */
    const [policyPoller, setPolicyPoller] = useState<PollContext>({ id: '', count: 0 });

    // Policy polling scheduling handler */
    useEffect(() => {
        if (!policyState.loading) {
            return;
        }
        const timeoutId = setTimeout(() => pollPolicy(), 5000);
        return () => {
            clearTimeout(timeoutId);
        }
        // runs every time the dependency "policyPoller" changes
    }, [policyPoller]);

    /** Initiates policy issuing process. */
    const initiateIssuePolicy = () => {
        setPolicyState(ps => ({ ...ps, loading: true }));

        const holder = {} as any; // ApiAdditionalPolicyHolderInformation;

        let holderName = readModelValue(`${POLICY_HOLDER_ID}.FIRSTNAME`).value ?? 'WEB';
        if (holderName) {
            holder.name = holderName;
        }

        let holderLastName = readModelValue(`${POLICY_HOLDER_ID}.LASTNAME`).value ?? 'APP';
        if (holderLastName) {
            holder.lastName = holderLastName;
        }

        let holderPhone = readModelValue(PHONE_ID).value;
        if (holderPhone) {
            holder.phone = holderPhone;
        }

        let holderPhoneConsent = readModelValue(PHONE_CONSENT_ID).valueAsBool();
        if (holderPhoneConsent) {
            holder.phoneContactConsent = holderPhoneConsent;
        }

        let holderEmail = readModelValue(EMAIL_ID).value;
        if (holderEmail) {
            holder.email = holderEmail;
        }

        let holderEmailConsent = readModelValue(EMAIL_CONSENT_ID).valueAsBool();
        if (holderEmailConsent) {
            holder.emailContactConsent = holderEmailConsent;
        }

        let addressId = 0;

        let holderStreet = readModelValue(POLICY_HOLDER_ADDRESS_STREET).valueAsInt();
        if (holderStreet) {
            addressId = holderStreet
        }

        let holderHouse = readModelValue(POLICY_HOLDER_ADDRESS_HOUSE).valueAsInt();
        if (holderHouse) {
            addressId = holderHouse
        }

        let holderFlat = readModelValue(POLICY_HOLDER_ADDRESS_FLAT).valueAsInt();
        if (holderFlat) {
            addressId = holderFlat
        }

        if (addressId) {
            holder.addressId = addressId;
        }

        const request: IssuePolicyRequest = {
            insurerId: readModelValue(QUOTE_ID).valueAsInt()!,
            holder
        };

        _http
            .headers({'Accept-Language': i18n.resolvedLanguage ?? '' })
            .post(request, `/api/quote/${quotationState.id}/issue`)
            .res()
            .then(async response => {
                let createPolicyResponse = {} as IssuePolicyResponse;
                if (response.body) {
                    createPolicyResponse = JSON.parse(await response.text());
                }

                setPolicyState(ps => ({ ...ps, id: createPolicyResponse.id }));
                setPolicyPoller(() => ({ id: createPolicyResponse.id, count: 0 }));
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while initiating policy issue process', { details: error });
                setPolicyState(ps => ({ ...ps, ready: true, loading: false, success: false }));
            });
    }

    /** Polls for policy issue result */
    const pollPolicy = () => {
        const policyId = policyPoller.id;

        _http
            .get(`/api/policy/${policyId}`)
            .res()
            .then(async response => {
                let getPolicyResponse = {} as GetPolicyResponse;
                if (response.body) {
                    getPolicyResponse = JSON.parse(await response.text());
                }
     
                setPolicyState(ps => ({
                    ...ps,
                    number: getPolicyResponse.number,
                    result: getPolicyResponse.premium,
                    loading: !getPolicyResponse.ready,
                    ready: getPolicyResponse.ready,
                    success: getPolicyResponse.success
                }));

                if (!getPolicyResponse.ready) {
                    // reset poller
                    setPolicyPoller(ctx => ({ ...ctx, count: ctx.count + 1 }));
                }
            })
            .catch(error => {
                logger.error('DEBUGGER: An issue occured while polling for policy', { details: error });
                setPolicyState(qs => ({ ...qs, loading: false, ready: true, success: false }));
            });
    };


    const clearPolicyState = () => {
        setPolicyState(() => getInitialPolicyState());
        setPolicyPoller({ id: '', count: 0 });
    }

    return [
        policyState,
        clearPolicyState,
        initiateIssuePolicy
    ];
}